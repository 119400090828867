import Debug from 'debug'

const debug = Debug('web:imageDownsampling:')

/**
 * Checks the dimensions of an image and returns the height width and ratio of the image
 * @param {object} imgEl image element (not JQuery or JQuery[0])
 * @returns {object} returns the height, width, ratio between height and width as well as whether the image is landscape or not
 */
export const imgStats = imgEl => {
  const imgHeight = imgEl.naturalHeight
  const imgWidth = imgEl.naturalWidth
  const imgRatio = imgWidth / imgHeight
  let imgLandscape = true
  if (imgHeight > imgWidth) {
    imgLandscape = false
  }
  const imgInfo = {
    height: imgHeight,
    width: imgWidth,
    ratio: imgRatio,
    landscape: imgLandscape,
  }
  return imgInfo
}

/**
 * A function to turn canvas.toBlob as a promise
 * @param {object} canvas a canvas object
 * @returns {object} returns a blob from the canvas as a promise
 */
function getCanvasBlob(canvas) {
  return new Promise(resolve => canvas.toBlob(blob => resolve(blob)))
}

export const stepped_scale = (img, width, step) => {
  let scaleDown = step
  if (typeof scaleDown === 'undefined') {
    scaleDown = 0.5
  }
  return Promise.resolve()
    .then(() => {
      const canvas = document.createElement('canvas') // canvas the result ends up on
      const ctx = canvas.getContext('2d')
      const oc = document.createElement('canvas') // canvas used for intermedary steps
      const octx = oc.getContext('2d')

      // -- stepped scaling --
      canvas.width = width // destination canvas size
      canvas.height = (canvas.width * img.naturalHeight) / img.naturalWidth // width * ratio between image height and width

      if (img.width * step > width) {
        // For performance avoid unnecessary drawing
        const mul = 1 / step
        let cur = {
          width: Math.floor(img.width * step),
          height: Math.floor(img.height * step),
        }

        oc.width = cur.width
        oc.height = cur.height

        octx.drawImage(img, 0, 0, cur.width, cur.height)

        while (cur.width * step > width) {
          cur = {
            width: Math.floor(cur.width * step),
            height: Math.floor(cur.height * step),
          }
          octx.drawImage(
            oc,
            0,
            0,
            cur.width * mul,
            cur.height * mul,
            0,
            0,
            cur.width,
            cur.height
          )
        }

        ctx.drawImage(
          oc,
          0,
          0,
          cur.width,
          cur.height,
          0,
          0,
          canvas.width,
          canvas.height
        )
      } else {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      }
      return canvas
    })
    .then(canvas => {
      debug('getting blob from canvas')
      return getCanvasBlob(canvas)
    })
    .then(blob => {
      debug(blob)
      return blob
    })
    .catch(err => {
      debug(err)
    })
}

export const generateDimensions = (imgEl, maximumWidth, maximumHeight) => {
  const stats = imgStats(imgEl)
  debug({ imgStats: stats })
  let maxWidth = maximumWidth
  let maxHeight = maximumHeight
  if (stats.width < maxWidth && stats.height < maxHeight) {
    debug(`img already smaller than ${maxWidth} x ${maxHeight}`)
    return false // img already small enough
  }
  const maxWidthForHeight = (maxHeight / stats.height) * stats.width
  if (maxWidthForHeight < maxWidth) {
    debug('height restricted')
    maxWidth = maxWidthForHeight
  } else {
    debug('width restricted')
    maxHeight = (maxWidth / stats.width) * stats.height
  }
  const dimensions = {
    width: Math.floor(maxWidth),
    height: Math.floor(maxHeight),
  }
  return dimensions
}

/**
 * downsamples the image until it reaches the max width or max height
 * @param {string} img img element with image loaded
 * @param {number} maxWidth
 * @param {number} maxHeight
 * @returns {blob | false} returns a blob or false
 */
const imageDownsampling = ({ img, maxWidth, maxHeight }) => {
  debug(img)
  debug(img.src)
  return Promise.resolve()
    .then(() => generateDimensions(img, maxWidth, maxHeight))
    .then(dimensions => {
      debug({ imgDimensions: dimensions })
      return dimensions !== false
        ? stepped_scale(img, dimensions.width, 0.5)
        : false
    })
    .catch(err => debug(err))
}

export default imageDownsampling
