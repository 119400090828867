const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS'

export const loadPermissions = permissions => ({
  type: LOAD_PERMISSIONS,
  payload: { permissions },
})

const initialState = {
  permissions: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
}

const permissions = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_PERMISSIONS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getPermissions = state => state.permissions

export default permissions
