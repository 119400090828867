import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getBreadcrumbsState } from '../../../../redux/reducer'
import MobileDropDownItem from '../MobileDropDownItem/MobileDropDownItem'

const TabFallBack = ({ crumbs }) => {
  const tabName =
    crumbs && crumbs[crumbs.length - 1] && crumbs[crumbs.length - 1].title
      ? crumbs[crumbs.length - 1].title
      : null
  return <MobileDropDownItem>{tabName}</MobileDropDownItem>
}

TabFallBack.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
}

TabFallBack.defaultProps = {
  crumbs: [],
}

const mapStateToProps = state => ({
  crumbs: getBreadcrumbsState(state),
})

export default connect(mapStateToProps)(React.memo(TabFallBack))
