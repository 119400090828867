import React from 'react'
import { Global, css } from '@emotion/core'
import theme from './theme'

export const GlobalEmotionCSS = () => (
  <Global
    styles={css`
      .DateRangePickerInput {
        display: flex;
        align-items: center;
      }

      .DateRangePicker_picker__portal,
      .SingleDatePicker_picker__portal {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
      }

      .DateRangePickerInput__withBorder {
        border-radius: ${theme.borderRadius};
      }
      .CalendarDay__selected_span {
        background-color: ${theme.primaryLight};
        border-color: ${theme.primaryLight};
        :hover {
          background-color: ${theme.primaryLightTransparent};
          border: ${theme.primaryLightTransparent};
          color: #fff;
        }
        :focus {
          outline: none;
        }
      }
      .CalendarDay__hovered_span {
        background-color: ${theme.primaryLightTransparent};
        border: ${theme.primaryLightTransparent};
        color: #fff;
        :hover {
          background-color: ${theme.primaryLightTransparent};
          border: ${theme.primaryLightTransparent};
          color: #fff;
        }
      }
      .CalendarDay__selected,
      .CalendarDay__selected_start,
      .CalendarDay__selected_end {
        background-color: ${theme.primary};
        border-color: ${theme.primary};
        :hover {
          background-color: ${theme.primary};
          border-color: ${theme.primary};
        }
        :focus {
          outline: none;
        }
      }

      .CalendarDay__today {
        position: relative;
        color: ${theme.primary};
        font-weight: 600;
        font-size: 15px;
        :hover {
          color: ${theme.primary};
        }
      }

      .CalendarDay__selected_span.CalendarDay__today,
      .CalendarDay__selected.CalendarDay__today {
        color: #fff;
      }

      /* css transition group css */
      .animate-appear {
        opacity: 0.01;
      }

      .animate-appear.animate-appear-active {
        opacity: 1;
        transition: opacity 0.5s ease-in;
      }

      .animate-enter {
        opacity: 0.01;
      }

      .animate-enter.animate-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
      }

      .animate-exit {
        opacity: 1;
      }

      .animate-exit.animate-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in-out;
      }

      /* css transition group for modals css */
      .modal-appear {
        opacity: 0.01;
      }
      .modal-appear.modal-appear-active {
        opacity: 1;
        transition: opacity 0.25s ease-in;
      }
      .modal-enter {
        opacity: 0.01;
      }
      .modal-enter.modal-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
      }
      .modal-exit {
        opacity: 1;
      }
      .modal-exit.modal-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in-out;
      }
    `}
  />
)
