import Debug from 'debug'
import api from '../../api'
import {
  getClientsState,
  getMatchParamsIdRefState,
  getMatchParamsState,
  getCompanyByIdState,
  getClientByIdState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { toggleModal } from '../modalState/modalState'
import { errorHandler } from '../errorState/errorState'
import {
  getCompaniesRequest,
  updateCompanyById,
} from '../companiesState/companiesState'
import { updateLastBreadcrumbTitleApi } from '../breadcrumbState/breadcrumbState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { SHOW_ALL } from '../../Routes/routesConstants'
import findAndUpdate from '../../utils/findAndUpdate/findAndUpdate'
import {
  nextPageForLists,
  calculatePagination,
} from '../paginationState/paginationState'

const debug = Debug('web:clientsState:')

// --- Actions ---

const UPDATE_CLIENTS = 'UPDATE_CLIENTS'
const UPDATE_CLIENT_BY_ID = 'UPDATE_CLIENT_BY_ID'
const NEXT_CLIENT_PAGE = 'NEXT_CLIENT_PAGE'

// --- Action Creators ---

/**
 * update the clients in the clients reducer
 * @param {*} clients
 */
const updateClients = (clients, companyId) => ({
  type: UPDATE_CLIENTS,
  payload: { [companyId]: clients },
  reference: 'clients',
})

export const updateClientById = (client, id) => ({
  type: UPDATE_CLIENT_BY_ID,
  payload: { [id]: client },
  reference: 'clientById',
})

const nextClientPage = (clients, id) => ({
  type: NEXT_CLIENT_PAGE,
  payload: clients,
  reference: id,
})

// --- Read ---

/**
 * companyId is present return all clients for the company
 * if the clientId is present return that client
 * if none are present use clients with params
 * @param {number} companyId
 * @param {number} clientId
 * @param {{}} params
 */
export const getClientsRequest = ({
  clientId,
  parentId: companyId,
  params = { $limit: 20 },
}) => {
  let url = 'clients'
  if (companyId) {
    url = `companies/${companyId}/clients`
  }
  if (clientId) {
    url = `clients/${clientId}`
  }
  return api(url, { params })
}

/**
 * reads clients by ID
 * @param {number} companyId
 */
export const getClientsAPI = (companyId, pathname) => (dispatch, getState) => {
  const companyRef = companyId || SHOW_ALL
  const clientsInStore = getClientsState(getState())[companyRef]
  const parentInStore = getCompanyByIdState(getState())[companyId]
  return Promise.resolve()
    .then(() => (clientsInStore ? null : dispatch(toggleLoader(true))))
    .then(() =>
      Promise.all([
        getClientsRequest({
          parentId: companyId,
        }).then(({ data, ...pagination }) => {
          debug(data)
          dispatch(calculatePagination(pagination, pathname))
          return dispatch(updateClients(data, companyRef))
        }),
        companyId
          ? getCompaniesRequest({ companyId }).then(response =>
              dispatch(updateCompanyById(response, companyId))
            )
          : null,
      ])
    )
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      if (companyId) {
        return clientsInStore && parentInStore
          ? null
          : dispatch(toggleLoader(false, true))
      }
      return clientsInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// -- Next Client Page Api --

export const nextClientPageApi = (
  parentId,
  params,
  paginationRef
) => dispatch =>
  dispatch(
    nextPageForLists(
      parentId,
      params,
      paginationRef,
      getClientsRequest,
      nextClientPage
    )
  )

// --- Create ---

/**
 * creates a new client from the add new client form
 * @param {{}} client
 * @param {number} company_id
 */
const addNewClientRequest = (client, company_id) =>
  api(`companies/${company_id}/clients`, {
    method: 'post',
    data: { ...client },
  })

export const addNewClient = (client, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const idRef = getMatchParamsIdRefState(getState())
  const matchParams = getMatchParamsState(getState())
  const company_id = matchParams[idRef]
  const { name } = client
  return Promise.resolve()
    .then(() => addNewClientRequest(client, company_id))
    .then(response => {
      const currentClients = getClientsState(getState())[company_id]
      return [...currentClients, response]
    })
    .then(clients => dispatch(updateClients(clients, company_id)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created', name))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue adding the new client.')
      )
    })
}

// --- UPDATE ---

const patchClientsRequest = (client, id) =>
  api(`clients/${id}`, { method: 'patch', data: client })

export const updateClientApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, parentId, ...client } = values
  const current = getClientsState(getState())[parentId]
  const { siteCount, name } = getClientByIdState(getState())[id]
  const newName = values.name || name
  return Promise.resolve()

    .then(() => patchClientsRequest(client, id)) // send patch request
    .then(response => {
      const updated = { ...response, siteCount }
      return dispatch(
        findAndUpdate(
          current,
          updated,
          id,
          parentId,
          updateClientById,
          updateClients
        )
      )
    })
    .then(() =>
      newName === name
        ? null
        : dispatch(updateLastBreadcrumbTitleApi('client', id, newName))
    )
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newName))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      return dispatch(
        errorHandler(err, modalRef, `There was an issue updating ${name}.`)
      )
    })
}

// --- REDUX ---

const initialState = {
  clients: {},
  clientById: {},
}

const clients = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_CLIENTS:
    case UPDATE_CLIENT_BY_ID:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case NEXT_CLIENT_PAGE: {
      const current = state.clients[reference] || []
      return {
        ...state,
        clients: { ...state.clients, [reference]: [...current, ...payload] },
      }
    }
    default:
      return state
  }
}

export const getClients = state => state.clients
export const getClientById = state => state.clientById

export default clients
