export const sectionTitles = {
  flushing: 'Outlet flushing',
  riskAssessment: 'Risk assessment',
  showerHead: 'Descaling',
  tmvMaintenance: 'TMV maintenance',
  healthSafetyChemicals: 'Health and safety information for chemical products',
  waterTreatmentServiceRecords: 'Water treatment service records',
  microBiologicalResults: 'Microbiological test results',
  cleanAndDisinfection: 'Clean and disinfection reports',
  inspectionRecords: 'Inspection Records',
  equipmentServiceRecord: 'Equipment service record',
  readings: 'Temperature Readings',
  risks: 'Risk',
  users_count: 'Users assigned to jobs',
}
/**
 * 1: weekly
 * 2: monthly
 * 3: quarterly
 * 4: annually
 */
export const sectionTimeFrames = {
  flushing: 1,
  riskAssessment: 4,
  showerHead: 3,
  tmvMaintenance: 3,
  healthSafetyChemicals: 4,
  waterTreatmentServiceRecords: 4,
  microBiologicalResults: 4,
  cleanAndDisinfection: 4,
  inspectionRecords: 4,
  equipmentServiceRecord: 4,
  readings: 2,
}

const sectionTitleLookup = (section, removeUnderscores) => {
  const toReturn = sectionTitles[section]
  return toReturn || (removeUnderscores ? section.replace(/_/g, ' ') : section)
}

export const sectionTimeFrameLookup = section => {
  const toReturn = sectionTimeFrames[section]
  return toReturn || 4 // 4 is annually
}

export default sectionTitleLookup
