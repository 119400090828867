import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkUserRegistrationToken } from '../../reducers/userRegistrationState/userRegistrationState'
import {
  getUserRegistrationInfoState,
  getUserRegistrationTokenStatusState,
} from '../../redux/reducer'
import UserRegistrationStatus from './UserRegistrationStatus/UserRegistrationStatus'
import { NoAuthPage } from '../../components/SinglePage/SinglePage'
import Helmet from '../../components/Helmet/Helmet'

class UserRegistrationPage extends Component {
  componentDidMount() {
    const { handleOnMount, match } = this.props
    const { token } = match.params
    handleOnMount(token)
  }

  render() {
    const { registrationInfo, tokenStatus } = this.props
    const { humanReadableRole, expires_at, ...initialValues } = registrationInfo
    return (
      <NoAuthPage>
        <Helmet title="User Registration" />
        <UserRegistrationStatus
          initialValues={initialValues}
          humanReadableRole={humanReadableRole}
          tokenStatus={tokenStatus}
        />
      </NoAuthPage>
    )
  }
}

UserRegistrationPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  handleOnMount: PropTypes.func.isRequired,
  registrationInfo: PropTypes.objectOf(PropTypes.node),
  tokenStatus: PropTypes.string,
}

UserRegistrationPage.defaultProps = {
  registrationInfo: {},
  tokenStatus: 'loading',
}

const mapStateToProps = state => ({
  registrationInfo: getUserRegistrationInfoState(state),
  tokenStatus: getUserRegistrationTokenStatusState(state),
})

const mapDispatchToProps = dispatch => ({
  handleOnMount: token => dispatch(checkUserRegistrationToken(token)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistrationPage)
