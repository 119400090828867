import Debug from 'debug'
import moment from 'moment'
import api from '../../api'
import {
  getDRPStartDateState,
  getDRPEndDateState,
  getOutletByIdState,
} from '../../redux/reducer'
import { toggleModal } from '../modalState/modalState'
import {
  getGraphReadingsByOutletId,
  getOutletByIdApi,
  updateOutletReadings,
} from '../outletDeviceState/outletDeviceState'
import { errorHandler } from '../errorState/errorState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'

const debug = Debug('web:manualReadingState:')

// --- Actions ---

const TOGGLE_DELETE_MANUAL_READING_BUTTON =
  'TOGGLE_DELETE_MANUAL_READING_BUTTON'

// --- Action Creators ---

export const toggleDeleteManualReadingButton = (
  deleteButtonActive = false
) => ({
  type: TOGGLE_DELETE_MANUAL_READING_BUTTON,
  payload: { deleteButtonActive },
})

// --- Submit Manual Reading

/**
 * sends a post request to enter a manual reading
 * @param {number} device_id
 * @param {number} value
 */
const postManualReading = (outlet_id, value) =>
  api('/readings', {
    method: 'post',
    data: {
      outlet_id,
      ...value,
    },
  })

/**
 *
 * @param {{deviceId,type, outletId, modalRef}} destructuredObject
 */
export const submitManualReading = (
  { deviceId, ...values },
  setSubmitting,
  modalRef,
  outletId
) => (dispatch, getState) => {
  const outlet = getOutletByIdState(getState())[outletId]
  const currentReadings = outlet.readings || []
  const currentReadingsOldestFirst = outlet.readingsOldestFirst || []
  const { created_at, value } = values
  return Promise.resolve()
    .then(() => postManualReading(outletId, values))
    .then(response => {
      const startDate = getDRPStartDateState(getState())
      const endDate = getDRPEndDateState(getState())
      /**
       * if there is a created at date it is unclear where it would go on the graph or list
       * so just re-fetch both
       */
      if (created_at) {
        return Promise.all([
          dispatch(getGraphReadingsByOutletId(outletId, startDate, endDate)),
          dispatch(getOutletByIdApi(outletId)),
        ])
      }
      dispatch(getOutletByIdApi(outletId))
      /**
       * closes the modal
       * resets the manual reading value
       * adds the manual reading to the reading list
       */

      /**
       *  checks that the manual reading is within the date range in the date
       *  range picker and therefore whether it should show on the graph
       */
      const timeNow = created_at ? moment.utc(created_at) : moment.utc()
      if (moment.utc(startDate) < timeNow && moment.utc(endDate) > timeNow) {
        const newReadingsOldestFirst = [...currentReadingsOldestFirst, response]
        const newReadings = [response, ...currentReadings]
        return dispatch(
          updateOutletReadings(outletId, newReadings, newReadingsOldestFirst)
        )
      }
      return response
    })
    .then(() => {
      dispatch(toggleModal(modalRef, false))
      successToaster(`added a new manual reading of`, `${value}\u2103`)
    })
    .catch(err => {
      debug(err)
      setSubmitting(false)
      return dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue submitting your manual reading.'
        )
      )
    })
}

// --- Delete button control ---

// the reference for cancelling the timer
let deleteButtonTimer = null

/**
 * controls whether the delete button is shown calculated from the creation time
 * the timeOut runs for whatever time is left between the end of the 10 mins and
 * the current time when the modal is opened
 * @param {string} insertedAt the date in utc the reading was created at
 */
export const controlManualReadingDeleteButton = insertedAt => dispatch => {
  const deleteEnd = moment.utc(insertedAt).add(10, 'minutes')
  const timeNow = moment.utc()

  // clears the previous timer
  clearTimeout(deleteButtonTimer)

  // checks if the 10 minute window has passed, if true => hide button
  if (deleteEnd < timeNow) {
    return dispatch(toggleDeleteManualReadingButton(false))
  }

  // sets a timeout to remove the delete button after the difference between timeNow and deleteEnd
  dispatch(toggleDeleteManualReadingButton(true))
  const timeLeft = deleteEnd - timeNow
  deleteButtonTimer = setTimeout(
    () => dispatch(toggleDeleteManualReadingButton(false)),
    timeLeft
  )
  debug(timeLeft)
  return deleteButtonTimer
}

// --- clear delete button timer ---

export const clearDeleteButtonTimeout = () => clearTimeout(deleteButtonTimer)

// --- Redux ---

const initialState = {
  manualReadingValue: '',
  deleteButtonActive: false,
  status: { loading: false },
}

const manualReading = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_DELETE_MANUAL_READING_BUTTON:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getDeleteButtonActive = state => state.deleteButtonActive

export default manualReading
