import Debug from 'debug'
import { getPermissionsState } from '../redux/reducer'
import { actionButtonDownload } from './reportsState/reportsState'
import { toggleModal } from './modalState/modalState'

const debug = Debug('web:header:')

// --- ACTIONS ---

const TOGGLE_HEADER_ACTION_BUTTON = 'TOGGLE_HEADER_ACTION_BUTTON'
const CHANGE_ACTION_BUTTON = 'CHANGE_ACTION_BUTTON_TEXT'

// --- ACTION CREATORS ---

const toggleHeaderActionButton = showActionButton => ({
  type: TOGGLE_HEADER_ACTION_BUTTON,
  payload: { showActionButton },
})

export const changeActionButton = (
  actionButtonText,
  modalRef,
  actionButtonFunc = null
) => ({
  type: CHANGE_ACTION_BUTTON,
  payload: { actionButtonText, modalRef, actionButtonFunc },
})

// --- Show header action button ---

/**
 * wraps the toggleHeaderActionButton action creator
 * checks the permission and decides whether to dispatch the action
 * @param {boolean} showActionButton whether to show or hide the action button
 * @param {string} permissionName the permission to lookup
 * @param {string} permissionType the permission type to lookup against
 */
export const showHeaderActionButton = (
  showActionButton,
  permissionName = null,
  permissionType = 'create'
) => (dispatch, getState) => {
  if (typeof permissionName === 'string') {
    return getPermissionsState(getState())[permissionType].includes(
      permissionName
    )
      ? dispatch(toggleHeaderActionButton(showActionButton))
      : null
  }
  return dispatch(toggleHeaderActionButton(showActionButton))
}

// --- handle action button click ---

/**
 * These functions are the only functions the header action button
 * can perform. Redux stores the string reference for actions
 * within this object
 */
const actionButtonFunctions = {
  downloadPdf: actionButtonDownload,
}

export const actionButtonClickApi = (
  actionButtonModalRef,
  actionButtonFunc
) => dispatch => {
  if (actionButtonModalRef) {
    dispatch(toggleModal(actionButtonModalRef, true))
  }
  if (actionButtonFunc) {
    // utilises the string reference for the function to perform the function
    const func = actionButtonFunctions[actionButtonFunc]

    // checks that the function is present in the actionButtonFunctions object
    if (func) {
      return dispatch(func())
    }
    debug(
      `${actionButtonFunc} is not a known action for the header action button`
    )
  }
  return false
}

// --- REDUX ---

const initialState = {
  showActionButton: false,
  actionButtonText: null,
}

const headerReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CHANGE_ACTION_BUTTON:
    case TOGGLE_HEADER_ACTION_BUTTON:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getShowActionButton = state => state.showActionButton
export const getActionButtonText = state => state.actionButtonText
export const getActionButtonModalRef = state => state.modalRef
export const getActionButtonFunc = state => state.actionButtonFunc

export default headerReducer
