import Debug from 'debug'
import { toast } from 'react-toastify'
import api from '../../api'
import { updateErrors, errorHandler } from '../errorState/errorState'
import { LOGIN_SUCCESS } from '../loginState/loginState'
import { UPDATE_AUTH_USER_PROFILE_PHOTO } from '../userAccountState/userAccountState'
import { getAuthUserIdState, getAuthOTPState } from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'

const debug = Debug('web:auth:')

// --- Actions ---

const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'
const ENABLE_TOTP_FOR_AUTH_USER = 'ENABLE_TOTP_FOR_AUTH_USER'
const DISABLE_TOTP_FOR_AUTH_USER = 'DISABLE_TOTP_FOR_AUTH_USER'
const LOAD_TOTP_STATUS_FOR_AUTH_USER = 'LOAD_TOTP_STATUS_FOR_AUTH_USER'

// -- Action Creators --

/**
 * updated the user with the updated user information
 * @param {{}} user
 */
const updateAuthUser = user => ({
  type: UPDATE_AUTH_USER,
  payload: user,
  reference: 'user',
})

const loadTotpStatusForAuthUser = otp => {
  const { enabled: totp_status, key: secretKey, ...other } = otp
  return {
    type: LOAD_TOTP_STATUS_FOR_AUTH_USER,
    payload: { totp_status, otp: { ...other, secretKey } },
  }
}

const enableTotpForAuthUser = () => ({
  type: ENABLE_TOTP_FOR_AUTH_USER,
  payload: { totp_status: true, otp: {} },
})

const disableTotpForAuthUser = otp => {
  const { enabled: totp_status, key: secretKey, ...other } = otp
  return {
    type: DISABLE_TOTP_FOR_AUTH_USER,
    payload: { totp_status, otp: { ...other, secretKey } },
  }
}

// --- UPDATE ---

// -- Auth User Profile --

/**
 * patches a specified user with differences only
 * @param {{}} user object of differences
 * @param {*} id id of the user to be updated
 */
const patchUserRequest = (user, id) =>
  api(`users/${id}`, { method: 'patch', data: user })

/**
 * thunk to control the submission of a user's updates
 * to their profile
 * @param {{}} user
 * @param {number} id
 * @param {function} setSubmitting
 * @param {string} ref
 */
export const updateAuthUserAPI = (
  values,
  patchValues,
  id,
  setSubmitting,
  resetForm,
  ref
) => dispatch =>
  Promise.resolve()
    .then(() => patchUserRequest(patchValues, id))
    .then(response => dispatch(updateAuthUser(response)))
    .then(() => {
      resetForm({ ...values })
      dispatch(updateErrors(ref, null))
      successToaster('updated', ' your profile')
    })
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, ref, 'There was an issue in updating your profile.')
      )
    })

// -- User Email --

/**
 * updates the auth user's email
 * @param {{}} value object containing email and password fields
 */
const updateUserEmailRequest = value =>
  api('tokens', {
    method: 'post',
    data: {
      action: 'updateEmail',
      value,
    },
  })

/**
 * update the auth user's email
 * @param {{}} values
 * @param {string} currentEmail
 * @param {function} setSubmitting
 * @param {function} resetForm
 * @param {string} ref
 */
export const updateUserEmailAPI = (
  values,
  currentEmail,
  setSubmitting,
  resetForm,
  ref
) => dispatch =>
  Promise.resolve()
    .then(() => updateUserEmailRequest(values))
    .then(() => {
      resetForm()
      dispatch(updateErrors(ref, null))
      toast(
        `An email has been sent to ${currentEmail} to authorise this change of email address.`,
        { type: 'success' }
      )
    })

    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          ref,
          'There was an issue updating your email. Please try a different new email address.'
        )
      )
    })

// -- Password --

/**
 * updates the auth user's password
 * @param {{}} value
 * @param {number|string} id
 */
const updateUserPasswordRequest = (data, id) =>
  api(`users/${id}/passwordchange`, { method: 'post', data })

/**
 * Update the auth user's password
 * @param {{}} values
 * @param {number|string} id
 * @param {function} setSubmitting
 * @param {function} resetForm
 * @param {string} ref
 */
export const updateUserPasswordAPI = (
  values,
  id,
  setSubmitting,
  resetForm,
  ref
) => dispatch =>
  Promise.resolve()
    .then(() => updateUserPasswordRequest(values, id))
    .then(() => {
      resetForm()
      dispatch(updateErrors(ref, null))
      successToaster(`updated`, `your password`)
    })
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, ref, 'There was an issue updating your password.')
      )
    })

// --- 2 factor auth ---

// --- Get 2 Factor Auth Info ---

const get2FactorAuthInfoRequest = userId => api(`users/${userId}/totp`)

export const get2FactorAuthApi = () => (dispatch, getState) => {
  const userId = getAuthUserIdState(getState())
  const otpInStore = getAuthOTPState(getState())
  return Promise.resolve()
    .then(() => (otpInStore ? null : dispatch(toggleLoader(true))))
    .then(() => get2FactorAuthInfoRequest(userId))
    .then(({ otp }) => dispatch(loadTotpStatusForAuthUser(otp)))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return otpInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// -- Set Up 2 Factor Auth --

/**
 * request to set up 2 factor auth for the auth user
 * @param {{}} payload key and token
 */
const postAddTwoFactorAuthRequest = (userId, data) =>
  api(`users/${userId}/totp`, { method: 'post', data })

/**
 * enables two factor auth for the auth user
 * @param {{}} values
 * @param {function} setSubmitting
 * @param {string} reference
 * @param {function} resetForm
 */
export const setUp2FactorAuthApi = (
  values,
  setSubmitting,
  reference,
  resetForm
) => (dispatch, getState) => {
  const userId = getAuthUserIdState(getState())
  return Promise.resolve()
    .then(() => postAddTwoFactorAuthRequest(userId, values))
    .then(() => dispatch(enableTotpForAuthUser()))
    .then(() =>
      successToaster(
        'enabled',
        'two factor authentication on your account, you will need to use this when you next log in.'
      )
    )
    .catch(err => {
      debug(err)
      setSubmitting(false)
      resetForm()
      return dispatch(
        errorHandler(
          err,
          reference,
          'There was an issue enabling two-factor authentication, please try again.'
        )
      )
    })
}

// -- Remove Two-Factor Auth --

/**
 * request to remove two factor auth from the auth user
 * @param {number} token
 */
const removeTwoFactorAuthRequest = (userId, data) =>
  api(`users/${userId}/totp`, { method: 'post', data })

/**
 * removes two factor auth from the auth user
 * @param {number} token destructured from values
 * @param {function} setSubmitting
 * @param {string} reference
 * @param {function} resetForm
 */
export const removeTwoFactorAuthApi = (
  { token },
  setSubmitting,
  reference,
  resetForm
) => (dispatch, getState) => {
  const userId = getAuthUserIdState(getState())

  Promise.resolve()

    .then(() => removeTwoFactorAuthRequest(userId, { token }))
    .then(({ otp }) => dispatch(disableTotpForAuthUser(otp)))
    .then(() =>
      successToaster(
        'removed',
        'two factor authentication from your account',
        'danger'
      )
    )
    .catch(err => {
      debug(err)
      setSubmitting(false)
      resetForm()
      return dispatch(
        errorHandler(
          err,
          reference,
          'There was an issue disabling two-factor authentication, please try again.'
        )
      )
    })
}

// --- REDUX ---

const initialState = {
  email: '',
  user: { role: '' },
  role: '',
}

const authReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case DISABLE_TOTP_FOR_AUTH_USER:
    case ENABLE_TOTP_FOR_AUTH_USER:
    case UPDATE_AUTH_USER_PROFILE_PHOTO:
    case LOAD_TOTP_STATUS_FOR_AUTH_USER:
    case LOGIN_SUCCESS:
      return { ...state, ...payload }
    case UPDATE_AUTH_USER:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getAuth = state => state
export const getAuthUser = state => state.user
export const getAuthUserEmail = state => state.email
export const getAuthUserRole = state => state.role
export const getAuthTotpTokenStatus = state => state.totp_status
export const getAuthOTP = state => state.otp
export const getAuthUserId = state => state.id
export const getAuthUserFirstName = state => state.first_name
export const getAuthUserLastName = state => state.last_name
export const getAuthUserProfilePhoto = state => state.profile_photo

export default authReducer
