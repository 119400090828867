import Debug from 'debug'

const debug = Debug('web:breadcrumbObjectToArray:')

/**
 * converts the org-structure api response into an ordered array
 * tested in ./breadcrumbState.test.js
 * @param {Object} crumbsObject
 */
const breadcrumbObjectToArray = (crumbsObject, role) => {
  try {
    return Object.entries(crumbsObject)
      .reverse()
      .filter(key => {
        const [type, value] = key
        if (role === 'site-manager') {
          if (type === 'company' || type === 'client') return false
        }
        if (role === 'client') {
          if (type === 'company') return false
        }
        return value !== null
      })
      .map(crumb => {
        const [type, { name: title, id }] = crumb
        return {
          title,
          to: `/${type !== 'company' ? `${type}s` : 'companies'}/${id}`,
        }
      })
  } catch (err) {
    debug(err)
    return null
  }
}

export default breadcrumbObjectToArray
