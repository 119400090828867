import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const BlankSlateSurround = styled('div')`
  background-color: ${props => (props.cleanSlate ? null : '#fff')};
  border-radius: ${props => props.theme.borderRadius};
  text-align: center;
  padding: 32px;
  border: ${props => (props.cleanSlate ? null : '1px solid #e1e4e8')};
  box-shadow: ${props =>
    props.cleanSlate ? null : 'inset 0 0 10px rgba(27, 31, 35, 0.05)'};
  font-family: inherit;
  font-weight: 600;
  line-height: 1.1;
  font-size: 1.2rem;
  color: ${props => (props.muted ? props.theme.muted : null)};
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`
const BlankSlate = ({
  children,
  cleanSlate,
  className,
  containerClassName,
  muted,
}) => (
  <div className={containerClassName} data-cy="blankSlate">
    <BlankSlateSurround
      className={className}
      cleanSlate={cleanSlate}
      muted={muted}
    >
      {children}
    </BlankSlateSurround>
  </div>
)

BlankSlate.propTypes = {
  children: PropTypes.node.isRequired,
  cleanSlate: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  muted: PropTypes.bool,
}

BlankSlate.defaultProps = {
  cleanSlate: false,
  className: null,
  containerClassName: null,
  muted: false,
}

export default BlankSlate
