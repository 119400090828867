import Debug from 'debug'
import { SHOW_ALL } from '../../Routes/routesConstants'
import {
  getLocationsState,
  getSiteByIdState,
  getLocationByIdState,
  getMatchParamsIdRefState,
  getMatchParamsState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import api from '../../api'
import { getSingleSiteApi } from '../sitesState/sitesState'
import { errorHandler } from '../errorState/errorState'
import { updateLastBreadcrumbTitleApi } from '../breadcrumbState/breadcrumbState'
import { toggleModal } from '../modalState/modalState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import findAndUpdate from '../../utils/findAndUpdate/findAndUpdate'
import {
  calculatePagination,
  nextPageForLists,
} from '../paginationState/paginationState'

const debug = Debug('web:locationsState:')

// --- Actions ---

const UPDATE_LOCATION_INFO = 'UPDATE_LOCATION_INFO'
const UPDATE_LOCATION_BY_ID = 'UPDATE_LOCATION_BY_ID'
const NEXT_PAGE_LOCATIONS = 'NEXT_PAGE_LOCATIONS'

// --- Action Creators ---

/**
 * Locations action creator
 * @param {[]} locations
 */
export const updateLocationInfo = (locations, id) => ({
  type: UPDATE_LOCATION_INFO,
  payload: { [id]: locations },
  reference: 'locations',
})

const nextPageLocations = (locations, id) => ({
  type: NEXT_PAGE_LOCATIONS,
  payload: locations,
  reference: id,
})

export const updateLocationById = (location, id) => ({
  type: UPDATE_LOCATION_BY_ID,
  payload: { [id]: location },
  reference: 'locationById',
})

// --- Create ---

/**
 * creates a new location for a given site_id
 * @param {{}} location
 * @param {number} site_id
 */
const addNewLocationRequest = (location, site_id) =>
  api(`sites/${site_id}/locations`, { method: 'post', data: location })

/**
 * takes the information from the formik form for creating a location
 * pairs that with the url's site_id and creates a new location adding it to the current locations
 * @param {{}} location all of the information from the formik form
 * @param {function} setSubmitting the formik function to show that a form is submitting
 * @param {string} modalRef the reference for the modal
 */
export const addNewLocation = (location, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const idRef = getMatchParamsIdRefState(getState())
  const matchParams = getMatchParamsState(getState())
  const site_id = matchParams[idRef]
  const { name } = location
  return Promise.resolve()
    .then(() => addNewLocationRequest(location, site_id))
    .then(response => {
      const currentLocations = getLocationsState(getState())[site_id]

      return [...currentLocations, response]
    })
    .then(locations => dispatch(updateLocationInfo(locations, site_id)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created the location:', name))

    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue adding the new location.'
        )
      )
    })
}

// --- Read ---

/**
 * Note destructured object as input
 * sends an all locations request
 * if a siteId present it gets locations for that site
 * if a locationId present it gets info on that location
 * @param {number | string} siteId
 * @param {number | string} locationId
 * @param {{}} params (optional)
 */
export const getLocationRequest = ({
  parentId: siteId,
  locationId,
  params = {
    $limit: 20,
  },
}) => {
  let service = 'locations'
  if (siteId) {
    service = `sites/${siteId}/locations`
  }
  if (locationId) {
    service = `locations/${locationId}`
  }
  return api(service, { params })
}

/**
 * Get Locations thunk
 * @param {string | number} siteId
 */
export const getLocationsApi = (pathname, siteId) => (dispatch, getState) => {
  const ref = siteId || SHOW_ALL
  const locationInStore = getLocationsState(getState())[ref]
  const parentInStore = ref === SHOW_ALL || getSiteByIdState(getState())[ref]
  return Promise.resolve()
    .then(() =>
      locationInStore && parentInStore ? null : dispatch(toggleLoader(true))
    )
    .then(() =>
      Promise.all([
        getLocationRequest({ parentId: siteId }).then(
          ({ data, ...pagination }) => {
            debug(data)
            dispatch(calculatePagination(pagination, pathname))
            return dispatch(updateLocationInfo(data, ref))
          }
        ),
        siteId ? dispatch(getSingleSiteApi(siteId)) : null,
      ])
    )
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return locationInStore && parentInStore
        ? null
        : dispatch(toggleLoader(false, true))
    })
}

// --- Next Page Locations ---

export const nextPageLocationsApi = (
  parentId,
  params,
  paginationRef
) => dispatch =>
  dispatch(
    nextPageForLists(
      parentId,
      params,
      paginationRef,
      getLocationRequest,
      nextPageLocations
    )
  )

// --- Update ---

const patchLocationRequest = (data, id) =>
  api(`locations/${id}`, { method: 'patch', data })

export const updateLocationApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, parentId, ...newValues } = values
  const current = getLocationsState(getState())[parentId]
  const { sensorCount, name } = getLocationByIdState(getState())[id]
  const newName = values.name || name
  return Promise.resolve()
    .then(() => patchLocationRequest(newValues, id)) // send patch request
    .then(response => {
      const updated = { ...response, sensorCount }
      return dispatch(
        findAndUpdate(
          current,
          updated,
          id,
          parentId,
          updateLocationById,
          updateLocationInfo
        )
      )
    })
    .then(() =>
      newName === name
        ? null
        : dispatch(updateLastBreadcrumbTitleApi('location', id, newName))
    )
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newName))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, `There was an issue updating ${name}.`)
      )
    })
}

// --- delete ---

// export const locationDelete = ()

// --- Redux ---

const initialState = {
  locations: {},
  locationById: {},
}

const locations = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_LOCATION_INFO:
    case UPDATE_LOCATION_BY_ID:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case NEXT_PAGE_LOCATIONS: {
      const current = state.locations[reference] || []
      return {
        ...state,
        locations: {
          ...state.locations,
          [reference]: [...current, ...payload],
        },
      }
    }
    default:
      return state
  }
}

export const getLocations = state => state.locations
export const getLocationById = state => state.locationById

export default locations
