import styled from '@emotion/styled'

const Icon = styled('i')`
  font-size: ${props => props.iconSize || props.theme.iconSize || 'inherit'};
  text-align: ${props => props.textAlign || 'inherit'};
`

export const CenteredIcon = styled('i')`
  font-size: ${props => props.iconSize || props.theme.iconSize || 'inherit'};
  text-align: center;
  margin: auto;
  width: 100%;
`

export const GoodIcon = styled(CenteredIcon)`
  color: ${props => props.theme.success};
`

export const WarningIcon = styled(GoodIcon)`
  color: ${props => props.theme.hover.warning};
`

export const DangerIcon = styled(GoodIcon)`
  color: ${props => props.theme.danger};
`

export default Icon
