import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDatesState } from '../../redux/reducer'

const CopyrightNotice = ({ className, year }) => (
  <div className={className}>© REMOTE TECH LTD {year}</div>
)
CopyrightNotice.propTypes = {
  className: PropTypes.string,
  year: PropTypes.number,
}

CopyrightNotice.defaultProps = {
  className: null,
  year: null,
}

const mapStateToProps = state => ({
  year: getDatesState(state).currentYear,
})

export default connect(mapStateToProps)(React.memo(CopyrightNotice))
