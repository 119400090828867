import Debug from 'debug'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { errorHandler } from '../errorState/errorState'
import { SHOW_ALL } from '../../Routes/routesConstants'

const debug = Debug('web:paginationState:')
// --- Constants ---

const PAGINATION_DATA = 'paginationData'

// --- Actions ---

const UPDATE_PAGINATION = 'UPDATE_PAGINATION'
const PAGINATION_LOADING = 'PAGINATION_LOADING'
const RESET_PAGINATION = 'RESET_PAGINATION'

// --- Action Creators ---

/**
 * updates the pagination information by page
 * @param {{}} paginationInfo object containing the pagination info
 * @param {*} reference reference to the data, pathname is an example
 */
export const updatePagination = (paginationInfo, reference) => ({
  type: UPDATE_PAGINATION,
  payload: { ...paginationInfo },
  reference: PAGINATION_DATA,
  id: [reference],
})

export const paginationLoading = loading => ({
  type: PAGINATION_LOADING,
  payload: { loading },
})

export const resetPagination = (page, $limit) => ({
  type: RESET_PAGINATION,
  payload: { page, $limit },
  reference: PAGINATION_DATA,
})

// --- Calculate Pagination ---

export const calculatePagination = (pagination, reference) => dispatch => {
  const {
    available = 0,
    total: tRequest,
    $skip,
    $limit,
    max_limit,
  } = pagination
  const total = $skip + tRequest
  const hasMore = total < available
  const page = Math.ceil(
    $limit < max_limit ? total / $limit : total / max_limit
  )
  dispatch(updatePagination({ ...pagination, total, hasMore, page }, reference))
}

// --- Next Page for main lists ---

/**
 *
 * @param {string|number} parentId id of any parent for the request
 * @param {{}} params object providing pagination params to the request
 * @param {string} paginationRef defines the pagination reference in redux
 * @param {func} requestFunction the function for the request
 * @param {func} reduxActionFunction the dispatch action to update the redux store
 */
export const nextPageForLists = (
  parentId,
  params,
  paginationRef,
  requestFunction,
  reduxActionFunction
) => dispatch =>
  Promise.resolve()
    .then(() => dispatch(toggleLoader(true, false, paginationRef)))
    .then(() => requestFunction({ parentId, params }))
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination(pagination, paginationRef))
      return data
    })
    .then(data => {
      const ref = parentId || SHOW_ALL
      return dispatch(reduxActionFunction(data, ref))
    })
    .then(() => dispatch(toggleLoader(false, false, paginationRef)))
    .catch(err => {
      debug(err, 'nextPage')
      errorHandler(err)
      dispatch(toggleLoader(false, false, paginationRef))
    })

// --- redux ---

const initialState = { [PAGINATION_DATA]: {}, loading: false }

const paginationReducer = (state = initialState, action) => {
  const { type, payload, reference, id } = action
  switch (type) {
    case PAGINATION_LOADING:
      return { ...state, ...payload }
    case UPDATE_PAGINATION:
      return {
        ...state,
        [reference]: {
          ...state[reference],
          [id]: state[reference][id]
            ? { ...state[reference][id], ...payload }
            : { ...payload },
        },
      }
    default:
      return state
  }
}

export const getPagination = state => state.paginationData
export const getPaginationLoading = state => state.loading

export default paginationReducer
