import Debug from 'debug'
import api from '../../api'
import { getCompaniesState, getCompanyByIdState } from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { toggleModal } from '../modalState/modalState'
import { errorHandler } from '../errorState/errorState'
import { updateLastBreadcrumbTitleApi } from '../breadcrumbState/breadcrumbState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import findAndUpdate from '../../utils/findAndUpdate/findAndUpdate'
import {
  calculatePagination,
  nextPageForLists,
} from '../paginationState/paginationState'

const debug = Debug('web:companiesState:')

// --- Actions ---

const UPDATE_COMPANIES = 'UPDATE_COMPANIES'
const UPDATE_COMPANY_BY_ID = 'UPDATE_COMPANY_BY_ID'
const NEXT_COMPANY_PAGE = 'NEXT_COMPANY_PAGE'

// --- Action Creators ---

const updateCompanies = companies => ({
  type: UPDATE_COMPANIES,
  payload: { companies },
})

export const updateCompanyById = (company, id) => ({
  type: UPDATE_COMPANY_BY_ID,
  payload: { [id]: company },
  reference: 'companyById',
})

const nextCompanyPage = companies => ({
  type: NEXT_COMPANY_PAGE,
  payload: companies,
})

// --- Create ---

export const postNewCompanyRequest = company =>
  api('/companies', { method: 'post', data: company })

export const addNewCompany = (company, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { name } = company
  return Promise.resolve()
    .then(() => postNewCompanyRequest(company))
    .then(response => {
      const currentCompanies = getCompaniesState(getState())
      return [...currentCompanies, response]
    })
    .then(companies => dispatch(updateCompanies(companies)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created', name))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue adding the new company.'
        )
      )
    })
}

// --- Read ---

/**
 * request to api to get companies
 */
export const getCompaniesRequest = ({ companyId, params = { $limit: 20 } }) =>
  api(companyId ? `companies/${companyId}` : 'companies', { params })

/**
 * thunk to load companies and control page loader
 */
export const getCompaniesApi = pathname => (dispatch, getState) => {
  const companiesInStore = getCompaniesState(getState()).length > 0
  return Promise.resolve()
    .then(() => (companiesInStore ? null : dispatch(toggleLoader(true))))
    .then(() => getCompaniesRequest({}))
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination(pagination, pathname))
      return dispatch(updateCompanies(data))
    })
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return companiesInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// -- get next company page --

export const getNextCompanyPageApi = (params, paginationRef) => dispatch =>
  dispatch(
    nextPageForLists(
      null, // no parentId for companies
      params,
      paginationRef,
      getCompaniesRequest,
      nextCompanyPage
    )
  )

// --- Update ---

const patchCompanyRequest = (company, id) =>
  api(`companies/${id}`, { method: 'patch', data: company })

export const updateCompanyApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, ...company } = values
  const current = getCompaniesState(getState())
  const { clientCount, name } = getCompanyByIdState(getState())[id]
  const newName = values.name || name
  return Promise.resolve()
    .then(() => patchCompanyRequest(company, id)) // send patch request
    .then(response => {
      const updated = { ...response, clientCount }
      return dispatch(
        findAndUpdate(
          current,
          updated,
          id,
          null,
          updateCompanyById,
          updateCompanies
        )
      )
    })
    .then(() =>
      newName === name
        ? null
        : dispatch(updateLastBreadcrumbTitleApi('company', id, newName))
    )
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newName))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, `There was an issue updating ${name}.`)
      )
    })
}

// --- redux ---

const initialState = {
  companies: [],
  companyById: {},
}

const companies = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_COMPANIES:
      return { ...state, ...payload }
    case UPDATE_COMPANY_BY_ID:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case NEXT_COMPANY_PAGE: {
      const current = state.companies || []
      return { ...state, companies: [...current, ...payload] }
    }
    default:
      return state
  }
}

export const getCompanies = state => state.companies
export const getCompanyById = state => state.companyById

export default companies
