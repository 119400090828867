import Debug from 'debug'
import { parse, stringify } from 'qs'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'

const debug = Debug('web:searchState:')
// --- Actions ---

const UPDATE_SEARCH_INPUT = 'UPDATE_SEARCH_INPUT'
const TOGGLE_SEARCH_BOX = 'TOGGLE_SEARCH_BOX'
const RESET_AND_REMOVE_SEARCH_BOX = 'RESET_AND_REMOVE_SEARCH_BOX'

// --- Action Creators ---
// todo: remove export once all searches use server side
export const updateSearchInput = searchValue => ({
  type: UPDATE_SEARCH_INPUT,
  payload: { searchValue },
})

export const toggleSearchBox = (searchBoxVisible = true) => ({
  type: TOGGLE_SEARCH_BOX,
  payload: { searchBoxVisible },
})

export const resetAndRemoveSearchBox = () => ({
  type: RESET_AND_REMOVE_SEARCH_BOX,
})

// --- Search Thunk ---

/**
 * performs a search request and updates the redux state in the correct places
 * @param {*} url
 */
export const performSearchRequest = ({
  pathname,
  searchValue,
  search: searchQS,
  push,
}) => dispatch => {
  dispatch(toggleLoader(true, false, 'search'))
  dispatch(updateSearchInput(searchValue))

  debug(`search:${pathname}?${searchValue}`)
  const query = parse(searchQS, { ignoreQueryPrefix: true })
  if (searchValue) {
    query.search = searchValue
  } else {
    delete query.search
  }
  const search = stringify(query)
  push({ search })
}

export const getSearchFromQS = searchQS => {
  const { search = '' } = parse(searchQS, { ignoreQueryPrefix: true })
  return search
}

// --- Redux ---

const initialState = {
  searchBoxVisible: false,
}

const searchReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case UPDATE_SEARCH_INPUT:
    case TOGGLE_SEARCH_BOX:
      return { ...state, ...payload }
    case RESET_AND_REMOVE_SEARCH_BOX:
      return { ...state, searchValue: undefined, searchBoxVisible: false }
    default:
      return state
  }
}

export const getSearchValue = state => state.searchValue
export const getSearchBoxVisible = state => state.searchBoxVisible

export default searchReducer
