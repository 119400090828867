import styled from '@emotion/styled'
import React from 'react'
import PropTypes from 'prop-types'
import Img from '../Img/Img'

// styles

const ProfileImgContainer = styled('div')`
  grid-area: profilePic;
  display: flex;
  justify-content: center;
`

const ProfileImg = styled(Img)`
  border-radius: 50%;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  object-fit: cover;
`
const UserIcon = styled('i')`
  text-align: center;
  font-size: ${props => `${props.size}px`};
  color: ${props => props.theme.muted};
`

// Fallback in case the image fails to load

const Fallback = ({ size, fallbackIcon }) => (
  <UserIcon className={fallbackIcon || 'fas fa-user-circle'} size={size} />
)

Fallback.propTypes = {
  size: PropTypes.number.isRequired,
  fallbackIcon: PropTypes.string,
}

Fallback.defaultProps = {
  fallbackIcon: null,
}

// component

const ProfilePhoto = ({
  profilePic,
  className,
  size,
  fallbackIcon,
  fallbackIconSize,
}) => (
  <ProfileImgContainer className={className}>
    {profilePic ? (
      <ProfileImg
        src={profilePic}
        alt="Profile"
        fallback={() => (
          <Fallback
            size={fallbackIconSize || size}
            fallbackIcon={fallbackIcon}
          />
        )}
        size={size}
      />
    ) : (
      <Fallback size={fallbackIconSize || size} fallbackIcon={fallbackIcon} />
    )}
  </ProfileImgContainer>
)

ProfilePhoto.propTypes = {
  profilePic: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  fallbackIcon: PropTypes.string,
  fallbackIconSize: PropTypes.number,
}
ProfilePhoto.defaultProps = {
  profilePic: null,
  className: null,
  size: 32,
  fallbackIcon: null,
  fallbackIconSize: null,
}
export default React.memo(ProfilePhoto)
