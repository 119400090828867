import {
  getPageLoaderWaitListState,
  getPageLoaderState,
} from '../../redux/reducer'

// --- Actions ---

const TOGGLE_LOADER = 'TOGGLE_LOADER'
const ADD_TO_PAGE_LOADER_WAIT_LIST = 'ADD_TO_PAGE_LOADER_WAIT_LIST'
const UPDATE_PAGE_LOADER_WAIT_LIST = 'UPDATE_PAGE_LOADER_WAIT_LIST'

// --- Action Creators ---

/**
 * toggles the loader and error for a page
 * @param {boolean} loaderOn
 * @param {boolean | null} error
 */
export const toggleLoader = (
  loaderOn,
  error = false,
  loader = 'pageLoader'
) => ({
  type: TOGGLE_LOADER,
  payload: { [loader]: { loaderOn, error } },
})

/**
 * Adds the apiAction to the loaderWaitList
 * @param {string} apiAction the reference to the action in progress that the loader should wait for
 */
export const addToLoaderWaitList = apiAction => ({
  type: ADD_TO_PAGE_LOADER_WAIT_LIST,
  payload: apiAction,
  reference: 'pageLoaderWaitList',
})

export const updatePageLoaderWaitList = pageLoaderWaitList => ({
  type: UPDATE_PAGE_LOADER_WAIT_LIST,
  payload: { pageLoaderWaitList },
})

// --- Remove from wait list and check if the loader can be toggled ---

/**
 * Removes a referenced API action from the pageLoaderWaitList
 * Also checks if the waitList is empty then it toggles the loader
 * @param {string} apiAction the reference to the api action that has completed
 */
export const removeFromLoaderWaitList = (apiAction, notUpdateLoader) => (
  dispatch,
  getState
) => {
  const waitList = getPageLoaderWaitListState(getState())
  const { error = false } = getPageLoaderState(getState())
  const newWaitList = waitList.filter(i => i !== apiAction)
  dispatch(updatePageLoaderWaitList(newWaitList))
  if (newWaitList.length === 0 && !notUpdateLoader) {
    dispatch(toggleLoader(false, error))
  }
}

const initialState = {
  pageLoader: { loaderOn: true, error: false },
  pageLoaderWaitList: [],
}

const pageLoaderReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case TOGGLE_LOADER:
    case UPDATE_PAGE_LOADER_WAIT_LIST:
      return { ...state, ...payload }
    case ADD_TO_PAGE_LOADER_WAIT_LIST:
      return { ...state, [reference]: [...state[reference], payload] }
    default:
      return state
  }
}

export const getPageLoader = state => state.pageLoader
export const getPageLoaderWaitList = state => state.pageLoaderWaitList
export const getAnyLoader = state => state

export default pageLoaderReducer
