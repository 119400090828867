import Debug from 'debug'
import api from '../../api'
import { riskPrefix } from '../l8LogbookState/l8LogbookState'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { errorHandler, updateErrors } from '../errorState/errorState'
import { getDocsToDeleteState } from '../../redux/reducer'
import { removeOnlyDocumentApi } from '../l8LogbookDocumentState/l8LogbookDocumentState'
import { updateReportLoadingStatus } from '../reportsState/reportsState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { toggleModal } from '../modalState/modalState'
import { createDownloadLinkAndClick } from '../../utils/downloadDocument/downloadDocument'
import readFileAsPromise from '../../utils/readFileAsPromise/readFileAsPromise'

const debug = Debug('web:assessmentsState:')
// --- Actions ---

const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT'
const UPDATE_ASSESSMENTS_BY_JOB = 'UPDATE_ASSESSMENTS_BY_JOB'

// --- Action creator ---

export const updateAssessment = (assessment, id) => ({
  type: UPDATE_ASSESSMENT,
  payload: { [id]: assessment },
  reference: 'assessments',
})

export const updateAssessmentsByJob = (assessments, jobId) => ({
  type: UPDATE_ASSESSMENTS_BY_JOB,
  payload: { [jobId]: assessments },
  reference: 'assessmentsByJobId',
})

// --- create assessment ---

const postAssessmentRequest = data =>
  api(`${riskPrefix}/assessments`, { method: 'post', data })

export const createAssessmentApi = (body, errRef) => dispatch => {
  dispatch(toggleLoader(true, false, 'createAssessment'))
  return postAssessmentRequest(body)
    .then(response => {
      dispatch(updateAssessment(response, response._id))
      dispatch(updateAssessmentsByJob([response._id], response.job_id))
    })
    .then(() => dispatch(toggleLoader(false, false, 'createAssessment')))
    .catch(err => {
      debug(err)
      dispatch(toggleLoader(false, false, 'createAssessment'))
      errorHandler(
        err,
        errRef,
        `There was an issue creating the assessment to begin the job.`
      )
    })
}

// --- get assessment ---

const getAssessmentRequest = (assessmentId, params) =>
  api(`${riskPrefix}/assessments${assessmentId ? `/${assessmentId}` : ''}`, {
    params,
  })

// export const getAssessmentApi = assessmentId => dispatch =>
//          getAssessmentRequest(assessmentId).then(response => {
//            dispatch(updateAssessment(response, response._id))
//            return response
//          })

export const getAssessmentsByJobIdApi = job_id => dispatch =>
  getAssessmentRequest(null, { job_id })
    .then(
      ({ data }) =>
        data &&
        Promise.all(
          data.map(ass => {
            dispatch(dispatch(updateAssessment(ass, ass._id)))
            return ass._id
          })
        )
    )
    .then(assessments => {
      dispatch(updateAssessmentsByJob(assessments, job_id))
      return assessments
    })

// --- clean up assessment docs from docsToDelete ---

const cleanUpDocsToDelete = ({ parentId, errRef }) => (dispatch, getState) => {
  const docsToDelete = getDocsToDeleteState(getState())
  if (Array.isArray(docsToDelete) && docsToDelete.length > 0) {
    docsToDelete.forEach(doc =>
      dispatch(removeOnlyDocumentApi(doc, parentId, errRef, true))
    )
  }
}

// --- create assessment section ---

const postSectionRequest = (assessmentId, data) =>
  api(`${riskPrefix}/assessments/${assessmentId}/sections`, {
    method: 'post',
    data,
  })

const patchSectionRequest = (assessmentId, sectionId, data) =>
  api(`${riskPrefix}/assessments/${assessmentId}/sections/${sectionId}`, {
    method: 'patch',
    data,
  })

export const updateSectionApi = ({
  sectionId,
  values,
  setSubmitting,
  resetForm,
  assessmentId,
  errRef,
  isSubmit,
  push,
}) => dispatch => {
  dispatch(updateErrors(errRef, null))
  return Promise.resolve()
    .then(() =>
      sectionId
        ? patchSectionRequest(assessmentId, sectionId, values)
        : postSectionRequest(assessmentId, { sections: [values] })
    )
    .then(response => {
      dispatch(updateAssessment(response, response._id))
      return response
    })
    .then(response => {
      setSubmitting(false)
      resetForm(values)
      if (!sectionId) {
        const { sections } = response
        /**
         * assumes the last assessment in the array is the new one
         * OR that a sectionId will be present
         * */

        const { _id } = sections[sections.length - 1]
        if (_id) {
          push({
            search: `?sectionId=${_id}`,
          })
        }
      }
      return response
    })
    .then(() =>
      successToaster(
        isSubmit ? 'submitted your assessment' : 'saved your progress'
      )
    )
    .then(() => isSubmit && dispatch(toggleModal('submitAssessment', true)))
    .then(() => dispatch(cleanUpDocsToDelete({ errRef }))) // todo: parent and errRef
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          errRef,
          'There was an issue saving your assessment, please try again.'
        )
      )
    })
}

// --- download an assessment report ---

const downloadAssessmentReportRequest = ({
  assessment_id,
  template_name = 'tank_inspection',
  format = 'pdf',
  delivery_method = 'email',
}) =>
  api(`${riskPrefix}/reports`, {
    params: { assessment_id, template_name, format, delivery_method },
    responseType: 'blob',
  })

export const downloadAssessmentReportApi = ({
  assessmentId,
  delivery_method,
  jobMongoId,
  file_name,
}) => dispatch => {
  dispatch(
    updateReportLoadingStatus(jobMongoId, {
      loading: true,
      error: false,
    })
  )

  downloadAssessmentReportRequest({
    assessment_id: assessmentId,
    delivery_method,
  })
    .then(response => {
      const { type } = response || {}
      // if the blob is type json read the blob and show the message in the toaster.
      if (type === 'application/json') {
        return readFileAsPromise(response, false).then(rawMsg => {
          const { message } = JSON.parse(rawMsg)
          // if the message is try again (which may occur if the report is being re-generated)
          if (message === 'try again later') {
            return dispatch(
              downloadAssessmentReportApi({
                assessmentId,
                delivery_method: 'email',
                jobMongoId,
              })
            )
          }
          dispatch(
            updateReportLoadingStatus(jobMongoId, {
              loading: false,
              error: false,
            })
          )
          return successToaster(
            `registered to download this report an`,
            message
          )
        })
      }
      // else download the blob
      return Promise.resolve(URL.createObjectURL(response)).then(blobUrl =>
        createDownloadLinkAndClick(blobUrl, file_name, 'pdf').then(() =>
          dispatch(
            updateReportLoadingStatus(jobMongoId, {
              loading: false,
              error: false,
            })
          )
        )
      )
    })
    .catch(err => {
      debug(err)
      dispatch(
        updateReportLoadingStatus(jobMongoId, {
          loading: false,
          error: true,
          message: err,
        })
      )
      dispatch(errorHandler(err))
    })
}

// --- redux ---

const initialState = {
  assessmentsByJobId: {},
}

const assessmentReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_ASSESSMENT:
    case UPDATE_ASSESSMENTS_BY_JOB:
      return {
        ...state,
        [reference]: { ...state[reference], ...payload },
      }
    default:
      return state
  }
}

export const getAssessment = state => state.assessments
export const getAssessmentsByJobId = state => state.assessmentsByJobId

export default assessmentReducer
