import Debug from 'debug'
import api from '../../api'
import { riskPrefix } from '../l8LogbookState/l8LogbookState'
import { errorHandler } from '../errorState/errorState'
import {
  getTemplateByIdState,
  getTemplatesByCompanyIdState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'

const debug = Debug('web:templatesState:')

// --- Actions ---

const UPDATE_TEMPLATE_BY_ID = 'UPDATE_TEMPLATE_BY_ID'
const UPDATE_COMPANY_TEMPLATE_IDS = 'UPDATE_COMPANY_TEMPLATE_IDS'
const UPDATE_OPTIONS_LISTS_FOR_TEMPLATES = 'UPDATE_OPTIONS_LISTS_FOR_TEMPLATES'

// --- Action creators ---

const updateTemplateById = (templateId, template) => ({
  type: UPDATE_TEMPLATE_BY_ID,
  payload: { [templateId]: template },
  reference: 'templates',
})

const updateCompanyTemplateIds = (companyId, templates) => ({
  type: UPDATE_COMPANY_TEMPLATE_IDS,
  payload: { [companyId]: templates },
  reference: 'companyTemplates',
})

const updateOptionsListsForTemplates = (companyId, options) => ({
  type: UPDATE_OPTIONS_LISTS_FOR_TEMPLATES,
  payload: { [companyId]: options },
  reference: 'lists',
})

// Post template

/**
 *
 * @param {{}} data body of the post request
 * @param {string} companyId id of the company to hold the template
 */
const postTemplateRequest = (data, companyId) =>
  api(`${riskPrefix}/companies/${companyId}/templates`, {
    method: 'post',
    data,
  })

const patchTemplateRequest = (data, companyId, templateId) =>
  api(`${riskPrefix}/companies/${companyId}/templates/${templateId}`, {
    method: 'patch',
    data,
  })

export const saveTemplateApi = ({
  values,
  setSubmitting,
  resetForm,
  errRef,
  companyId,
  templateId,
  push,
  pathname,
}) => dispatch => {
  // remove the data from the preview before posting
  const {
    questions: originalQs = [],
    name,
    created_at, // these next variables are to remove them from a patch
    created_by,
    updated_at,
    updated_by,
    _id,
    company_id,
    ...otherMetadata
  } = values
  const questions = originalQs.map(q => {
    const { risk, typeTitle, demoAnswer, optionsList, ...cleanedObject } = q
    if (typeof optionsList === 'string' && optionsList !== '') {
      return { ...cleanedObject, options: optionsList, optionsList }
    }
    return { ...cleanedObject, optionsList }
  })

  const data = { ...otherMetadata, name, questions }
  Promise.resolve()
    .then(() =>
      templateId
        ? patchTemplateRequest(data, companyId, templateId)
        : postTemplateRequest(data, companyId)
    )

    .then(response => {
      resetForm(values)
      dispatch(updateTemplateById(response._id, { ...response, ...values }))
      if (!templateId) {
        push(`${pathname}/${response._id}`)
      }
    })
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          errRef,
          `There was an issue submitting ${name}. Please try again.`
        )
      )
    })
}

// Get templates

/**
 * get templates
 * @param {string} companyId id of the company which controls the template
 * @param {string} templateId (optional) id of a specific template
 */
const getTemplatesRequest = (companyId, templateId, params) =>
  api(
    `${riskPrefix}/companies/${companyId}/templates${
      templateId ? `/${templateId}` : ''
    }`,
    { params }
  )

export const getTemplatesApi = (companyId, templateId, singleTemplate) => (
  dispatch,
  getState
) => {
  if (singleTemplate && !templateId) {
    return dispatch(toggleLoader(false))
  }
  let template
  if (singleTemplate) {
    template = getTemplateByIdState(getState())[templateId]
  } else {
    template = getTemplatesByCompanyIdState(getState())[companyId]
  }
  if (!template) {
    dispatch(toggleLoader(true, false))
  }
  return Promise.resolve()
    .then(() => getTemplatesRequest(companyId, templateId))
    .then(response => {
      if (singleTemplate) {
        return dispatch(updateTemplateById(templateId, response))
      }
      const { data } = response
      // todo: update a template list here providing a list of Ids
      return Promise.all(
        data.map(t => {
          dispatch(updateTemplateById(t._id, t))
          return t._id
        })
      ).then(ids => dispatch(updateCompanyTemplateIds(companyId, ids)))
    })
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

export const templateCompanySectionRef = ({ companyId, l8_section }) =>
  `${companyId}-${l8_section}`

export const getTemplatesByCompanyAndLogbookSection = (
  companyId,
  l8_section,
  modalRef
) => (dispatch, getState) => {
  const ref = templateCompanySectionRef({ companyId, l8_section })
  const inStore = getTemplatesByCompanyIdState(getState())[ref]
  if (!inStore) {
    dispatch(toggleLoader(true, false, ref))
  }
  let params = {}
  if (l8_section !== 'riskAssessment') {
    params = { l8_section }
  }
  return getTemplatesRequest(companyId, null, params)
    .then(({ data }) =>
      data.map(t => {
        dispatch(updateTemplateById(t._id, t))
        return t._id
      })
    )
    .then(ids => ids.length > 0 && dispatch(updateCompanyTemplateIds(ref, ids)))
    .then(() => dispatch(toggleLoader(false, false, ref)))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an error loading your assessment templates.'
        )
      )
      dispatch(toggleLoader(false, true, ref))
    })
}

// get options lists

const getOptionsListRequest = companyId =>
  api(`${riskPrefix}/companies/${companyId}/options`, {
    params: { $limit: 999 },
  })

export const getOptionsListApi = (companyId, errRef) => dispatch =>
  getOptionsListRequest(companyId)
    .then(({ data }) =>
      dispatch(updateOptionsListsForTemplates(companyId, data))
    )
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          errRef,
          `There was an issue populating the pre-generated answer lists.
           You can continue to create templates by manually adding answer options, where applicable.`
        )
      )
    })

// --- redux ---

const initialState = {
  templates: {},
  companyTemplates: {},
  lists: {},
}

const templatesState = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_TEMPLATE_BY_ID:
    case UPDATE_COMPANY_TEMPLATE_IDS:
    case UPDATE_OPTIONS_LISTS_FOR_TEMPLATES:
      return {
        ...state,
        [reference]: { ...state[reference], ...payload },
      }
    default:
      return state
  }
}

export const getTemplateById = state => state.templates
export const getTemplatesByCompanyId = state => state.companyTemplates
export const getOptionsListByCompanyId = state => state.lists

export default templatesState
