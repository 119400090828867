import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import invertedLogo from './L8Log.svg'
import logo from './L8Log-colour.svg'
import Img from '../Img/Img'

const Logo = styled(Img)`
  height: 3rem;
  line-height: 3rem;
  vertical-align: bottom;
  margin-right: 0.5rem;
  width: auto;
`
const L8LogLogo = ({ siteLogo, className, inverted }) => (
  <Logo
    src={siteLogo || (inverted ? invertedLogo : logo)}
    alt="L8 Logbook"
    className={className}
    fallback={() => (
      <Logo
        src={inverted ? invertedLogo : logo}
        alt="L8 Logbook"
        className={className}
      />
    )}
  />
)

L8LogLogo.propTypes = {
  siteLogo: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
}
L8LogLogo.defaultProps = {
  siteLogo: null,
  className: null,
  inverted: false,
}
export default React.memo(L8LogLogo)
