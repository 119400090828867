import React from 'react'
import PropTypes from 'prop-types'
import NavigationItem from './NavigationItem/NavigationItem'

const NavigationItems = ({ items, pathname, bottomborder }) =>
  items.map(item => (
    <NavigationItem
      key={item.link}
      {...item}
      pathname={pathname}
      bottomborder={bottomborder}
    />
  ))

NavigationItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  pathname: PropTypes.string,
  bottomborder: PropTypes.string,
}
NavigationItems.defaultProps = {
  items: [],
  pathname: '/',
  bottomborder: null,
}

export default NavigationItems
