import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * includes a component prop to render a fallback component
 * This has been wrapped by the HOC Img
 */
export class FallbackImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgError: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props
    const { src: prevSrc } = prevProps
    if (src !== prevSrc) {
      this.resetErrorForNewUrl()
    }
  }

  onError = () => {
    this.setState({ imgError: true })
  }

  resetErrorForNewUrl = () => {
    this.setState({ imgError: false })
  }

  render() {
    const { component: FallbackComponent, alt, ...otherProps } = this.props
    const { imgError } = this.state
    if (!imgError) {
      return <img {...otherProps} onError={this.onError} alt={alt} />
    }
    if (FallbackComponent) {
      return <FallbackComponent />
    }
    return null
  }
}

FallbackImg.propTypes = {
  component: PropTypes.func,
  alt: PropTypes.string,
  src: PropTypes.string,
}

FallbackImg.defaultProps = {
  component: null,
  alt: null,
  src: 'forceFallback',
}

/**
 * Adds a fallback onError prop to a standard img tag
 * @param {Component} fallback takes a component and renders it if the img fails to load
 */
const Img = ({ fallback, ...otherProps }) => (
  <FallbackImg component={fallback} {...otherProps} />
)

export default React.memo(Img)
