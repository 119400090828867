import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { fadeOut } from '../../../css/animations'

import { DangerAlert, SuccessAlert } from '../../Alert/Alert'

const FadeSuccessMessage = styled(SuccessAlert)`
  max-width: 100%;
  animation-name: ${props => (props.success ? fadeOut : null)};
  animation-duration: 0.5s;
  animation-delay: 4.5s;
  animation-timing-function: ease-in-out;
`

const FormErrors = ({ externalErrors, className }) =>
  externalErrors ? (
    <div className={className} data-cy="formErrors">
      {externalErrors.msg &&
      typeof externalErrors.type === 'string' &&
      externalErrors.type === 'success' ? (
        <FadeSuccessMessage>{externalErrors.msg}</FadeSuccessMessage>
      ) : (
        <DangerAlert>{externalErrors.msg}</DangerAlert>
      )}
    </div>
  ) : null

FormErrors.propTypes = {
  externalErrors: PropTypes.objectOf(PropTypes.node),
  className: PropTypes.string,
}
FormErrors.defaultProps = {
  externalErrors: null,
  className: null,
}
export default React.memo(FormErrors)
