import moment from 'moment'

// --- redux ---

const dateNow = moment()
const currentYear = +dateNow.format('YYYY')
const currentMonthName = dateNow.format('MMM')
/**
 * current month and week begin at 0
 */
const currentMonth = dateNow.month() + 1
const currentWeek = +dateNow.week()

/**
 * calculates the quarter of the year
 */
const currentQuarterCalc = () => {
  if (currentMonth < 4) {
    return 1
  }
  if (currentMonth < 7) {
    return 2
  }
  if (currentMonth < 10) {
    return 3
  }
  return 4
}

const initialState = {
  currentYear,
  currentMonth,
  currentWeek,
  currentMonthName,
  currentQuarter: currentQuarterCalc(),
}

const dateReducer = (state = initialState) => state

export const getDates = state => state

export default dateReducer
