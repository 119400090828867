import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { debounce } from 'lodash'
import SearchInput from './SearchInput/SearchInput'
import { performSearchRequest } from '../../../reducers/searchState/searchState'

const Search = ({ searchBoxVisible, className, sendSearchRequest }) => {
  // debounce only worked when held outside of the input component
  const debouncedSearch = debounce(v => sendSearchRequest(v), 400, {
    leading: false,
    trailing: true,
  })
  return searchBoxVisible ? (
    <Formik
      initialValues={{ search: '' }}
      render={({ resetForm, values, setFieldValue }) => (
        <SearchInput
          className={className}
          resetForm={resetForm}
          values={values}
          debouncedSearch={debouncedSearch}
          sendSearchRequest={sendSearchRequest}
          setFieldValue={setFieldValue}
        />
      )}
    />
  ) : null
}

Search.propTypes = {
  searchBoxVisible: PropTypes.bool,
  className: PropTypes.string,
  sendSearchRequest: PropTypes.func.isRequired,
}

Search.defaultProps = {
  searchBoxVisible: false,
  className: null,
}

const mapDispatchToProps = (
  dispatch,
  { location: { pathname, search }, history: { push } }
) => ({
  sendSearchRequest: searchValue =>
    dispatch(performSearchRequest({ pathname, searchValue, search, push })),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(React.memo(Search))
)
