import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from '@emotion/styled'
import Helmet from '../../components/Helmet/Helmet'
import LoginForm from './LoginForm/LoginForm'
import {
  getLoginNoticeState,
  getLoginStatusState,
  getAuthUserEmailState,
} from '../../redux/reducer'
import {
  AUTHENTICATED,
  TWO_FACTOR_AUTH,
  LOGGED_OUT,
} from '../../reducers/loginState/loginState'
import VerificationTokenForm from './VerificationTokenForm/VerificationTokenForm'
import Link from '../../components/Link/Link'
import { SuccessAlert } from '../../components/Alert/Alert'
import L8LogLogo from '../../components/L8LogLogo/L8LogLogo'
import SideImages from './SideImages/SideImages'
import CopyrightNotice from '../../components/CopyrightNotice/CopyrightNotice'

const CopyRight = styled(CopyrightNotice)`
  text-align: center;
  font-size: 1rem;
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.muted};
`
const SupportLink = styled(Link)`
  color: ${props => props.theme.muted};
  text-align: center;
  font-weight: 600;
  :hover {
    color: ${props => props.theme.primary};
  }
`

const FormContainer = styled('div')`
  max-width: 500px;
  flex-grow: 1;
  width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-gap: 3rem;
  align-self: center;
  margin: auto;
`

const Logo = styled(L8LogLogo)`
  height: 5rem;
  line-height: 5rem;
  margin: auto;
  @media (min-width: 768px) {
    height: 8rem;
    line-height: 8rem;
  }
`

const Page = styled('div')`
  background-color: #fcfcfc;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100vw;
  overflow-y: scroll;
`

const Form = styled('div')`
  width: 100%;
`

export const LOGIN_REFERENCE = 'loginForm'
const VERIFICATION_TOKEN_REFERENCE = 'verificationTokenForm'

const LoginPage = ({ authenticated, notice, location, email }) => {
  const { state = { from: { pathname: '/' } } } = location || {}
  const { from } = state
  const { pathname = '/', search = '', hash = '' } = from || {}
  if (authenticated === AUTHENTICATED) {
    return <Redirect to={{ pathname, search, hash }} />
  }
  return (
    <Page>
      <Helmet title="Login" />
      <SideImages />
      <FormContainer>
        <Logo />
        {authenticated === TWO_FACTOR_AUTH ? (
          <>
            <Form>
              <VerificationTokenForm reference={VERIFICATION_TOKEN_REFERENCE} />
            </Form>
            <SupportLink to="mailto:support@remote-tech.co.uk">
              Cannot verify? support@remote-tech.co.uk
            </SupportLink>
          </>
        ) : (
          <>
            <Form>
              {notice && <SuccessAlert>{notice}</SuccessAlert>}
              <LoginForm
                reference={LOGIN_REFERENCE}
                initialValues={{ email }}
                from={from}
              />
            </Form>
            <CopyRight />
          </>
        )}
      </FormContainer>
    </Page>
  )
}

LoginPage.propTypes = {
  authenticated: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
  notice: PropTypes.string,
  email: PropTypes.string,
}

LoginPage.defaultProps = {
  authenticated: LOGGED_OUT,
  location: { state: { from: { pathname: '/' } } },
  notice: null,
  email: '',
}

const mapStateToProps = state => ({
  notice: getLoginNoticeState(state),
  authenticated: getLoginStatusState(state),
  email: getAuthUserEmailState(state),
})

export default connect(mapStateToProps)(React.memo(LoginPage))
