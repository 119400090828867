import axios from 'axios'
import qsStringify from 'qs/lib/stringify'

/**
 * creates an axios API request
 * @param {string} url [required]
 * @param {{}} object
 */
const api = (url, { ...otherParams }) => {
  const {
    baseURL = process.env.REACT_APP_API_URL,
    method = 'get',
    timeout = 30000,
    headers = { Authorization: localStorage.jwt },
    paramsSerializer = params =>
      qsStringify(params, { arrayFormat: 'brackets' }),
    ...other
  } = otherParams
  return axios({
    baseURL,
    url,
    method,
    headers,
    timeout,
    paramsSerializer,
    ...other,
  }).then(({ data }) => data)
}

export default api
