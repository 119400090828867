import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Link from '../../../components/Link/Link'

const SideMenuNavLink = styled(Link)`
  min-height: 40px;
  cursor: pointer;
  font-size: 0.875rem;
  display: grid;
  align-items: center;
  justify-items: start;
  grid-column-gap: 0.75rem;
  grid-auto-flow: column dense;
  grid-template-columns: ${props =>
    props.icon ? `${props.iconSize} 1fr auto` : '1fr auto'};
  border: none;
  border-radius: 4px;
  padding: 0 15px;
  margin: 0 15px;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  background-color: ${props =>
    props.activeLink ? props.theme.primary : 'transparent'};
  :hover {
    background-color: ${props =>
      props.activeLink
        ? props.theme.hover.primary
        : 'rgba(255, 255, 255, 0.08)'};
    color: #fff;
  }
  :focus {
    background-color: ${props =>
      props.activeLink
        ? props.theme.hover.primary
        : 'rgba(255, 255, 255, 0.15)'};
    color: #fff;
  }
  i {
    justify-self: center;

    color: ${props =>
      props.activeLink ? '#fff' : props.theme.sideMenuSectionTitle};
  }
`

SideMenuNavLink.propTypes = {
  activeLink: PropTypes.bool,
  iconSize: PropTypes.string,
  icon: PropTypes.bool,
}

SideMenuNavLink.defaultProps = {
  activeLink: false,
  iconSize: '18px',
  icon: false,
}

export default SideMenuNavLink
