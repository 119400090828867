import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import CardBody from './CardBody/CardBody'
import CloseButton from '../CloseButton/CloseButton'
import CardOutlineOnly from './CardOutlineOnly/CardOutlineOnly'

export const CardHeader = styled('div')`
  background: none;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  min-height: 3.5rem;
  background-color: ${props => props.theme.cardHeader || 'inherit'};
  display: grid;
  grid-column-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: ${props => (props.closeButton ? '1fr auto' : '1fr')};
  grid-auto-flow: column;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  :first-child {
    border-radius: 3px 3px 0 0;
  }
`

export const CardTitle = styled('div')`
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  color: ${props => props.theme.muted};
  max-width: ${props => (props.closeButton ? '310px' : '100%')};
  word-break: break-word;

  ::first-letter {
    text-transform: capitalize;
  }
`

const Card = ({
  title,
  children,
  className,
  noCardBody,
  closeButton,
  onCloseClick,
  cardBodyPadding,
  cardBodyClassName,
  ...otherProps
}) => (
  <CardOutlineOnly className={className} {...otherProps}>
    {title ? (
      <CardHeader>
        <CardTitle closeButton={closeButton}>{title}</CardTitle>
        {closeButton && <CloseButton onClick={onCloseClick} />}
      </CardHeader>
    ) : null}
    {noCardBody ? (
      children
    ) : (
      <CardBody className={cardBodyClassName} cardBodyPadding={cardBodyPadding}>
        {children}
      </CardBody>
    )}
  </CardOutlineOnly>
)

Card.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  noCardBody: PropTypes.bool,
  closeButton: PropTypes.bool,
  onCloseClick: PropTypes.func,
  cardBodyPadding: PropTypes.string,
  cardBodyClassName: PropTypes.string,
}
Card.defaultProps = {
  className: '',
  children: null,
  title: null,
  noCardBody: false,
  closeButton: false,
  onCloseClick: null,
  cardBodyPadding: null,
  cardBodyClassName: null,
}

export default Card
