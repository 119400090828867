export default [
  {
    name: 'Users',
    link: '/users',
    parent: 'RT',
    icon: 'far fa-users',
  },
  {
    name: 'Gateways',
    link: '/gateways',
    parent: 'RT',
    icon: 'far fa-wifi',
  },
  {
    name: 'Jobs',
    link: '/jobs',
    icon: 'far fa-tasks',
    parent: 'RT',
  },
]
