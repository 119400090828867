import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { init } from '@sentry/browser'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/en-gb'
import store, { history } from './redux/store'

// Use tabler dist in vendor dir for now.
// Current version is 0.0.32
// import './vendor/tabler/tabler.0.0.32.css'
import './vendor/fontawesome/css/fontawesome-all.css'

import './css/index.css'

import App from './App/App'
import ReduxThemeProvider from './ReduxThemeProvider/ReduxThemeProvider'
import { GlobalEmotionCSS } from './css/globalEmotionCSS'

// import registerServiceWorker from './registerServiceWorker'
moment.locale(['en-gb', 'en'])
if (process.env.NODE_ENV !== 'development') {
  init({ dsn: 'https://5503a57eee654fe790d9f6371c481afd@sentry.io/1215352' })
}

ReactDOM.render(
  <Provider store={store}>
    <ReduxThemeProvider>
      <Router history={history}>
        <GlobalEmotionCSS />
        <App />
      </Router>
    </ReduxThemeProvider>
  </Provider>,
  document.getElementById('root')
)
// registerServiceWorker()

if (window.Cypress) {
  window._store_ = store
}
