import styled from '@emotion/styled'

const SinglePage = styled('div')`
  label: pageContent;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow-y: auto;
`

export const NoAuthPage = styled(SinglePage)`
  background-color: ${props => props.theme.pageBackground};
`

export default SinglePage
