import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Form } from 'formik'
import FormErrors from '../../../../../../components/form/FormErrors/FormErrors'
import Field from '../../../../../../components/form/FormikField/FormikField'
import { FixedPrimaryButton } from '../../../../../../components/Buttons/Buttons'
import RequiredMessage from '../../../../../../components/RequiredMessage/RequiredMessage'
import { getErrorsState } from '../../../../../../redux/reducer'

const NewUserForm = styled(Form)`
  label: registerUser;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'first_name'
    'last_name'
    'phone_number'
    'email'
    'password'
    'confirmPassword'
    'submit';
`

const AddNewUserFormComponent = ({
  errors,
  touched,
  isSubmitting,
  dirty,
  isValid,
  externalErrors,
  humanReadableUserRole,
}) => (
  <div>
    <FormErrors externalErrors={externalErrors} />

    <RequiredMessage />
    <NewUserForm>
      <Field
        type="string"
        name="first_name"
        title="First Name"
        isRequired
        error={touched.first_name && errors.first_name}
      />
      <Field
        type="string"
        name="last_name"
        title="Last Name"
        isRequired
        error={touched.last_name && errors.last_name}
      />
      <Field
        type="string"
        name="phone_number"
        title="Phone Number"
        isRequired
        error={touched.phone_number && errors.phone_number}
      />
      <Field
        type="string"
        name="email"
        title="Email"
        isRequired
        error={touched.email && errors.email}
        disabled
      />
      <Field
        type="password"
        name="password"
        title="Password"
        isRequired
        placeholder="Minimum of 8 characters long"
        error={touched.password && errors.password}
      />
      <Field
        type="password"
        name="confirmPassword"
        title="Confirm Password"
        isRequired
        placeholder="Minimum of 8 characters long"
        error={touched.confirmPassword && errors.confirmPassword}
      />
      <FixedPrimaryButton
        style={{ gridArea: 'submit' }}
        disabled={!dirty || !isValid}
        loading={isSubmitting}
        type="submit"
      >
        Register as a new {humanReadableUserRole || 'user'}
      </FixedPrimaryButton>
    </NewUserForm>
  </div>
)

AddNewUserFormComponent.propTypes = {
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  externalErrors: PropTypes.objectOf(PropTypes.node),
  humanReadableUserRole: PropTypes.string,
}

AddNewUserFormComponent.defaultProps = {
  externalErrors: null,
  humanReadableUserRole: null,
}

const mapStateToProps = (state, { formRef }) => ({
  externalErrors: getErrorsState(state)[formRef],
})

export default connect(mapStateToProps)(AddNewUserFormComponent)
