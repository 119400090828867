import React from 'react'
import Responsive from 'react-responsive'

export const breakpoints = { desktop: 1350, tablet: 992, mobile: 768 }

const { desktop, tablet, mobile } = breakpoints

export const LargeDesktop = props => (
  <Responsive {...props} minWidth={desktop} />
)
export const Desktop = props => <Responsive {...props} minWidth={tablet} />
export const Tablet = props => (
  <Responsive {...props} minWidth={mobile} maxWidth={tablet - 1} />
)
export const Mobile = props => <Responsive {...props} maxWidth={mobile - 1} />
export const TabletAndMobile = props => (
  <Responsive {...props} maxWidth={tablet - 1} />
)

export default Responsive
