import React from 'react'
import PropTypes from 'prop-types'
import FormLabel from '../FormLabel/FormLabel'
import RequiredStar from '../../RequiredMessage/RequiredStar/RequiredStar'

const FormTitle = ({ children, isRequired, htmlFor }) =>
  children && (
    <FormLabel htmlFor={htmlFor}>
      {children}
      {isRequired && <RequiredStar> *</RequiredStar>}
    </FormLabel>
  )

FormTitle.propTypes = {
  children: PropTypes.node,
  htmlFor: PropTypes.string,
  isRequired: PropTypes.bool,
}

FormTitle.defaultProps = {
  children: null,
  isRequired: null,
  htmlFor: null,
}

export default React.memo(FormTitle)
