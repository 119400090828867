import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  getSearchBoxVisibleState,
  getBreadcrumbsState,
  getBreadcrumbLoadedState,
  getPageLoaderState,
  getShowActionButtonState,
  getActionButtonTextState,
  getMatchParamsState,
  getMatchParamsIdRefState,
  getTertiaryLinksState,
  getTMenuRefState,
  getPermissionsState,
} from '../../redux/reducer'
import BaseSearch from './Search/Search'
import BreadCrumbs from './BreadCrumbs/BreadCrumbs'
import tertiaryMenuBuilder from './tertiaryMenuBuilder/tertiaryMenuBuilder'
import { actionButtonClickApi } from '../../reducers/header'
import Hamburger from '../SideMenu/Hamburger/Hamburger'
import { SIDE_MENU_BREAK_POINT } from '../SideMenu/SideMenuContainer/SideMenuContainer'
import TabBarSwitcher from './TabBarSwitcher/TabBarSwitcher'
import { PrimaryButton } from '../../components/Buttons/Buttons'
import HeaderUser from './HeaderUser/HeaderUser'
import Container from '../../components/Container/Container'

const AllHeaderContainer = styled('div')`
  label: header;
  position: relative;
  width: 100%;
  flex-grow: 0;
  z-index: 2;
`

const HeaderContainer = styled('div')`
  background-color: ${props => props.theme.headerBackground || '#fff'};
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  width: 100%;
`
const SecondaryHeader = styled('div')`
  label: SecondaryHeader;
  display: grid;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  align-items: center;
  grid-template-columns: 30px 1fr auto;

  grid-template-rows: ${props => {
    let rows = 'auto '
    const { searchBoxVisible, showSecondaryItems } = props
    if (showSecondaryItems) {
      if (searchBoxVisible) {
        rows += '56px '
      }
    }
    return rows
  }};

  grid-template-areas: 'hamburger breadcrumbs headerUser';

  @media (min-width: ${SIDE_MENU_BREAK_POINT}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'breadcrumbs headerUser';
    min-height: 54px;
  }
`
const TertiaryRow = styled('div')`
  label: TertiaryRow;
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 6px;
  grid-template-columns: 1fr;
  grid-template-rows: ${props => {
    const { showActionButton, showSecondaryItems, searchBoxVisible } = props
    let areas = '40px'
    if (showSecondaryItems) {
      if (showActionButton) {
        areas += ' 40px'
      }
      if (searchBoxVisible) {
        areas += ' 40px'
      }
    }
    return areas
  }};
  grid-template-areas: ${props => {
    const { showActionButton, showSecondaryItems, searchBoxVisible } = props
    let areas = ''

    if (showSecondaryItems) {
      if (showActionButton) {
        areas += `'headerActionButton'`
      }
      if (searchBoxVisible) {
        areas += `'search'`
      }
      areas += `'secondaryLinks'`
    }
    return areas
  }};
  align-items: center;
  min-height: 40px;
  padding-top: ${({ showActionButton, searchBoxVisible }) =>
    showActionButton || searchBoxVisible ? '4px' : null};

  @media (min-width: 768px) {
    padding-top: unset;
    grid-template-rows: 54px;
    grid-template-columns: ${props => {
      let columns = `1fr `
      const { searchBoxVisible, showActionButton, showSecondaryItems } = props
      if (showSecondaryItems) {
        if (showActionButton) {
          columns += `auto `
        }
        if (searchBoxVisible) {
          columns += `auto `
        }
      }
      return columns
    }};

    grid-template-areas: ${props => {
      let areas = ``
      const { showActionButton, searchBoxVisible, showSecondaryItems } = props
      if (showSecondaryItems) {
        areas += `'secondaryLinks`
        if (showActionButton) {
          areas += ` headerActionButton`
        }
        if (searchBoxVisible) {
          areas += ` search`
        }
      }
      areas += `'`
      return areas
    }};
  }
`

const SecondaryLinks = styled(TabBarSwitcher)`
  grid-area: secondaryLinks;
`
const Search = styled(BaseSearch)`
  grid-area: search;
`

const HeaderActionButton = styled(PrimaryButton)`
  label: headerActionButton;
  grid-area: headerActionButton;
`
const UserMenu = styled(HeaderUser)`
  label: headerUser;
  grid-area: headerUser;
`

export const BaseHeader = ({
  can,
  tertiaryLinks,
  location: { pathname },
  searchBoxVisible,
  crumbs,
  breadcrumbsLoaded,
  pageLoaderOn,
  showActionButton,
  matchParams,
  matchParamIdRef,
  tMenuRef,
  actionButtonOnClick,
  actionButtonText,
}) => {
  const secondaryLinks =
    tMenuRef && tertiaryLinks
      ? tertiaryMenuBuilder(
          tertiaryLinks[tMenuRef],
          matchParams && matchParamIdRef ? matchParams[matchParamIdRef] : null,
          can
        )
      : null
  const showSecondaryItems =
    !pageLoaderOn.loaderOn && !pageLoaderOn.error && breadcrumbsLoaded
  return (
    <AllHeaderContainer>
      <HeaderContainer>
        <SecondaryHeader showSecondaryItems={showSecondaryItems}>
          <Hamburger />
          <BreadCrumbs
            crumbs={crumbs}
            breadcrumbsLoaded={breadcrumbsLoaded}
            pageLoaderOn={pageLoaderOn}
          />

          <UserMenu />
        </SecondaryHeader>
      </HeaderContainer>

      <HeaderContainer>
        <Container>
          <TertiaryRow
            searchBoxVisible={searchBoxVisible}
            showActionButton={showActionButton}
            showSecondaryItems={showSecondaryItems}
          >
            {secondaryLinks && showSecondaryItems && (
              <SecondaryLinks navLinks={secondaryLinks} pathname={pathname} />
            )}
            {showActionButton && showSecondaryItems && (
              <HeaderActionButton onClick={actionButtonOnClick}>
                {actionButtonText}
              </HeaderActionButton>
            )}
            <Search searchBoxVisible={searchBoxVisible && showSecondaryItems} />
          </TertiaryRow>
        </Container>
      </HeaderContainer>
    </AllHeaderContainer>
  )
}

BaseHeader.propTypes = {
  tertiaryLinks: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  searchBoxVisible: PropTypes.bool,
  crumbs: PropTypes.arrayOf(PropTypes.object),
  breadcrumbsLoaded: PropTypes.bool,
  pageLoaderOn: PropTypes.objectOf(PropTypes.bool),
  actionButtonOnClick: PropTypes.func.isRequired,
  showActionButton: PropTypes.bool,
  actionButtonText: PropTypes.node,
  matchParams: PropTypes.objectOf(PropTypes.node),
  matchParamIdRef: PropTypes.string,
  tMenuRef: PropTypes.string,
  can: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  location: PropTypes.objectOf(PropTypes.any),
}

BaseHeader.defaultProps = {
  location: {},
  searchBoxVisible: false,
  crumbs: [],
  breadcrumbsLoaded: false,
  pageLoaderOn: { loaderOn: true },
  showActionButton: false,
  actionButtonText: null,
  matchParams: null,
  matchParamIdRef: null,
  tMenuRef: null,
  tertiaryLinks: null,
  can: {},
}
const mapStateToProps = state => ({
  can: getPermissionsState(state),
  tertiaryLinks: getTertiaryLinksState(state),
  searchBoxVisible: getSearchBoxVisibleState(state),
  crumbs: getBreadcrumbsState(state),
  breadcrumbsLoaded: getBreadcrumbLoadedState(state),
  pageLoaderOn: getPageLoaderState(state),
  showActionButton: getShowActionButtonState(state),
  actionButtonText: getActionButtonTextState(state),
  matchParams: getMatchParamsState(state),
  matchParamIdRef: getMatchParamsIdRefState(state),
  tMenuRef: getTMenuRefState(state),
})

const mapDispatchToProps = (
  dispatch,
  { actionButtonModalRef, actionButtonFunc }
) => ({
  actionButtonOnClick: () =>
    dispatch(actionButtonClickApi(actionButtonModalRef, actionButtonFunc)),
})

const Header = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(React.memo(BaseHeader))
)

export default Header
