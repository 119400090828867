import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Link from '../../../../components/Link/Link'

const InnerCrumb = styled('div')`
  label: crumb;
  pointer-events: ${props => (props.lastCrumb ? 'none' : null)};
  user-select: none;
  color: ${props =>
    props.lastCrumb ? props.theme.primary : props.theme.navLink};
  max-width: ${props => (props.lastCrumb ? null : '120px')};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 0;
  text-transform: capitalize;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 0.8125rem;
  min-width: 2.375rem;
  cursor: pointer;
  line-height: 1.84615385;
  border-radius: 3px;
  position: relative;
  float: left;
  margin-left: -1px;
  display: block;
  z-index: ${props => 100 - props.zIndex};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  :hover {
    color: ${props => (props.lastCrumb ? null : props.theme.hover.primary)};
  }
  :focus {
    outline: none;
  }

  @media (min-width: 1200px) {
    max-width: ${props => (props.lastCrumb ? 'none' : '120px')};
  }
  @media (min-width: 1600px) {
    max-width: none;
  }
`
const BreadcrumbChevron = styled('div')`
  /* width: 50px; */
  float: left;
  padding-left: ${props => (props.left ? '16px' : null)};
  :before,
  :after {
    border-style: solid;
    border-width: 0.1rem 0.1rem 0 0;
    display: inline-block;
    height: 0.5rem;
    bottom: 0%;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    width: 0.5rem;
    border-color: rgba(0, 0, 0, 0.3);
    vertical-align: bottom;
  }
  :before {
    ${props => (!props.lastCrumb && props.left ? `content: ' '` : null)};
    transform: rotate(-135deg);
    right: 100%;
  }
  :after {
    ${props => (!props.lastCrumb && !props.left ? `content: ' '` : null)};
    transform: rotate(45deg);
  }
`

const Crumb = ({
  children,
  lastCrumb,
  zIndex,
  to,
  className,
  left,
  ...other
}) => (
  <Link to={to}>
    <BreadcrumbChevron lastCrumb={lastCrumb} left={left}>
      <InnerCrumb
        lastCrumb={lastCrumb}
        zIndex={zIndex}
        className={className}
        {...other}
      >
        {children}
      </InnerCrumb>
    </BreadcrumbChevron>
  </Link>
)

Crumb.propTypes = {
  lastCrumb: PropTypes.bool,
  zIndex: PropTypes.number,
  children: PropTypes.node,
  to: PropTypes.string,
  className: PropTypes.string,
  left: PropTypes.bool,
}

Crumb.defaultProps = {
  lastCrumb: false,
  zIndex: null,
  children: null,
  to: null,
  className: null,
  left: false,
}
export default Crumb
