import Debug from 'debug'
import { getSensorByIdState } from '../../redux/reducer'
import api from '../../api'
import { errorHandler } from '../errorState/errorState'

const debug = Debug('web:sensorState:')

// --- Actions ---

const UPDATE_SENSOR_BY_ID = 'UPDATE_SENSOR_BY_ID'
const UPDATE_SENSOR_REQUEST_IN_PROGRESS = 'UPDATE_SENSOR_REQUEST_IN_PROGRESS'

// --- Action Creators ---

export const updateSensorById = (id, data) => ({
  type: UPDATE_SENSOR_BY_ID,
  payload: { ...data },
  id,
  reference: 'sensorById',
})

export const updateSensorRequestInProgress = loading => ({
  type: UPDATE_SENSOR_REQUEST_IN_PROGRESS,
  payload: { loading },
})

// --- get sensor information ---

const getSensorRequest = id => api(`sensors/${id}`)

export const getSensorApi = (id, modalRef) => (dispatch, getState) => {
  const inStore = getSensorByIdState(getState())[id]
  return Promise.resolve()
    .then(() =>
      inStore ? null : dispatch(updateSensorRequestInProgress(true))
    )
    .then(() => getSensorRequest(id))
    .then(response => dispatch(updateSensorById(id, response)))
    .then(() => dispatch(updateSensorRequestInProgress(false)))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue downloading this sensor's information`
        )
      )
      return inStore ? null : dispatch(updateSensorRequestInProgress(false))
    })
}

// --- redux ---

const initialState = {
  sensorById: {},
  loading: true,
}

const sensorReducer = (state = initialState, action) => {
  const { type, payload, reference, id } = action
  switch (type) {
    case UPDATE_SENSOR_REQUEST_IN_PROGRESS:
      return { ...state, ...payload }
    case UPDATE_SENSOR_BY_ID:
      return {
        ...state,
        [reference]: {
          ...state[reference],
          [id]: { ...state[reference][id], ...payload },
        },
      }
    default:
      return state
  }
}

export const getSensorById = state => state.sensorById
export const getSensorLoadingStatus = state => state.loading

export default sensorReducer
