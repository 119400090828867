import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormikInput, FieldContainer, Error } from '../FormikField/FormikField'
import FormTitle from '../FormTitle/FormTitle'

const InputIconContainer = styled('div')`
  position: relative;
`
const Input = styled('input')`
  display: block;
  width: 100%;
  padding: ${props => {
    const { icon, iconend } = props
    if (typeof icon === 'string') {
      if (iconend === 'true') {
        return '0.375rem 2.5rem 0.375rem 0.75rem'
      }
      return '0.375rem 0.75rem 0.375rem 2.5rem'
    }
    return '0.375rem 0.75rem'
  }};
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`

export const IconForInput = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ iconEnd }) => (iconEnd ? null : 0)};
  right: ${({ iconEnd }) => (iconEnd ? 0 : null)};
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 2.5rem;
  cursor: pointer;
  z-index: 5;
  text-align: center;
  pointer-events: ${({ onClick }) => (onClick ? null : 'none')};
  :hover {
    color: ${({ onClick }) => (onClick ? 'rgba(0, 0, 0, 0.45)' : null)};
  }
`

const InputIcon = ({
  type,
  icon,
  iconEnd,
  containerClassName,
  inputReactRef,
  formikInput,
  name,
  title,
  iconClick,
  isRequired,
  ...otherProps
}) => (
  <FieldContainer name={name}>
    <FormTitle isRequired={isRequired} htmlFor={name}>
      {title}
    </FormTitle>
    <InputIconContainer className={containerClassName}>
      {iconEnd === 'false' && (
        <IconForInput onClick={iconClick}>
          <i className={icon} data-cy="inputIcon" />
        </IconForInput>
      )}
      {formikInput ? (
        <FormikInput
          type={type}
          icon={icon}
          iconend={iconEnd}
          name={name}
          id={name}
          {...otherProps}
        />
      ) : (
        <Input
          type={type}
          icon={icon}
          iconend={iconEnd}
          id={name}
          ref={inputReactRef}
          {...otherProps}
        />
      )}
      {iconEnd === 'true' && (
        <IconForInput iconEnd onClick={iconClick}>
          <i className={icon} data-cy="inputIcon" />
        </IconForInput>
      )}
    </InputIconContainer>
    <Error name={name} component="div" />
  </FieldContainer>
)

InputIcon.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  date: PropTypes.objectOf(PropTypes.any),
  containerClassName: PropTypes.string,
  inputReactRef: PropTypes.func,
  formikInput: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  iconEnd: PropTypes.string,
  iconClick: PropTypes.func,
  isRequired: PropTypes.bool,
}

InputIcon.defaultProps = {
  type: 'text',
  icon: null,
  date: null,
  containerClassName: null,
  inputReactRef: null,
  formikInput: false,
  name: null,
  title: null,
  iconEnd: 'false',
  iconClick: null,
  isRequired: null,
}

export default InputIcon
