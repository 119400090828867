import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Link from '../../../../components/Link/Link'
import DropDownItem from '../../../../components/DropDownItem/DropDownItem'
import DropDownIcon from '../../../../components/DropDownIcon/DropDownIcon'
import Hr from '../../../../components/Hr/Hr'
import { logoutApi } from '../../../../reducers/loginState/loginState'

const HeaderDropDownMenu = ({ logout }) => (
  <div>
    <Link to="/account">
      <DropDownItem>
        <DropDownIcon className="far fa-user" />
        My Account
      </DropDownItem>
    </Link>
    <Hr />
    <DropDownItem
      onClick={logout}
      onKeyDown={e => (e.keyCode !== 9 ? logout : null)}
      role="button"
      tabIndex={0}
    >
      <DropDownIcon className="far fa-sign-out" />
      Sign Out
    </DropDownItem>
  </div>
)

HeaderDropDownMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}
const mapDispatchToProps = (dispatch, { history: { push } }) => ({
  logout: () => dispatch(logoutApi(push)),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(HeaderDropDownMenu)
)
