import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { getLoginStatusState } from '../../redux/reducer'
import { LOGGED_OUT, AUTHENTICATED } from '../../reducers/loginState/loginState'
import { PageLoader } from '../../components/Loader/Loader'

const AuthRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (authenticated === AUTHENTICATED) {
        return <Component {...props} />
      }
      if (authenticated === LOGGED_OUT) {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }

      return <PageLoader />
    }}
  />
)

AuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
  authenticated: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
}

AuthRoute.defaultProps = {
  authenticated: 'authenticating',
  location: '/',
}

const mapStateToProps = state => ({
  authenticated: getLoginStatusState(state),
})

export default connect(mapStateToProps)(AuthRoute)
