import React from 'react'
import styled from '@emotion/styled'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import CloseButton from '../../components/CloseButton/CloseButton'

/**
 * Styles the close button component provided to the toaster
 */
const SmallCloseButton = styled(CloseButton)`
  font-size: 1rem;
  padding: 20px;
`

/**
 * Defines style for different types
 * Can create your own types by adding a name after the --
 * All Toastify does is add that type value to the end
 * These styles are all for the progress bar
 */
const StyledToastContainer = styled(ToastContainer)`
  .Toastify__progress-bar--default {
    background: ${props => props.theme.primary};
  }
  .Toastify__progress-bar--success {
    background: ${props => props.theme.success};
  }
  .Toastify__progress-bar--error {
    background: ${props => props.theme.danger};
  }
  .Toastify__progress-bar--warning {
    background: ${props => props.theme.hover.warning};
  }
  .Toastify__progress-bar--info {
    background: ${props => props.theme.primaryLight};
  }
  .Toastify__toast {
    border-radius: 3px;
    background: #fff;
    color: #495057;
    min-height: 50px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
    font-size: 0.875rem;
  }
  .Toastify__progress-bar {
    height: 3px;
  }
`

/**
 * Provides a default set of props and styles for ToasterContainer
 * @param {{}} props
 */
const Toasters = ({ ...otherProps }) => (
  <StyledToastContainer
    autoClose={7000}
    newestOnTop
    pauseOnHover
    closeButton={<SmallCloseButton />}
    data-cy="toasterContainer"
    {...otherProps}
  />
)

Toasters.propTypes = {}

export default Toasters
