import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import SideMenuContainer from './SideMenuContainer/SideMenuContainer'
import SideMenuLogoContainer from './SideMenuLogoContainer/SideMenuLogoContainer'
import SideMenuSection from './SideMenuSection/SideMenuSection'
import SideMenuSectionTitle from './SideMenuSectionTitle/SideMenuSectionTitle'
import SideMenuNavLink from './SideMenuNavLink/SideMenuNavLink'
import {
  getPrimaryLinksState,
  getAdminLinksState,
  getSiteLogoState,
} from '../../redux/reducer'
import SideMenuNavLinks from './SideMenuNavLinks/SideMenuNavLinks'
import SideMenuMask from './SideMenuMask/SideMenuMask'
import CopyrightNotice from '../../components/CopyrightNotice/CopyrightNotice'
import remoteTechLogo from './remoteTechWhite.svg'
import Img from '../../components/Img/Img'

const Hr = styled('hr')`
  margin: 0.75rem 0 1.5rem;
  border-color: #2e2e33;
`

const Copyright = styled(CopyrightNotice)`
  font-size: 0.875rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  opacity: 0.55;
`

const CopyrightNav = styled(SideMenuNavLink)`
  cursor: default;
  font-size: 0.875rem;
  text-align: center;
  :hover {
    background-color: transparent;
  }
`

const RemoteTechLogo = styled(Img)`
  text-align: center;
  width: 100%;
  opacity: 0.55;
`

const SideMenu = ({
  openMenu,
  primaryLinks,
  // accountLinks,
  adminLinks,
  // signOut,
  siteLogo,
  location: { pathname },
}) => (
  <div>
    <SideMenuContainer openMenu={openMenu} logoTop>
      <SideMenuLogoContainer siteLogo={siteLogo} />
      <div>
        <SideMenuSection>
          <SideMenuSectionTitle>My L8log</SideMenuSectionTitle>
          <SideMenuNavLinks links={primaryLinks} pathname={pathname} />
        </SideMenuSection>

        {/* <SideMenuSection>
        <SideMenuSectionTitle>My Organisation</SideMenuSectionTitle>

        <SideMenuNavLink icon>
          <Icon className="far fa-address-book" />
          All Users
        </SideMenuNavLink>
      </SideMenuSection> */}

        {adminLinks.length > 0 && (
          <SideMenuSection>
            <SideMenuSectionTitle>Admin</SideMenuSectionTitle>
            <SideMenuNavLinks links={adminLinks} pathname={pathname} />
          </SideMenuSection>
        )}
      </div>

      <div>
        <SideMenuSection>
          <SideMenuSectionTitle>Support</SideMenuSectionTitle>
          <SideMenuNavLink to="/support" style={{ textTransform: 'none' }}>
            Ask a question
          </SideMenuNavLink>
        </SideMenuSection>

        <Hr />

        <CopyrightNav>
          <RemoteTechLogo src={remoteTechLogo} alt="Remote Tech Logo" />
        </CopyrightNav>
        <CopyrightNav>
          <Copyright />
        </CopyrightNav>
      </div>
    </SideMenuContainer>
    <SideMenuMask openMenu={openMenu} />
  </div>
)

SideMenu.propTypes = {
  openMenu: PropTypes.bool,
  primaryLinks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  adminLinks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  siteLogo: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.node),
}
SideMenu.defaultProps = {
  openMenu: true,
  primaryLinks: [],
  adminLinks: [],
  siteLogo: null,
  location: {},
}

const mapStateToProps = state => ({
  primaryLinks: getPrimaryLinksState(state),
  adminLinks: getAdminLinksState(state),
  siteLogo: getSiteLogoState(state),
})

export default withRouter(connect(mapStateToProps)(SideMenu))
