import Debug from 'debug'
import { toast } from 'react-toastify'
import api from '../../api'
import {
  getUserState,
  getUserRequestStatusState,
  getUsersByRoleState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { toggleModal } from '../modalState/modalState'
import { errorHandler } from '../errorState/errorState'
import { addParentIdToUser } from './addParentToUser/addParentToUser'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import {
  calculatePagination,
  nextPageForLists,
} from '../paginationState/paginationState'

const debug = Debug('web:UserState:')

// --- Constants ---
const API_LIMIT = 20

// --- Actions ---
const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID'
const USER_REQUEST_STATUS = 'USER_REQUEST_STATUS'
const UPDATE_USERS_BY_ROLE = 'UPDATE_USERS_BY_ROLE'
const NEXT_USER_PAGE = 'NEXT_USER_PAGE'

// --- Action Creators ---

export const updateUserById = (id, user) => ({
  type: UPDATE_USER_BY_ID,
  payload: { [id]: user },
  reference: 'user',
})

export const userRequestStatus = (id, status) => ({
  type: USER_REQUEST_STATUS,
  payload: { [id]: status },
  reference: 'status',
})

const updateUsersByRole = (role, id, users) => ({
  type: UPDATE_USERS_BY_ROLE,
  payload: { [id]: users },
  reference: role,
})

const nextUserRole = (apiRole, users, id) => ({
  type: NEXT_USER_PAGE,
  payload: users,
  reference: { apiRole, id },
})

// --- Invite ---

const postUserInvite = user =>
  api('tokens/invite-user', { method: 'post', data: user })

export const inviteNewUser = (
  user,
  setSubmitting,
  modalRef,
  id
) => dispatch => {
  const { first_name, last_name, role, email } = user
  return Promise.resolve()
    .then(() => postUserInvite(addParentIdToUser(user, id)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() =>
      toast(
        `An email invitation has been sent to ${email} inviting ${first_name} ${last_name} to join L8log in the role of ${role}.`,
        { type: toast.TYPE.SUCCESS }
      )
    )
    .catch(err => {
      debug(err)
      debug(user)
      setSubmitting(false)
      const errMsg =
        err.errors.email === `"email" belongs to a registered user`
          ? `${user.email} has already been registered.`
          : `There was an issue sending the invite to ${user.email}.`
      return dispatch(errorHandler(err, modalRef, errMsg))
    })
}

// --- Create ---

/**
 * posts a new user
 * @param {{}} user
 */
const postNewUser = user => api('users', { method: 'post', data: user })

/**
 * creates a new user for the specified role
 * creates an association to the org structure
 * adds the user to the role's array in redux
 * @param {{}} user
 * @param {function} setSubmitting
 * @param {string} modalRef
 * @param {string|number} id
 * @param {string} userRole
 */
export const addNewUser = (user, setSubmitting, modalRef, id, userRole) => (
  dispatch,
  getState
) =>
  Promise.resolve()
    .then(() => postNewUser(addParentIdToUser(user, id)))
    .then(response => {
      debug(response)
      const currentUsers = getUsersByRoleState(getState())[userRole][id]
      return [...currentUsers, response]
    })
    .then(users => {
      dispatch(updateUsersByRole(userRole, id, users))
    })
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() =>
      successToaster('added', `${user.first_name} ${user.last_name} to L8log`)
    )
    .catch(err => {
      const { role } = user
      let msg = `There was an issue adding the new ${role.replace('-', ' ')}.`
      const { data } = err.response || {}
      const { errors } = data || {}
      const { email } = errors || {}
      if (email === 'must be unique') {
        msg = `The email must be unique, this email address has been used before for another user.`
      }
      setSubmitting(false)
      dispatch(errorHandler(err, modalRef, msg))
    })
// --- Read ---

/**
 * get a user with a given params
 * @param {{}} params
 */
export const getUserRequest = ({ params = { $limit: API_LIMIT }, userId }) =>
  api(userId ? `users/${userId}` : 'users', { params })

export const checkUserInStore = (id, getState) => {
  const userInStore = getUserState(getState())[id] || false
  const userRequestStatusState = getUserRequestStatusState(getState())[id]
  return !userInStore && userRequestStatusState !== 'requesting'
}

export const getUserCatchHandler = (dispatch, err, id) => {
  debug(err)
  dispatch(errorHandler(err, 'users')) // error ref to stop a 404
  dispatch(userRequestStatus(id, 'error'))
}

// -- get next user page api --

export const getNextUserPageApi = (
  parentId,
  params,
  paginationRef,
  apiRole
) => dispatch =>
  dispatch(
    nextPageForLists(
      parentId,
      params,
      paginationRef,
      getUserRequest,
      (users, id) => dispatch2 => dispatch2(nextUserRole(apiRole, users, id))
    )
  )

/**
 * get a single user by Id
 * @param {number} id
 */
export const getUserApi = id => (dispatch, getState) => {
  if (checkUserInStore(id, getState)) {
    dispatch(userRequestStatus(id, 'requesting'))

    const params = {
      $select: [
        'first_name',
        'last_name',
        'role',
        'id',
        'profile_photo',
        'phone_number',
        'email',
      ],
    }

    return getUserRequest({ params, userId: id })
      .then(response => dispatch(updateUserById(id, response)))
      .then(() => dispatch(userRequestStatus(id, 'successful')))
      .catch(err => {
        getUserCatchHandler(dispatch, err, id)
      })
  }
  return null
}

export const getUsersByRoleAndHeirarchy = (
  pathname,
  userRole,
  apiRole,
  id,
  idType
) => (dispatch, getState) => {
  const usersInStore = getUsersByRoleState(getState())[userRole][id]
  return Promise.resolve()
    .then(() => (usersInStore ? null : dispatch(toggleLoader(true))))
    .then(() => {
      let params = { $limit: API_LIMIT }
      if (apiRole) {
        params = { ...params, role: apiRole }
      }

      // topLevel is for admin and technician as they have no ID associated with them
      if (id !== 'topLevel') {
        params = { ...params, [idType]: id }
      }
      return getUserRequest({ params })
    })
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination(pagination, pathname))
      return dispatch(updateUsersByRole(userRole, id, data))
    })
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      return usersInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- redux ---

const initialState = {
  status: {},
  user: {},
  byRole: {
    manager: {},
    siteManager: {},
    client: {},
    admin: {},
    technician: {},
    engineer: {},
    allUser: {},
  },
}

const usersReducer = (state = initialState, action) => {
  const { type, payload, reference = null } = action
  switch (type) {
    case UPDATE_USER_BY_ID:
    case USER_REQUEST_STATUS:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case UPDATE_USERS_BY_ROLE:
      return {
        ...state,
        byRole: {
          ...state.byRole,
          [reference]: { ...state.byRole[reference], ...payload },
        },
      }
    case NEXT_USER_PAGE: {
      const { apiRole, id } = reference || {}
      const currentRole = state.byRole[apiRole] || {}
      const current = currentRole[id] || []
      return {
        ...state,
        byRole: {
          ...state.byRole,
          [apiRole]: {
            ...currentRole,
            [id]: [...current, ...payload],
          },
        },
      }
    }
    default:
      return state
  }
}

export const getUser = state => state.user
export const getUserRequestStatus = state => state.status
export const getUsersByRole = state => state.byRole

export default usersReducer
