// --- Actions ---

const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU'

// --- Action creators ---

export const toggleSideMenu = menuOpen => ({
  type: TOGGLE_SIDE_MENU,
  payload: { menuOpen },
})

// --- Redux ---

const initialState = {
  menuOpen: false,
}

const SideMenu = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_SIDE_MENU:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getSideMenuOpen = state => state.menuOpen

export default SideMenu
