import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Link from '../../../../components/Link/Link'
import { breakpoints } from '../../../../components/Responsive/Responsive'
import NameWithCount from '../../../../components/NameWithCount/NameWithCount'

const Li = styled('li')`
  justify-self: stretch;
  display: grid;
  @media (min-width: ${breakpoints.desktop}px) {
    display: block;
    margin: 16px 0;
  }
`

const NavLink = styled(Link)`
  label: NavBarLink;
  width: 100%;
  justify-self: stretch;
  color: ${({ to, theme, pathname }) =>
    to === pathname ? theme.primary : theme.navLink};
  border-left: 3px solid
    ${({ to, theme, pathname }) =>
      to === pathname ? theme.primary : 'transparent'};
  transition: color 0.3s;
  margin-bottom: -1px;
  user-select: none;
  cursor: pointer;
  min-width: 2rem;
  padding: 1rem 0.75rem;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  min-height: 54px;

  :hover {
    text-decoration: none;
    color: #6e7687;
    margin-left: 0;
    border-left: 3px solid ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.hover.table};
  }
  @media (min-width: ${breakpoints.desktop}px) {
    margin-left: 0;
    border-left: 0;
    border-bottom: 2px solid
      ${({ to, theme, pathname }) =>
        to === pathname ? theme.primary : 'transparent'};
    :hover {
      border-bottom: 2px solid ${({ theme }) => theme.primary};
      background-color: transparent;
      border-left: 0;
    }
  }
`

const NavigationItem = ({ link, count, name, pathname, bottomborder }) => (
  <Li>
    <NavLink to={link} bottomborder={bottomborder} pathname={pathname}>
      <b>
        <NameWithCount name={name} count={count} />
      </b>
    </NavLink>
  </Li>
)

NavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.node,
  pathname: PropTypes.string.isRequired,
  bottomborder: PropTypes.string,
}

NavigationItem.defaultProps = {
  bottomborder: null,
  count: null,
}

export default React.memo(NavigationItem)
