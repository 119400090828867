import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { toggleSideMenu } from '../../../reducers/sideMenuState/sideMenuState'
import { SIDE_MENU_BREAK_POINT } from '../SideMenuContainer/SideMenuContainer'

const HamburgerContainer = styled('div')`
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  align-self: center;
  justify-self: center;
  display: grid;
  align-items: center;
  :hover {
    .burger,
    .burger::after,
    .burger::before {
      background-color: ${props => props.theme.hover.primary};
    }
  }
`

const HamburgerBox = styled('div')`
  label: HamburgerBox;
  grid-area: hamburger;
  width: 30px;
  height: 42px;
  display: inline-block;
  position: relative;
  @media (min-width: ${SIDE_MENU_BREAK_POINT}) {
    display: none;
  }
`

const Burger = styled('div')`
  label: Burger;
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 30px;
  height: 3px;
  background-color: ${props => props.theme.primary};
  border-radius: 3px;
  position: absolute;
  :before,
  :after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${props => props.theme.primary};
    border-radius: 3px;
    position: absolute;
  }
  :before {
    top: -8px;
  }
  :after {
    bottom: -8px;
  }
`

const Hamburger = ({ className, onClick }) => (
  <HamburgerContainer onClick={onClick} className={className}>
    <HamburgerBox>
      <Burger className="burger" />
    </HamburgerBox>
  </HamburgerContainer>
)

Hamburger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

Hamburger.defaultProps = {
  className: null,
}

const mapDispatchToProps = (dispatch, { menuOpen }) => ({
  onClick: () => dispatch(toggleSideMenu(!menuOpen)),
})

export default connect(
  null,
  mapDispatchToProps
)(React.memo(Hamburger))
