import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import NavigationItems from '../NavigationItems/NavigationItems'
import { breakpoints } from '../../../components/Responsive/Responsive'

const NavGroup = styled('ul')`
  label: NavGroup;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: row;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
  @media (min-width: ${breakpoints.desktop}px) {
    display: flex;
    flex-direction: row;
    margin-left: -0.75rem;
    padding-left: 0.75rem;
  }
`
const NavLinks = ({ navLinks, pathname, bottomborder, className }) => (
  <NavGroup className={className}>
    <NavigationItems
      items={navLinks}
      pathname={pathname}
      bottomborder={bottomborder}
    />
  </NavGroup>
)

NavLinks.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.object),
  pathname: PropTypes.string,
  bottomborder: PropTypes.string,
  className: PropTypes.string,
}
NavLinks.defaultProps = {
  navLinks: [],
  pathname: null,
  bottomborder: null,
  className: null,
}

export default NavLinks
