import Debug from 'debug'
import { configureScope } from '@sentry/browser'
import api from '../../api'
import { loadPermissions } from '../permissionsState/permissionsState'
import { navigationSuccess } from '../navigationState/navigationState'
import { getWhitelabelApi } from '../whitelabelState/whitelabelState'
import { getTempLoginDetailsState } from '../../redux/reducer'
import { errorHandler } from '../errorState/errorState'
import { updateSideBarJobCounts } from '../jobsPageState/jobsPageState'

const debug = Debug('web:loginState:')

// --- Actions ---

const LOGIN_STATUS = 'LOGIN_STATUS'
export const LOGOUT = 'LOGOUT'
const UPDATE_LOGIN_NOTICE = 'UPDATE_LOGIN_NOTICE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
// const LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN'
const UPDATE_TEMP_STORE_LOGIN_DETAILS = 'UPDATE_TEMP_STORE_LOGIN_DETAILS'
const UPDATE_LOGIN_EMAIL_DISABLED = 'UPDATE_LOGIN_EMAIL_DISABLED'

// --- LoginStatus Values ---

export const AUTHENTICATING = 'authenticating'
export const LOGGED_OUT = 'loggedOut'
export const AUTHENTICATED = 'authenticated'
export const TWO_FACTOR_AUTH = 'twoFactor'

// --- Action Creators ---

/**
 * defines the authentication status of the app
 * @param {string} status
 */
export const loginStatus = status => ({
  type: LOGIN_STATUS,
  payload: { status },
})

export const logout = () => ({
  type: LOGOUT,
})

export const updateLoginNotice = loginNotice => ({
  type: UPDATE_LOGIN_NOTICE,
  payload: { loginNotice },
})

/**
 * This sends the user to the auth reducer
 */
export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
  payload: { user, ...user },
})

// const loginWithToken = () => ({
//   type: LOGIN_WITH_TOKEN,
// })

const updateTempStoreLoginDetails = (loginDetails = null) => ({
  type: UPDATE_TEMP_STORE_LOGIN_DETAILS,
  payload: { loginDetails },
})

export const updateLoginEmailDisabled = loginEmailDisabled => ({
  type: UPDATE_LOGIN_EMAIL_DISABLED,
  payload: { loginEmailDisabled },
})

const authentication = data =>
  api('/authentication', {
    method: 'post',
    data,
  })

// --- Successful Login ---

const successfulLogin = response => dispatch => {
  const { user, accessToken } = response
  const { can, ...userInfo } = user
  const { role, id, associated_org } = userInfo || {}
  localStorage.jwt = accessToken
  return Promise.all([
    dispatch(loginSuccess({ ...userInfo })),
    dispatch(loadPermissions(user.can)),
    dispatch(navigationSuccess()),
    dispatch(getWhitelabelApi()),
    dispatch(updateLoginEmailDisabled(false)),
    process.env.NODE_ENV !== 'development'
      ? configureScope(scope => {
          scope.setUser({
            id,
            associated_org,
            role,
          })
        })
      : true,
  ])
}

// --- After login ---

const performAfterLogin = () => dispatch =>
  Promise.all([dispatch(updateSideBarJobCounts())])

// --- Initial Login ---

/**
 * JWT only login, used if there is a JWT when the app loads
 */
const jwtAuthenticateRequest = token => authentication({ accessToken: token })

/**
 * checks if there is a JWT
 * If there is then it authenticates
 * If either JWT check or auth fails set loginStatus as logged out
 * This will cause auth pages to redirect to '/login'
 */
export const initialLoginApi = () => dispatch => {
  const token = localStorage.jwt
  const tokenPresent = typeof token === 'string'
  if (!tokenPresent) {
    return dispatch(loginStatus(LOGGED_OUT))
  }
  return Promise.resolve()
    .then(() => jwtAuthenticateRequest(token))
    .then(response => dispatch(successfulLogin(response)))
    .then(() => dispatch(loginStatus(AUTHENTICATED)))
    .then(() => dispatch(performAfterLogin()))
    .catch(err => {
      debug(err)
      return dispatch(loginStatus(LOGGED_OUT))
    })
}

// --- Normal Login ---

const loginRequest = ({ email, password }) =>
  authentication({ email, password })

export const loginApi = (values, setSubmitting, reference) => dispatch => {
  loginRequest(values)
    .then(response => {
      if (response.token_required) {
        dispatch(updateTempStoreLoginDetails(values))
        const thrownObject = 'totp'
        throw thrownObject
      }
      return dispatch(successfulLogin(response))
    })
    .then(() => dispatch(loginStatus(AUTHENTICATED)))
    .then(() => dispatch(performAfterLogin()))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      if (err === 'totp') {
        return dispatch(loginStatus(TWO_FACTOR_AUTH))
      }
      const { message } = err
      let errMsg = 'There was an issue in authenticating your login details.'
      if (message === 'Invalid login') {
        errMsg = 'Invalid login details, your email or password are incorrect.'
      }
      return dispatch(errorHandler(err, reference, errMsg))
    })
}

// --- Login with 2 factor auth ---

const twoFactorAuthLoginRequest = requestBody =>
  authentication({ ...requestBody })

export const loginWithTwoFactorAuth = ({ token }, reference, resetForm) => (
  dispatch,
  getState
) => {
  const tempLoginDetails = getTempLoginDetailsState(getState())
  const { email, password } = tempLoginDetails
  return Promise.resolve()
    .then(() => twoFactorAuthLoginRequest({ email, password, token }))
    .then(response => dispatch(successfulLogin(response)))
    .then(() => {
      dispatch(updateTempStoreLoginDetails())
      return dispatch(loginStatus(AUTHENTICATED))
    })
    .then(() => dispatch(performAfterLogin()))
    .catch(err => {
      debug(err)
      resetForm()
      return dispatch(
        errorHandler(
          err,
          reference,
          'There was an issue logging in, please try again'
        )
      )
    })
}

// --- Log out ---

/**
 * logs the user out of the app, resets all reducers
 * then pushes '/login'
 */
export const logoutApi = push => dispatch =>
  Promise.resolve()
    .then(() => dispatch(logout()))
    .then(() => push('/login'))
    .then(() =>
      process.env.NODE_ENV !== 'development'
        ? configureScope(scope => scope.setUser())
        : null
    )
    .catch(err => debug(err))

// --- Redux ---

const initialState = {
  status: 'authenticating',
  loginNotice: null,
}

const LoginState = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOGIN_STATUS:
    case UPDATE_LOGIN_NOTICE:
    case UPDATE_TEMP_STORE_LOGIN_DETAILS:
    case UPDATE_LOGIN_EMAIL_DISABLED:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getLoginStatus = state => state.status
export const getLoginNotice = state => state.loginNotice
export const getTempLoginDetails = state => state.loginDetails
export const getLoginEmailDisabled = state => state.loginEmailDisabled

export default LoginState
