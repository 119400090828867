import Debug from 'debug'
import {
  postJobRequest,
  riskPrefix,
  addSubJob,
  patchJobMetadata,
} from '../l8LogbookState/l8LogbookState'
import sectionTypeLookup from '../l8LogbookState/sectionTypeLookup/sectionTypeLookup'
import { toggleModal } from '../modalState/modalState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { errorHandler } from '../errorState/errorState'
import api from '../../api'
import {
  getLogbookEntityReferencesState,
  getJobsState,
  getAssessmentState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { getSingleSiteApi } from '../sitesState/sitesState'
import { getAssessmentsByJobIdApi } from '../assessmentsState/assessmentsState'

const debug = Debug('web:l8LogbookSubJobsState:')

// --- Actions ---

const UPDATE_JOB = 'UPDATE_JOB'
const UPDATE_JOB_METADATA = 'UPDATE_JOB_METADATA'

// --- Action Creators ---

export const updateJob = (job, id) => ({
  type: UPDATE_JOB,
  payload: { [id]: job },
})

export const updateJobMetadata = (meta, id) => ({
  type: UPDATE_JOB_METADATA,
  payload: { ...meta },
  reference: id,
})

// --- Add New Sub Job ---

// todo: update counts once work_start and work_end plus assigned meta is added to create job
export const AddNewSubJob = (
  { name, description },
  setSubmitting,
  modalRef,
  { jobId, section, year, timeFrame, timeName, siteId, parent_id }
) => dispatch =>
  postJobRequest(siteId, {
    type: sectionTypeLookup(section),
    name,
    metadata: {
      description,
      section,
      year,
      site_id: siteId,
      timeFrame,
      risk: 1,
      parent_id,
      parent: false,
      timeName: `${timeName || year}` /* timeName must be input as a string */,
    },
  })
    .then(response => {
      dispatch(updateJob(response, response._id))
      dispatch(addSubJob([response._id], jobId))
    })
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created job:', name))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue adding the new job.')
      )
    })

// --- Add a task to a job ---

const postJobTaskRequest = (jobId, data) =>
  api(`${riskPrefix}/jobs/${jobId}/tasks`, { method: 'post', data })

export const postJobTaskApi = (
  { name },
  setSubmitting,
  modalRef,
  { jobId, subJobId }
) => (dispatch, getState) => {
  const mongoId = subJobId || getLogbookEntityReferencesState(getState())[jobId]
  return postJobTaskRequest(mongoId, { tasks: [{ name }] })
    .then(response => dispatch(updateJob(response, response._id)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue adding the task.')
      )
    })
}

// --- patch a task ---

const patchTaskRequest = ({ jobMongoId, taskId, data }) =>
  api(`${riskPrefix}/jobs/${jobMongoId}/tasks${taskId ? `/${taskId}` : ''}`, {
    method: 'patch',
    data,
  })

export const patchTaskApi = ({
  jobMongoId,
  taskId,
  patch: data,
  modalRef,
}) => dispatch =>
  patchTaskRequest({ jobMongoId, taskId, data })
    .then(response => dispatch(updateJob(response, response._id)))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue updating the task.')
      )
    })

// --- Remove a task ---

const removeTaskRequest = (jobMongoId, taskId) =>
  api(`${riskPrefix}/jobs/${jobMongoId}/tasks/${taskId}`, { method: 'delete' })

export const removeTaskApi = ({ jobMongoId, taskId, modalRef }) => dispatch =>
  removeTaskRequest(jobMongoId, taskId)
    .then(response => dispatch(updateJob(response, response._id)))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue deleting the task.')
      )
    })

// get individual subJob

const getJobRequest = jobMongoId => api(`${riskPrefix}/jobs/${jobMongoId}`)

const getJobNoLoaderApi = jobMongoId => dispatch =>
  getJobRequest(jobMongoId)
    .then(response =>
      Promise.all([
        response,
        dispatch(updateJob(response, response._id)),
        dispatch(getSingleSiteApi(response.site_id)),
      ])
    )
    .then(([job]) => job)

export const getJobApi = jobMongoId => (dispatch, getState) => {
  const inStore = getJobsState(getState())[jobMongoId]
  if (!inStore) {
    dispatch(toggleLoader(true))
  }
  return dispatch(getJobNoLoaderApi(jobMongoId))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

export const getJobAndAssessmentApi = jobMongoId => dispatch => {
  dispatch(toggleLoader(true))
  return Promise.resolve()
    .then(() => dispatch(getJobNoLoaderApi(jobMongoId)))
    .then(() => dispatch(getAssessmentsByJobIdApi(jobMongoId)))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

// --- add company info if missing ---

const getJobOrgRequest = jobMongoId =>
  api(`${riskPrefix}/jobs/${jobMongoId}?updateOrg=1`, { method: 'patch' })

export const getJobOrgInfoApi = jobMongoId => dispatch =>
  getJobOrgRequest(jobMongoId)
    .then(response => dispatch(updateJob(response, response._id)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
    })

// --- End job ---

export const endJobApi = (push, assessments, jobMongoId, modalRef) => (
  dispatch,
  getState
) => {
  const withIssues =
    assessments
      .map(id => {
        const assessment = getAssessmentState(getState())[id]
        return (
          assessment &&
          assessment.sections &&
          assessment.sections.filter(
            s => s.status.toLowerCase() === 'completed with issues'
          ).length > 0
        )
      })
      .filter(a => a).length > 0
  const setSubmitting = isSubmitting =>
    dispatch(toggleLoader(isSubmitting, false, modalRef))

  return Promise.resolve()
    .then(() => setSubmitting(true))
    .then(() =>
      dispatch(
        patchJobMetadata({
          patch: { status: withIssues ? 'completed with issues' : 'completed' },
          jobMongoId,
          modalRef,
          setSubmitting,
        })
      )
    )
    .then(() => push('/jobs/completed'))
    .catch(err => {
      debug(err)
      setSubmitting(false)

      dispatch(
        errorHandler(err, modalRef, 'There was an issue ending this job')
      )
    })
}

// --- Redux ---

const initialState = {}

const jobReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_JOB:
      return { ...state, ...payload }

    case UPDATE_JOB_METADATA:
      return { ...state, [reference]: { ...state[reference], ...payload } }

    default:
      return state
  }
}

export const getJobs = state => state

export default jobReducer
