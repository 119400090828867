import Debug from 'debug'
import api from '../../api'
import { errorHandler } from '../errorState/errorState'
import { updateTheme } from '../themeState/themeState'

const debug = Debug('web:whitelabelState:')

const UPDATE_SITE_LOGO = 'UPDATE_SITE_LOGO'

const getWhitelabelRequest = () => api(`whitelabels`)

export const updateSiteLogo = siteLogo => ({
  type: UPDATE_SITE_LOGO,
  payload: { siteLogo },
})

export const getWhitelabelApi = () => dispatch =>
  Promise.resolve()
    .then(getWhitelabelRequest)
    .then(({ logo, theme }) => {
      logo && dispatch(updateSiteLogo(logo))
      if (theme && typeof theme === 'object') {
        Object.entries(theme).forEach(themeChange =>
          dispatch(updateTheme(themeChange[1], themeChange[0]))
        )
      }
    })
    .catch(err => {
      debug(err)
      return dispatch(errorHandler(err))
    })

const initialState = {
  siteLogo: null,
}

const whitelabel = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_SITE_LOGO:
      return { ...state, ...payload }

    default:
      return state
  }
}

export const getSiteLogo = state => state.siteLogo

export default whitelabel
