/**
 * exported for tests
 * adds the correct id type by role
 * @param {{}} user
 * @param {number|string} id id of the site, client or company
 * @returns {} user object with parent
 */
export const addParentIdToUser = (user, id) => {
  const { role } = user
  if (role === 'admin' || role === 'technician') {
    return user
  }
  if (role === 'site-manager') {
    return { ...user, site_id: id }
  }
  if (role === 'client') {
    return { ...user, client_id: id }
  }
  if (role === 'manager' || role === 'engineer') {
    return { ...user, company_id: id }
  }
  const thrownObject = 'no known user'
  throw thrownObject
}
