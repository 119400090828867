import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import styled from '@emotion/styled'
import FormikFastOrSlowField from '../FormikFastOrSlowField/FormikFastOrSlowField'
import FormTitle from '../FormTitle/FormTitle'

export const FormikInput = styled(FormikFastOrSlowField)`
  display: block;
  width: 100%;
  padding: ${props => {
    const { icon, iconend } = props
    if (typeof icon === 'string') {
      if (iconend === 'true') {
        return '0.375rem 2.5rem 0.375rem 0.75rem'
      }
      return '0.375rem 0.75rem 0.375rem 2.5rem'
    }
    return '0.375rem 0.75rem'
  }};
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid
    ${props => (props.error ? props.theme.danger : 'rgba(0, 40, 100, 0.12)')};
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  :disabled {
    background-color: #f8f9fa;
    opacity: 0.5;
  }
  :focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`
export const FieldContainer = styled('div')`
  grid-area: ${props => props.name};
  width: 100%;
`

export const Error = styled(ErrorMessage)`
  color: ${props => props.theme.danger};
  font-size: 13px;
  padding: 0 2px;
  margin-bottom: -19px;

  ::first-letter {
    text-transform: capitalize;
  }
`

const BottomLineContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
`

const Counter = styled('div')`
  color: ${props => props.theme.muted};
  text-align: right;
  margin-bottom: -21px;
  font-size: 0.75rem;
`

const FormikField = ({
  title,
  name,
  showCounter,
  currentCount,
  maxCount,
  style,
  className,
  type,
  isRequired,
  ...otherProps
}) => (
  <FieldContainer name={name} className={className} style={style}>
    <FormTitle isRequired={isRequired} htmlFor={name}>
      {title}
    </FormTitle>
    <FormikInput
      {...otherProps}
      type={type}
      name={name}
      id={name}
      onWheel={e => {
        if (type === 'number') {
          e.target.blur()
        }
      }}
    />
    <BottomLineContainer>
      <div>
        <Error name={name} component="div" />
      </div>
      {showCounter && maxCount && (
        <Counter>
          ({currentCount || 0}/{maxCount})
        </Counter>
      )}
    </BottomLineContainer>
  </FieldContainer>
)

FormikField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  showCounter: PropTypes.bool,
  currentCount: PropTypes.node,
  maxCount: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  isRequired: PropTypes.bool,
  type: PropTypes.string,
}

FormikField.defaultProps = {
  title: null,
  type: null,
  currentCount: null,
  maxCount: null,
  showCounter: null,
  className: null,
  style: null,
  isRequired: null,
}

export default FormikField
