import Debug from 'debug'

import { toast } from 'react-toastify'
import api from '../../api'
import {
  toggleModal,
  toggleAreYouSureButtonLoading,
} from '../modalState/modalState'
import { errorHandler } from '../errorState/errorState'
import { getBreadcrumbsState } from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'

const debug = Debug('web:generalDeleteApi:')

// --- DELETE ---

/**
 * takes the service and id to send a delete request
 * @param {string} service type of service for delete such as: 'clients' || 'sites'
 * @param {number|string} id the id of the item to be deleted
 */
export const deleteRequest = (service, id) =>
  api(`${service}/${id}`, { method: 'delete' })

/**
 * this allows for the deletion of any item
 * designed for use with the delete button on the header bar
 * @param {string} service type of service for delete such as: 'clients' || 'sites'
 * @param {*} id the id of the item to be deleted
 * @param {*} modalRef the reference to the modal for closing and error messages
 */
export const generalDeleteApi = (
  service,
  id,
  modalRef,
  successMsg,
  errorMsg,
  push
) => (dispatch, getState) =>
  Promise.resolve()
    .then(() => dispatch(toggleAreYouSureButtonLoading(true)))
    .then(() => deleteRequest(service, id))
    .then(() => {
      const breadcrumbs = getBreadcrumbsState(getState())
      const lengthOfBreadcrumbs = breadcrumbs.length
      let url = '/'
      if (lengthOfBreadcrumbs > 1) {
        url = breadcrumbs[lengthOfBreadcrumbs - 2].to
      }
      return push(url)
    })
    // toggle the loader so that the screen shows the loader
    // until the content has been loaded fresh from the api
    // to avoid seeing the deleted item
    .then(() => dispatch(toggleLoader(true)))
    .then(() => dispatch(toggleAreYouSureButtonLoading(false)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() =>
      toast(`${successMsg}`, {
        type: toast.TYPE.ERROR,
      })
    )
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue when trying to ${errorMsg}.`
        )
      )
      dispatch(toggleAreYouSureButtonLoading(false))
    })
