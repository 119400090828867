import Debug from 'debug'
import moment from 'moment'
import api from '../../api'
import { getDashboardState, getAuthUserRoleState } from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { getReportsRequest } from '../reportsState/reportsState'
import reportApiToReducerReady from '../reportsState/reportApiToReducerReady/reportApiToReducerReady'
import capitaliseFirstLetter from '../../utils/capitaliseFirstLetter/capitaliseFirstLetter'
import { toggleModal } from '../modalState/modalState'
import { errorHandler } from '../errorState/errorState'

const debug = Debug('web:dashboardState:')

// --- Actions ---

const DASHBOARD_INITIAL_LOAD = 'DASHBOARD_INITIAL_LOAD'
const DASHBOARD_REPORTS_UPDATE = 'DASHBOARD_REPORTS_UPDATE'
const DASHBOARD_LIVE_READINGS_UPDATE = 'DASHBOARD_LIVE_READINGS_UPDATE'
const UPDATE_DASHBOARD_ITEM = 'UPDATE_DASHBOARD_ITEM'

// --- Action Creators ---

// -- Welcome Modal --

export const checkWelcome = () => dispatch => {
  if (typeof localStorage.welcome === 'undefined') {
    dispatch(toggleModal('welcome', true))
    localStorage.welcome = 'welcomed'
  }
}

// -- UpdateDashboardItem --
/**
 * updates a specific dashboard item
 * @param {string} role the user's role
 * @param {string} dataRef the reference to the dashboard item
 * @param {any} data
 */
const updateDashboardItem = (role, dataRef, data) => ({
  type: UPDATE_DASHBOARD_ITEM,
  payload: { [dataRef]: data },
  reference: role,
})

// --- API requests ---

// -- Read --

// const getDashboardRequest = () => api('dashboard/self')

const getLiveReadingsRequest = params => api('readings', { params })

const getStatisticsRequest = (endpoint, params) =>
  api(`statistics/${endpoint}`, { params })

const getCountsRequest = () => api('/org-structure/counts')

// -- Dashboard Initial Load --

// - Counts -

/**
 * defines the title for the counts card of the dashboard
 * @param {string} role
 */
const countsTitle = role => {
  if (role === 'siteManager') {
    return 'Site Overview'
  }
  return 'Company Overview'
}

/**
 * Takes the object with all the counts and converts to an array with title and value
 * @param {{}} counts
 * @param {string} role
 */
const countsGenerator = (counts, role) =>
  Object.entries(counts)
    .filter(key => {
      if (role === 'siteManager') {
        return ['locations', 'outlets', 'readings'].includes(key[0])
      }
      if (role === 'client') {
        return ['sites', 'locations', 'outlets', 'readings'].includes(key[0])
      }
      if (role === 'manager' || role === 'engineer') {
        return [
          'clients',
          'sites',
          'locations',
          'outlets',
          'readings',
        ].includes(key[0])
      }
      return true
    })
    .map(key => ({
      title: key[0],
      value: key[1],
    }))

/**
 * defines the reference by role for extracting data from the rollups in the dashboard return
 * @param {string} role
 */
const roleReference = role => {
  switch (role) {
    case 'siteManager':
      return 'locations'

    case 'client':
    case 'manager':
    case 'engineer':
      return 'sites'

    default:
      return 'sites'
  }
}

// - Compliance Graph Card -

/**
 * calculates the graph data for compliance card
 * sliced to only show the last 6 weeks of data
 * reversed prior_weeks to plot oldest first on the graph
 * @param complianceData {[]}
 * @param role {string}
 */
// const calculateComplianceGraphData = (complianceData, role) => {
//   const reference = roleReference(role)

//   const previousWeeks = [...complianceData]
//   const slicedWeeks = previousWeeks.slice(0, 6)
//   slicedWeeks.reverse()
//   return slicedWeeks.map(week => ({
//     date: week.week_ending,
//     value: week[reference].compliant,
//   }))
// }

/**
 * defines the title for the compliance GraphCard
 * @param {string} role
 */
const complianceCardTitle = role => {
  if (role === 'siteManager') {
    return 'Site Compliance'
  }
  if (role === 'client') {
    return 'Client Compliance'
  }
  return 'Company Compliance'
}

// -- Compliance Per ... --

/**
 * takes the last week data and processes it into the compliance per ... part of the dashboard
 * @param {{}} lastWeek
 * @param {string} role
 */
const calculateCompliancePer = (data = [], role) => {
  switch (role) {
    case 'siteManager':
      return {
        title: 'locations',
        url: '/locations/',
        child: 'outlets',
        compliance: data,
      }

    case 'client':
      return {
        title: 'sites',
        url: '/sites/',
        child: 'locations',
        compliance: data,
      }

    case 'manager':
    case 'engineer':
      return {
        title: 'clients',
        url: '/clients/',
        child: 'sites',
        compliance: data,
      }

    case 'admin':
      return {
        title: 'companies',
        url: '/companies/',
        child: 'clients',
        compliance: data,
      }

    default:
      return {}
  }
}

// -- complianceEvolution --

/**
 * extracts compliance over time
 * used by the compliance evolution graph on the dashboard
 * @param {[]} evolution
 * @param {string} reference
 * @returns {{}} date and % compliance
 */
const calculateCompliantOverTime = evolution => {
  const invertedEvolution = [...evolution]
  invertedEvolution.reverse()
  return invertedEvolution.map(week => ({
    x: week.ending,
    y: week.percent_compliant,
    noData: week.no_readings,
  }))
}

const complianceEvoXAxisTitle = frequency => {
  switch (frequency) {
    case 'daily':
      return 'Day'
    case 'monthly':
      return 'Month'
    case 'annually':
      return 'Year'
    default:
      return 'Time'
  }
}

/**
 * defines the data for the dashboard by user role and returns the action creator
 * @param {{}} apiReturn
 * @param {string} role
 * @returns {{}} action creator for dashboard
 */
const dashboardInitialLoad = role => {
  // constants from the response
  const reference = roleReference(role)

  const compliantCount = 0
  const nonCompliantCount = 0
  const complianceChange = 0
  const complianceGraphData = []

  // -- Dashboard payload --
  const dashboard = {
    countsTitle: countsTitle(role),
    compliantCount,
    nonCompliantCount,
    complianceChange,
    complianceGraphData,
    complianceCardTitle: complianceCardTitle(role),
    // compliantOverTime: calculateCompliance(
    //   [],
    //   reference,
    //   'compliant',
    //   role
    // ),
    // nonCompliantOverTime: compliantOverTime(
    //   rollups,
    //   reference,
    //   'non_compliant'
    // ),
    complianceEvolutionCountOf: capitaliseFirstLetter(reference),
  }

  // Action creator
  return {
    type: DASHBOARD_INITIAL_LOAD,
    payload: { ...dashboard },
    reference: role,
  }
}

export const dashboardLiveReadingsUpdate = (readings, role) => {
  const liveReadings = readings.map(reading => {
    const {
      outlet,
      location,
      site,
      client,
      company,
      value,
      compliant,
      created_at,
      id,
    } = reading

    const locationInformation = () => {
      switch (role) {
        case 'siteManager':
          return [location, outlet]
        case 'client':
          return [site, location, outlet]
        case 'manager':
        case 'engineer':
          return [client, site, location, outlet]
        default:
          return [company, client, site, location, outlet]
      }
    }

    return {
      id,
      locationInformation: locationInformation(),
      value,
      compliance: compliant,
      created_at,
      outletId: outlet.id,
    }
  })

  return {
    type: DASHBOARD_LIVE_READINGS_UPDATE,
    payload: { liveReadings },
    reference: role,
  }
}

const dashboardReportsUpdate = (reports, role) => ({
  type: DASHBOARD_REPORTS_UPDATE,
  payload: { reports: reportApiToReducerReady(reports) },
  reference: role,
})

export const getDashboardApi = () => (dispatch, getState) => {
  const role = getAuthUserRoleState(getState())
  const internalRole = role === 'site-manager' ? 'siteManager' : role
  const dashboardInStore =
    getDashboardState(getState())[internalRole].dashboard || {}
  if (!dashboardInStore) {
    dispatch(toggleLoader(true))
  }
  const currentMonthBeginning = moment
    .utc()
    .startOf('month')
    .toISOString()
  const currentMonthEnding = moment
    .utc()
    .endOf('month')
    .toISOString()

  const prevMonthBeginning = moment
    .utc()
    .subtract(1, 'month')
    .startOf('month')
    .toISOString()
  const prevMonthEnding = moment
    .utc()
    .subtract(1, 'month')
    .endOf('month')
    .toISOString()
  dispatch(dashboardInitialLoad(internalRole))
  return Promise.all([
    getCountsRequest().then(({ counts }) =>
      dispatch(
        updateDashboardItem(
          internalRole,
          'counts',
          countsGenerator(counts, internalRole)
        )
      )
    ),
    Promise.all([
      getStatisticsRequest('per', {
        beginning: currentMonthBeginning,
        ending: currentMonthEnding,
      }).then(({ data }) => {
        debug(data)
        return data
      }),

      getStatisticsRequest('per', {
        beginning: prevMonthBeginning,
        ending: prevMonthEnding,
      }).then(({ data }) => {
        debug(data)
        return data
      }),
    ])
      .then(data => {
        const [current, before = []] = data || []
        const beforeObject = {}
        if (Array.isArray(before)) {
          before.forEach(b => {
            beforeObject[b.id] = b.percent_compliant
          })
        }
        return current.map(i => ({
          ...i,
          change:
            typeof beforeObject[i.id] === 'number'
              ? (i.percent_compliant - beforeObject[i.id]).toFixed(2)
              : i.percent_compliant || 0,
        }))
      })
      .then(data =>
        dispatch(
          updateDashboardItem(
            internalRole,
            'compliancePer',
            calculateCompliancePer(data, internalRole)
          )
        )
      ),
    getStatisticsRequest('evolution').then(({ data, frequency }) => {
      dispatch(
        updateDashboardItem(
          internalRole,
          'compliantOverTime',
          calculateCompliantOverTime(data)
        )
      )
      dispatch(
        updateDashboardItem(
          internalRole,
          'compliantOverTimeFrequency',
          frequency
        )
      )
      dispatch(
        updateDashboardItem(
          internalRole,
          'complianceEvoXAxisName',
          complianceEvoXAxisTitle(frequency)
        )
      )
    }),
    getLiveReadingsRequest({
      $limit: 15,
      $sort: {
        created_at: -1,
      },
      withOrganisation: true,
    }).then(({ data }) =>
      dispatch(dashboardLiveReadingsUpdate(data, internalRole))
    ),
    getReportsRequest({
      $limit_per: 1,
    }).then(({ data }) => dispatch(dashboardReportsUpdate(data, internalRole))),
  ])
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return dashboardInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- Redux ---

const initialState = {
  siteManager: {},
  client: {},
  engineer: {},
  manager: {},
  technician: {},
  admin: {},
}

const dashboard = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case DASHBOARD_INITIAL_LOAD:
    case DASHBOARD_REPORTS_UPDATE:
    case DASHBOARD_LIVE_READINGS_UPDATE:
    case UPDATE_DASHBOARD_ITEM:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getDashboard = state => state

export default dashboard
