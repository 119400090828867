import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import image0 from './image0.jpg'
import image1 from './image1.jpg'
import { Desktop } from '../../../components/Responsive/Responsive'

const SideImage = styled('div')`
  clip-path: ellipse(32% 70% at 35% 50%);
  width: 100%;
  height: 100%;
  margin-left: -35%;
  margin-right: -25%;

  background-image: url(${props => props.image});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
`

const Cover = styled('div')`
  background-color: rgba(17, 154, 212, 0.2);
  width: 100%;
  height: 100%;
`

const SideImages = () => {
  const images = [image0, image1]
  const index = Math.floor(Math.random() * images.length)

  const [image] = useState(images[index])

  return image ? (
    <Desktop>
      <SideImage image={image}>
        <Cover />
      </SideImage>
    </Desktop>
  ) : null
}

// SideImages.propTypes = {}

export default React.memo(SideImages)
