const RT = 'RT'
const company = 'Company'
const client = 'Client'
const site = 'Site'

export default [
  {
    name: 'Dashboard',
    link: '/',
    icon: 'far fa-chart-bar',
  },
  {
    name: 'Companies',
    link: '/companies',
    parent: RT,
    icon: 'far fa-city',
  },
  {
    name: 'Clients',
    link: '/clients',
    parent: RT,
    icon: 'far fa-address-card',
  },
  {
    name: 'Clients',
    link: '/companies/:id',
    parent: company,
    icon: 'far fa-address-card',
  },
  {
    name: 'Sites',
    link: '/sites',
    parent: [RT, company],
    icon: 'far fa-building',
  },
  {
    name: 'Sites',
    link: '/clients/:id',
    parent: client,
    icon: 'far fa-building',
  },
  {
    name: 'Locations',
    link: '/sites/:id',
    parent: site,
    icon: 'far fa-map-pin',
  },
  {
    name: 'Locations',
    link: '/locations',
    parent: [RT, company, client],
    icon: 'far fa-map-pin',
  },
  {
    name: 'Outlets',
    link: '/outlets',
    parent: [RT, company, client, site],
    icon: 'far fa-tint',
  },
  {
    name: 'Reports',
    link: '/reports',
    icon: 'far fa-file-alt',
  },
  {
    name: 'My Jobs',
    link: '/my-jobs',
    icon: 'far fa-briefcase',
  },
]
