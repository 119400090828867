/* eslint import/no-cycle: 0 */
import { combineReducers } from 'redux'
import auth, {
  getAuthUser,
  getAuthUserRole,
  getAuthUserEmail,
  getAuthTotpTokenStatus,
  getAuthOTP,
  getAuthUserId,
  getAuthUserFirstName,
  getAuthUserLastName,
  getAuthUserProfilePhoto,
  getAuth,
} from '../reducers/authState/authState'
import clients, {
  getClients,
  getClientById,
} from '../reducers/clientsState/clientsState'
import navigation, {
  getPrimaryLinks,
  getAccountLinks,
  getAdminLinks,
  getMatchParams,
  getMatchParamsIdRef,
  getTertiaryLinks,
  getTMenuRef,
} from '../reducers/navigationState/navigationState'
import userAccount, {
  getProfilePhotoProgress,
  getUserAccountHistory,
  getUpdateEmailStatus,
} from '../reducers/userAccountState/userAccountState'
import outletDevice, {
  getOutlets,
  getOutletById,
  getReadingsLoader,
  getDeviceById,
  getSensorInfoPageRef,
  getGraphReadingsLoader,
  getTriggerTypes,
  getPipeTypes,
  getOutletStats,
} from '../reducers/outletDeviceState/outletDeviceState'
import sites, {
  getSites,
  getSiteById,
  getSiteStatsByWeek,
} from '../reducers/sitesState/sitesState'
import search, {
  getSearchValue,
  getSearchBoxVisible,
} from '../reducers/searchState/searchState'
import header, {
  getShowActionButton,
  getActionButtonText,
  getActionButtonModalRef,
  getActionButtonFunc,
} from '../reducers/header'
import breadcrumb, {
  getBreadcrumbs,
  getBreadcrumbLoaded,
  getBreadcrumbsById,
  getCurrentBreadcrumbId,
} from '../reducers/breadcrumbState/breadcrumbState'
import pageLoader, {
  getPageLoader,
  getPageLoaderWaitList,
  getAnyLoader,
} from '../reducers/pageLoaderState/pageLoaderState'
import datePicker, {
  getDRPStartDate,
  getDRPEndDate,
  getShowDatePicker,
} from '../reducers/dateRangePickerState/dateRangePickerState'
import whitelabel, {
  getSiteLogo,
} from '../reducers/whitelabelState/whitelabelState'
import modal, {
  getDisplayModal,
  getAreYouSureLoading,
} from '../reducers/modalState/modalState'
import report, {
  getReport,
  getReportLoadingStatus,
  getPdfUrl,
} from '../reducers/reportsState/reportsState'
import manualReading, {
  getDeleteButtonActive,
} from '../reducers/manualReadingState/manualReadingState'
import users, {
  getUser,
  getUserRequestStatus,
  getUsersByRole,
} from '../reducers/userState/userState'
import selected, {
  getSelectedReading,
  getSelectedContact,
  getSelectedUser,
  getSelectedPdf,
  getSelectedSensor,
  getSelectedLogbookEntry,
  getSelectedLogbookDocument,
} from '../reducers/selectedState/selectedState'
import errors, { getErrors } from '../reducers/errorState/errorState'
import companies, {
  getCompanies,
  getCompanyById,
} from '../reducers/companiesState/companiesState'
import permissions, {
  getPermissions,
} from '../reducers/permissionsState/permissionsState'
import dashboard, {
  getDashboard,
} from '../reducers/dashboardState/dashboardState'
import contacts, { getContacts } from '../reducers/contactsState/contactsState'
import userRegistration, {
  getUserRegistrationInfo,
  getUserRegistrationTokenStatus,
} from '../reducers/userRegistrationState/userRegistrationState'
import loginState, {
  getLoginStatus,
  LOGOUT,
  getLoginNotice,
  getTempLoginDetails,
  getLoginEmailDisabled,
} from '../reducers/loginState/loginState'
import notifications, {
  getUnsubscribeLoading,
  getSubscribeLoading,
  getUserNotificationsBySite,
  getNotificationMethods,
} from '../reducers/notificationsState/notificationsState'

import gateways, {
  getGatewaysBySite,
  getGateway,
  getGatewayFirmware,
  getSensorManagement,
  getBatchSwitchChanges,
  getFirmwareSelectOptions,
  getGatewaySyncStatus,
} from '../reducers/gatewaysState.js/gatewaysState'

import postCodeLookup, {
  getAvailableAddresses,
  getAddressByIndex,
  getPostCodeLookupStatus,
} from '../reducers/postcodeLookupState/postcodeLookupState'
import sideMenu, {
  getSideMenuOpen,
} from '../reducers/sideMenuState/sideMenuState'
import theme, { getTheme } from '../reducers/themeState/themeState'
import locations, {
  getLocationById,
  getLocations,
} from '../reducers/locationsState/locationsState'
import support, {
  getSupportTopics,
} from '../reducers/customerSupportState/customerSupportState'
import passwordReset, {
  getPWResetTokenStatus,
  getPWResetInfo,
} from '../reducers/passwordResetState/passwordResetState'
import sensors, {
  getSensorById,
  getSensorLoadingStatus,
} from '../reducers/sensorState/sensorState'
import pagination, {
  getPagination,
  getPaginationLoading,
} from '../reducers/paginationState/paginationState'
import l8Logbook, {
  getLogbook,
  getLogbookEntityReferences,
  getUploadProgress,
  getLogbookSubJobReferences,
  getActiveLogbooks,
  getUserJobLists,
  getLogbookSummarySettings,
  getLogbookSections,
} from '../reducers/l8LogbookState/l8LogbookState'
import l8LogbookDocuments, {
  getDocuments,
  getDocsToDelete,
} from '../reducers/l8LogbookDocumentState/l8LogbookDocumentState'
import dates, { getDates } from '../reducers/dateState/dateState'
import jobs, { getJobs } from '../reducers/jobsState/jobsState'
import templates, {
  getTemplateById,
  getTemplatesByCompanyId,
  getOptionsListByCompanyId,
} from '../reducers/templatesState/templatesState'
import assessment, {
  getAssessment,
  getAssessmentsByJobId,
} from '../reducers/assessmentsState/assessmentsState'
import jobsPage, {
  getJobPageView,
  getMyJobsPageByReference,
} from '../reducers/jobsPageState/jobsPageState'

const appReducer = combineReducers({
  auth,
  l8Logbook,
  l8LogbookDocuments,
  jobs,
  companies,
  clients,
  sites,
  locations,
  outletDevice,
  sensors,
  manualReading,
  navigation,
  pagination,
  theme,
  userAccount,
  search,
  header,
  breadcrumb,
  pageLoader,
  datePicker,
  whitelabel,
  modal,
  report,
  users,
  selected,
  errors,
  permissions,
  dashboard,
  contacts,
  userRegistration,
  loginState,
  notifications,
  gateways,
  postCodeLookup,
  sideMenu,
  support,
  passwordReset,
  dates,
  templates,
  assessment,
  jobsPage,
})

/**
 * The rootReducer defers to the appReducer unless the LOGOUT action is called in which case it passes an undefined state.
 * An undefined state resets the store to it's initial state
 * @param {object} state
 * @param {object} action
 */
const rootReducer = (state, action) => {
  const { type } = action

  let newState = state
  if (type === LOGOUT) {
    newState = undefined
    localStorage.removeItem('jwt')
  }
  return appReducer(newState, action)
}
export default rootReducer

/**
 * selectors
 */

// login
export const getLoginStatusState = state => getLoginStatus(state.loginState)
export const getLoginNoticeState = state => getLoginNotice(state.loginState)
export const getTempLoginDetailsState = state =>
  getTempLoginDetails(state.loginState)
export const getLoginEmailDisabledState = state =>
  getLoginEmailDisabled(state.loginState)

// dates
export const getDatesState = state => getDates(state.dates)

// theme
export const getThemeState = state => getTheme(state.theme)

// common
export const getUpdateEmailStatusState = state =>
  getUpdateEmailStatus(state.common)

// dashboard
export const getDashboardState = state => getDashboard(state.dashboard)

// permissions
export const getPermissionsState = state => getPermissions(state.permissions)

// selected
export const getSelectedReadingState = state =>
  getSelectedReading(state.selected)
export const getSelectedContactState = state =>
  getSelectedContact(state.selected)
export const getSelectedUserState = state => getSelectedUser(state.selected)
export const getSelectedPdfState = state => getSelectedPdf(state.selected)
export const getSelectedSensorState = state => getSelectedSensor(state.selected)
export const getSelectedLogbookEntryState = state =>
  getSelectedLogbookEntry(state.selected)
export const getSelectedLogbookDocumentState = state =>
  getSelectedLogbookDocument(state.selected)

// errors
export const getErrorsState = state => getErrors(state.errors)

// l8Logbook
export const getL8LogbookState = state => getLogbook(state.l8Logbook)
export const getLogbookEntityReferencesState = state =>
  getLogbookEntityReferences(state.l8Logbook)
export const getUploadProgressState = (state, dropZoneRef) =>
  getUploadProgress(state.l8Logbook, dropZoneRef)
export const getLogbookSubJobReferencesState = state =>
  getLogbookSubJobReferences(state.l8Logbook)
export const getActiveLogbooksState = state =>
  getActiveLogbooks(state.l8Logbook)
export const getJobUserListsState = state => getUserJobLists(state.l8Logbook)
export const getLogbookSummarySettingsState = state =>
  getLogbookSummarySettings(state.l8Logbook)
export const getLogbookSectionsState = state =>
  getLogbookSections(state.l8Logbook)

// l8Logbook documents
export const getDocumentsState = state => getDocuments(state.l8LogbookDocuments)
export const getDocsToDeleteState = state =>
  getDocsToDelete(state.l8LogbookDocuments)

// l8Logbook subJobs
export const getJobsState = state => getJobs(state.jobs)

// jobs Page
export const getMyJobsPageByReferenceState = (state, reference) =>
  getMyJobsPageByReference(state.jobsPage, reference)
export const getJobPageViewState = state => getJobPageView(state.jobsPage)

// assessments

export const getAssessmentState = state => getAssessment(state.assessment)
export const getAssessmentsByJobIdState = state =>
  getAssessmentsByJobId(state.assessment)

// contacts
export const getContactsState = state => getContacts(state.contacts)

// companies
export const getCompaniesState = state => getCompanies(state.companies)
export const getCompanyByIdState = state => getCompanyById(state.companies)

// clients
export const getClientsState = state => getClients(state.clients)
export const getClientByIdState = state => getClientById(state.clients)

// sites
export const getSitesState = state => getSites(state.sites)
export const getSiteByIdState = state => getSiteById(state.sites)
export const getSiteStatsByWeekState = (state, siteId, reference) =>
  getSiteStatsByWeek(state.sites, siteId, reference)

// locations
export const getLocationsState = state => getLocations(state.locations)
export const getLocationByIdState = state => getLocationById(state.locations)

// reports
export const getReportState = state => getReport(state.report)
export const getReportLoadingStatusState = state =>
  getReportLoadingStatus(state.report)
export const getPdfUrlState = state => getPdfUrl(state.report)

// pageLoader
export const getPageLoaderState = state => getPageLoader(state.pageLoader)
export const getPageLoaderWaitListState = state =>
  getPageLoaderWaitList(state.pageLoader)
export const getAnyLoaderState = state => getAnyLoader(state.pageLoader)

// users
export const getUserState = state => getUser(state.users)
export const getUserRequestStatusState = state =>
  getUserRequestStatus(state.users)
export const getUsersByRoleState = state => getUsersByRole(state.users)

// userRegistration
export const getUserRegistrationInfoState = state =>
  getUserRegistrationInfo(state.userRegistration)
export const getUserRegistrationTokenStatusState = state =>
  getUserRegistrationTokenStatus(state.userRegistration)

// whitelabel
export const getSiteLogoState = state => getSiteLogo(state.whitelabel)

// OutletDevice

export const getOutletsState = state => getOutlets(state.outletDevice)
export const getOutletByIdState = state => getOutletById(state.outletDevice)
export const getReadingsLoaderState = state =>
  getReadingsLoader(state.outletDevice)
export const getGraphReadingsLoaderState = state =>
  getGraphReadingsLoader(state.outletDevice)
export const getDeviceByIdState = state => getDeviceById(state.outletDevice)
export const getSenorInfoPageRefState = state =>
  getSensorInfoPageRef(state.outletDevice)
export const getPipeTypesState = state => getPipeTypes(state.outletDevice)
export const getTriggerTypesState = state => getTriggerTypes(state.outletDevice)
export const getOutletStatsState = (state, ref) =>
  getOutletStats(state.outletDevice, ref)

// sensors
export const getSensorByIdState = state => getSensorById(state.sensors)
export const getSensorLoadingStatusState = state =>
  getSensorLoadingStatus(state.sensors)

// breadcrumbs
export const getBreadcrumbsState = state => getBreadcrumbs(state.breadcrumb)
export const getBreadcrumbLoadedState = state =>
  getBreadcrumbLoaded(state.breadcrumb)
export const getBreadcrumbsByIdState = (state, id) =>
  getBreadcrumbsById(state.breadcrumb, id)
export const getCurrentBreadcrumbIdState = state =>
  getCurrentBreadcrumbId(state.breadcrumb)

// Date Range Picker
export const getDRPStartDateState = state => getDRPStartDate(state.datePicker)
export const getDRPEndDateState = state => getDRPEndDate(state.datePicker)
export const getShowDatePickerState = state =>
  getShowDatePicker(state.datePicker)

// auth
export const getAuthState = state => getAuth(state.auth)
export const getAuthUserState = state => getAuthUser(state.auth)
export const getAuthUserEmailState = state => getAuthUserEmail(state.auth)
export const getAuthTotpTokenStatusState = state =>
  getAuthTotpTokenStatus(state.auth)
export const getAuthUserRoleState = state => getAuthUserRole(state.auth)
export const getAuthOTPState = state => getAuthOTP(state.auth)
export const getAuthUserIdState = state => getAuthUserId(state.auth)
export const getAuthUserFirstNameState = state =>
  getAuthUserFirstName(state.auth)
export const getAuthUserLastNameState = state => getAuthUserLastName(state.auth)
export const getAuthUserProfilePhotoState = state =>
  getAuthUserProfilePhoto(state.auth)

// navigation
export const getNavigationState = state => state.navigation
export const getPrimaryLinksState = state => getPrimaryLinks(state.navigation)
export const getAccountLinksState = state => getAccountLinks(state.navigation)
export const getTertiaryLinksState = state => getTertiaryLinks(state.navigation)
export const getAdminLinksState = state => getAdminLinks(state.navigation)
export const getMatchParamsState = state => getMatchParams(state.navigation)
export const getMatchParamsIdRefState = state =>
  getMatchParamsIdRef(state.navigation)
export const getTMenuRefState = state => getTMenuRef(state.navigation)

// header
export const getShowActionButtonState = state =>
  getShowActionButton(state.header)
export const getActionButtonTextState = state =>
  getActionButtonText(state.header)
export const getActionButtonModalRefState = state =>
  getActionButtonModalRef(state.header)
export const getActionButtonFuncState = state =>
  getActionButtonFunc(state.header)

// search
// todo: remove once all search is server side
export const getSearchValueState = state => getSearchValue(state.search)
export const getSearchBoxVisibleState = state =>
  getSearchBoxVisible(state.search)

// userAccount
export const getProfilePhotoProgressState = state =>
  getProfilePhotoProgress(state.userAccount)
export const getUserAccountHistoryState = state =>
  getUserAccountHistory(state.userAccount)

// modal
export const getDisplayModalState = state => getDisplayModal(state.modal)
export const getAreYouSureLoadingState = state =>
  getAreYouSureLoading(state.modal)

// notifications
export const getUnsubscribeLoadingState = state =>
  getUnsubscribeLoading(state.notifications)
export const getSubscribeLoadingState = state =>
  getSubscribeLoading(state.notifications)
export const getUserNotificationsBySiteState = state =>
  getUserNotificationsBySite(state.notifications)
export const getNotificationMethodsState = state =>
  getNotificationMethods(state.notifications)

// manualReading
export const getDeleteButtonActiveState = state =>
  getDeleteButtonActive(state.manualReading)

// gateways
export const getGatewaysBySiteState = state => getGatewaysBySite(state.gateways)
export const getGatewayState = state => getGateway(state.gateways)
export const getGatewayFirmwareState = state =>
  getGatewayFirmware(state.gateways)
export const getSensorManagementState = state =>
  getSensorManagement(state.gateways)
export const getBatchSwitchChangesState = state =>
  getBatchSwitchChanges(state.gateways)
export const getFirmwareSelectionOptionsState = state =>
  getFirmwareSelectOptions(state.gateways)
export const getGatewaySyncStatusState = state =>
  getGatewaySyncStatus(state.gateways)

// postCodeLookup
export const getAvailableAddressesState = state =>
  getAvailableAddresses(state.postCodeLookup)
export const getAddressByIndexState = (state, index) =>
  getAddressByIndex(state.postCodeLookup, index)
export const getPostCodeLookupStatusState = state =>
  getPostCodeLookupStatus(state.postCodeLookup)

// sideMenu
export const getSideMenuOpenState = state => getSideMenuOpen(state.sideMenu)

// support
export const getSupportState = state => state.support
export const getSupportTopicsState = state => getSupportTopics(state.support)

// passwordReset

export const getPWResetTokenStatusState = state =>
  getPWResetTokenStatus(state.passwordReset)
export const getPWResetInfoState = state => getPWResetInfo(state.passwordReset)

// pagination
export const getPaginationState = state => getPagination(state.pagination)
export const getPaginationLoadingState = state =>
  getPaginationLoading(state.pagination)

// formBuilder
export const getTemplateByIdState = state => getTemplateById(state.templates)
export const getTemplatesByCompanyIdState = state =>
  getTemplatesByCompanyId(state.templates)
export const getOptionsListByCompanyIdState = state =>
  getOptionsListByCompanyId(state.templates)
