import defaultTheme from '../../css/theme'

// --- Actions ---

const UPDATE_SINGLE_THEME = 'UPDATE_SINGLE_THEME'
const UPDATE_THEME_BLOCK = 'UPDATE_THEME_BLOCK'

// --- Action Creators ---

const updateSingleTheme = (theme, reference) => ({
  type: UPDATE_SINGLE_THEME,
  payload: { [reference]: theme },
})

const updateThemeBlock = (theme, reference) => ({
  type: UPDATE_THEME_BLOCK,
  payload: { ...theme },
  reference,
})

export const updateTheme = (theme, reference) => dispatch => {
  if (typeof theme === 'object') {
    return dispatch(updateThemeBlock(theme, reference))
  }
  return dispatch(updateSingleTheme(theme, reference))
}
// --- Redux ---

const initialState = {
  ...defaultTheme,
}

const themeReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_SINGLE_THEME:
      return { ...state, ...payload }
    case UPDATE_THEME_BLOCK:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getTheme = state => state

export default themeReducer
