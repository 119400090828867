import { toast } from 'react-toastify'

export const successToaster = (action, reference, type = 'success') =>
  toast(
    `You have successfully ${action ? ` ${action}` : ''}${
      reference ? ` ${reference}` : ''
    }.`,
    {
      type,
    }
  )
