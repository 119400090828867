const TOGGLE_MODAL = 'TOGGLE_MODAL'
const TOGGLE_ARE_YOU_SURE_BUTTON_LOADING = 'TOGGLE_ARE_YOU_SURE_BUTTON_LOADING'

export const toggleModal = (modalRef, onOff = false) => ({
  type: TOGGLE_MODAL,
  payload: { [modalRef]: onOff },
  reference: 'displayModal',
})

export const toggleAreYouSureButtonLoading = areYouSureLoading => ({
  type: TOGGLE_ARE_YOU_SURE_BUTTON_LOADING,
  payload: { areYouSureLoading },
})

const initialState = {
  areYouSureLoading: false,
  displayModal: {},
}

const modalReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case TOGGLE_ARE_YOU_SURE_BUTTON_LOADING:
      return { ...state, ...payload }
    case TOGGLE_MODAL:
      return { ...state, [reference]: { ...state[reference], ...payload } }

    default:
      return state
  }
}

export const getDisplayModal = state => state.displayModal
export const getAreYouSureLoading = state => state.areYouSureLoading

export default modalReducer
