import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

const NoStyledA = styled('a')`
  color: inherit;
  display: block;
  /* outline: none; */

  :hover {
    text-decoration: none;
    color: inherit;
  }
`
const NoStyleNavLink = styled(NavLink)`
  color: inherit;
  /* outline: none; */
  :hover {
    text-decoration: none;
  }
`

const Link = ({
  children,
  to,
  href,
  linksOn,
  exact,
  activeStyle,
  activeLink,
  icon,
  iconSize,
  ...safeProps
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal =
    /^\/(?!\/)/.test(to) || (typeof to === 'object' && to !== null)

  // Use gatsby-link for internal links, and <a> for others
  if (!to) {
    return <div {...safeProps}>{children}</div>
  }
  if (!internal || !linksOn) {
    return (
      <NoStyledA
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...safeProps}
      >
        {children}
      </NoStyledA>
    )
  }
  return (
    <NoStyleNavLink to={to} activeStyle={activeStyle} {...safeProps}>
      {children}
    </NoStyleNavLink>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  href: PropTypes.string,
  linksOn: PropTypes.bool,
}

Link.defaultProps = {
  to: null,
  href: null,
  linksOn: true,
}

export default React.memo(Link)

export const GridLink = styled(Link)`
  display: grid;
`
