import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import Debug from 'debug'
import LoginFormComponent from './LoginFormComponent/LoginFormComponent'
import { loginApi } from '../../../reducers/loginState/loginState'

const debugError = Debug('web:loginForm:Error')

const LoginForm = ({ initialValues, handleSubmit, reference, from }) => (
  <Formik
    initialValues={{
      ...{
        email: '',
        password: '',
      },
      ...initialValues,
    }}
    onSubmit={(values, { setSubmitting }) => {
      Promise.resolve()
        .then(() => handleSubmit(values, setSubmitting, reference))
        .catch(err => {
          debugError(err)
          setSubmitting(false)
        })
    }}
    validationSchema={yup.object().shape({
      email: yup
        .string()
        .trim()
        .email('Email must be valid.')
        .required(' '),
      password: yup
        .string()
        .trim()
        .required(' '),
    })}
    render={formikProps => (
      <LoginFormComponent {...formikProps} reference={reference} from={from} />
    )}
  />
)

LoginForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.node),
  handleSubmit: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  from: PropTypes.objectOf(PropTypes.string),
}
LoginForm.defaultProps = {
  initialValues: {},
  from: { location: { pathname: '/' } },
}

const mapDispatchToProps = dispatch => ({
  handleSubmit: (values, setSubmitting, reference) =>
    dispatch(loginApi(values, setSubmitting, reference)),
})

export default connect(
  null,
  mapDispatchToProps
)(LoginForm)
