import Debug from 'debug'
import api from '../../api'
import {
  getReportState,
  getPdfUrlState,
  getSelectedPdfState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { errorHandler } from '../errorState/errorState'
import reportApiToReducerReady from './reportApiToReducerReady/reportApiToReducerReady'
import { updateSelectedPdf } from '../selectedState/selectedState'
import {
  calculatePagination,
  nextPageForLists,
} from '../paginationState/paginationState'
import { SHOW_ALL } from '../../Routes/routesConstants'
import downloadDocument, {
  downloadDocumentRequest,
  createDownloadLinkAndClick,
} from '../../utils/downloadDocument/downloadDocument'
import { riskPrefix } from '../l8LogbookState/l8LogbookState'
import { getJobsRequest } from '../jobsPageState/jobsPageState'

const debug = Debug('web:reportsState:')

// --- Actions ---

const UPDATE_REPORTS_BY_SITE = 'UPDATE_REPORTS_BY_SITE'
const UPDATE_REPORTS_STATUS = 'UPDATE_REPORTS_STATUS'
const UPDATE_PDF_URL = 'UPDATE_PDF_URL'
const NEXT_REPORT_PAGE = 'NEXT_REPORT_PAGE'
// --- Action Creators ---

/**
 * creates a loadingStatus object to display a spinner for downloading a report
 * @param {number} pdfId
 * @param {boolean} status
 */
export const updateReportLoadingStatus = (pdfId, status = false) => ({
  type: UPDATE_REPORTS_STATUS,
  payload: { [pdfId]: status },
  reference: 'loadingStatus',
})

/**
 * takes an array of reports for a site and processes them into what is required by the UI
 * this is placed into an object designated by the siteId
 * @param {number} siteId the id for the site to process reports for
 * @param {[{}]} info an array of report objects
 * @returns action creator with report information processed
 */
const updateReportsBySite = (reports, id) => ({
  type: UPDATE_REPORTS_BY_SITE,
  payload: { [id]: reportApiToReducerReady(reports) },
  reference: 'reports',
})

const updatePdfUrl = (pdfId, pdfUrl) => ({
  type: UPDATE_PDF_URL,
  payload: { [pdfId]: pdfUrl },
  reference: 'pdfUrl',
})

const nextReportPage = (reports, id) => ({
  type: NEXT_REPORT_PAGE,
  payload: reportApiToReducerReady(reports),
  reference: id,
})

// --- Get reports for site ---

/**
 * gets reports by params
 * @param {{}} params the params to send to the reports
 */
export const getReportsRequest = ({ params = { $limit: 20 } }) =>
  api(`reports/v2`, { params })

/**
 * Used on initial load of the reports page
 * this toggles the page loader and requests the reports for the site
 * @param {number} siteId
 */
export const getReportsApi = (
  pathname,
  siteId,
  params = { $limit: 20 },
  togglePageLoader = true
) => (dispatch, getState) => {
  const ref = siteId || SHOW_ALL
  const reportsForSite = getReportState(getState())[ref]
  return Promise.resolve()
    .then(() =>
      reportsForSite || !togglePageLoader ? null : dispatch(toggleLoader(true))
    )
    .then(() => getReportsRequest({ params }))
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination({ ...pagination }, pathname))
      return dispatch(updateReportsBySite(data, ref))
    })
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return reportsForSite || !togglePageLoader
        ? null
        : toggleLoader(false, true)
    })
}

// -- get Next Report Page --

export const getNextReportPageApi = (
  parentId,
  params,
  paginationRef
) => dispatch =>
  dispatch(
    nextPageForLists(
      parentId,
      params,
      paginationRef,
      getReportsRequest,
      nextReportPage
    )
  )

/**
 * takes the pdfId and creates an axios request to get the pdf as a blob
 * this is then converted to a URL and downloaded by a simulated click of a link
 * solution from: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
 * @param {{pdfId:number, title:string}} object containing pdfId and title
 */
export const downloadPDF = ({ pdfId, title }) => dispatch => {
  dispatch(updateReportLoadingStatus(pdfId, { loading: true, error: false }))
  return downloadDocument(`reports/${pdfId}`, title)
    .then(() =>
      dispatch(
        updateReportLoadingStatus(pdfId, { loading: false, error: false })
      )
    )
    .catch(err => {
      debug(err)
      dispatch(
        updateReportLoadingStatus(pdfId, {
          loading: false,
          error: true,
          message: err,
        })
      )
      dispatch(errorHandler(err))
    })
}

// --- Preview PDF ---

const pdfInfoRequest = id => api(`reports/info/${id}`)

export const previewPDF = ({ pdfId, params, jobMongoId }) => (
  dispatch,
  getState
) => {
  const pdfInStore = getPdfUrlState(getState())[pdfId]
  const { assessment_id } = params || {}
  const siteReport = !assessment_id
  return Promise.all([
    dispatch(toggleLoader(true)),
    typeof pdfInStore === 'string'
      ? null
      : downloadDocumentRequest(
          `${siteReport ? `reports/${pdfId}` : `/${riskPrefix}/reports`}`,
          params
        ).then(url => dispatch(updatePdfUrl(pdfId, url))),
    siteReport
      ? pdfInfoRequest(pdfId).then(data =>
          dispatch(updateSelectedPdf({ ...data, download_token: pdfId }))
        )
      : getJobsRequest({
          job_id: jobMongoId,
          include_assessment_meta: true,
        }).then(({ data }) => {
          const [{ assessment_meta = {} }] = data || [{}]
          const { file_name = jobMongoId } = assessment_meta || {}
          dispatch(
            updateSelectedPdf({ title: file_name, download_token: pdfId })
          )
        }),
  ])
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(
        updateReportLoadingStatus(pdfId, {
          loading: false,
          error: true,
          message: err,
        })
      )
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

// --- Action button download PDF ---

/**
 * collates information about the pdf
 * puts it together and uses the preview blob url to create a download link
 * the link is clicked and the file downloads with the report title
 */
export const actionButtonDownload = () => (dispatch, getState) => {
  const selectedPdf = getSelectedPdfState(getState())
  const { download_token, title } = selectedPdf
  const url = getPdfUrlState(getState())[download_token]
  createDownloadLinkAndClick(url, title)
}

// --- Redux ---

const initialState = {
  loadingStatus: { loading: false, error: false },
  pdfUrl: {},
  reports: {},
}

const reportsReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_REPORTS_BY_SITE:
    case UPDATE_PDF_URL:
    case UPDATE_REPORTS_STATUS:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case NEXT_REPORT_PAGE: {
      const current = state.reports[reference] || []
      return {
        ...state,
        reports: {
          ...state.reports,
          [reference]: [...current, ...payload],
        },
      }
    }
    default:
      return state
  }
}

export const getReport = state => state.reports
export const getReportLoadingStatus = state => state.loadingStatus
export const getPdfUrl = state => state.pdfUrl

export default reportsReducer
