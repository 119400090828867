import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { SmallButton } from '../../components/Buttons/Buttons'
import Icon from '../../components/Icon/Icon'
import DropDownMenu from '../../components/DropDownMenu/DropDownMenu'

const Container = styled('div')`
  position: relative;
`
const AltButton = styled('div')`
  -webkit-appearance: none;
  cursor: pointer;
`

const DropDownMenuButton = styled(SmallButton)`
  height: 27px;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
`

const EllipsisIcon = styled(Icon)`
  justify-self: center;
  cursor: pointer;
  :before {
    line-height: 0px;
    text-align: center;
    padding-left: 1px;
    padding-bottom: 1px;
  }
`

const VerticalEllipsisIcon = styled(Icon)`
  justify-self: center;
  cursor: pointer;
  padding: 8px 16px;
  color: ${props => props.theme.muted};
  :hover {
    color: ${props => props.theme.default};
  }
`

class LineItemDropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }

  onComponentWillUnmount() {
    document.removeEventListener('click', this.closeMenu)
  }

  showMenu = e => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu = event => {
    const { closeOnMenuClick } = this.props
    if (
      this.dropdownMenu &&
      (closeOnMenuClick || !this.dropdownMenu.contains(event.target))
    ) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu)
      })
    }
  }

  render() {
    const {
      className,
      dropDownClass,
      children,
      dropDownButton: DropDownButton,
      dropDownButtonOrientationVertical,
    } = this.props
    const { showMenu } = this.state
    return (
      <Container className={className}>
        {(DropDownButton && (
          <AltButton onClick={this.showMenu}>
            <DropDownButton />
          </AltButton>
        )) ||
          (dropDownButtonOrientationVertical && (
            <VerticalEllipsisIcon
              className="far fa-ellipsis-v"
              onClick={this.showMenu}
            />
          )) || (
            <DropDownMenuButton onClick={this.showMenu}>
              <EllipsisIcon className="far fa-ellipsis-h" />
            </DropDownMenuButton>
          )}

        {showMenu ? (
          <div
            ref={element => {
              this.dropdownMenu = element
            }}
            style={{ position: 'relative', zIndex: 2 }}
          >
            <DropDownMenu
              right
              className={dropDownClass}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {children}
            </DropDownMenu>
          </div>
        ) : null}
      </Container>
    )
  }
}

LineItemDropDown.propTypes = {
  children: PropTypes.node,
  /** used to close the dropdown menu after clicking any item */
  closeOnMenuClick: PropTypes.bool,
  dropDownButton: PropTypes.func,
  className: PropTypes.string,
  dropDownClass: PropTypes.string,
  dropDownButtonOrientationVertical: PropTypes.bool,
}

LineItemDropDown.defaultProps = {
  children: null,
  closeOnMenuClick: false,
  dropDownButton: null,
  className: null,
  dropDownClass: null,
  dropDownButtonOrientationVertical: false,
}

export default LineItemDropDown
