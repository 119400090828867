import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'

const CardContainer = styled('div')`
  label: CardOutlineOnly;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${props => props.bgColor || '#fff'};
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  box-shadow: ${props => props.theme.cardBoxShadow};
  position: relative;
  width: 100%;
  height: ${props => (props.fillSpace ? '100%' : 'auto')};
`

const CardOutlineOnly = ({
  children,
  className,
  fillSpace,
  bgColor,
  ...otherProps
}) => (
  <div className={className} {...otherProps}>
    <CardContainer fillSpace={fillSpace} bgColor={bgColor}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </CardContainer>
  </div>
)

CardOutlineOnly.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fillSpace: PropTypes.bool,
  bgColor: PropTypes.string,
}

CardOutlineOnly.defaultProps = {
  children: null,
  className: null,
  fillSpace: null,
  bgColor: null,
}

export default CardOutlineOnly
