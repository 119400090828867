import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Form } from 'formik'
import { FixedPrimaryButton } from '../../../../components/Buttons/Buttons'
import Field from '../../../../components/form/FormikField/FormikField'
import FormErrors from '../../../../components/form/FormErrors/FormErrors'
import { getErrorsState } from '../../../../redux/reducer'
import RequiredMessage from '../../../../components/RequiredMessage/RequiredMessage'

const FormGrid = styled(Form)`
  label: LoginFormGrid;
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'token'
    'submit';
`

const VerificationTokenFormComponent = ({
  errors,
  touched,
  isSubmitting,
  dirty,
  isValid,
  externalErrors,
}) => (
  <div>
    <FormErrors externalErrors={externalErrors} />

    <RequiredMessage />

    <FormGrid>
      <Field
        type="string"
        name="token"
        title="Authentication Code"
        isRequired
        error={touched.token && errors.token}
        autoFocus
        autoComplete="off"
      />

      <FixedPrimaryButton
        style={{ gridArea: 'submit' }}
        disabled={!dirty || !isValid}
        loading={isSubmitting}
        type="submit"
      >
        Login
      </FixedPrimaryButton>
    </FormGrid>
  </div>
)

VerificationTokenFormComponent.propTypes = {
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  externalErrors: PropTypes.objectOf(PropTypes.node),
}

VerificationTokenFormComponent.defaultProps = {
  externalErrors: null,
}

const mapStateToProps = (state, { reference }) => ({
  externalErrors: getErrorsState(state)[reference],
})

export default connect(mapStateToProps)(VerificationTokenFormComponent)
