import Debug from 'debug'
import api from '../../api'
import {
  getSitesState,
  getClientByIdState,
  getSiteByIdState,
} from '../../redux/reducer'
import {
  updateClientById,
  getClientsRequest,
} from '../clientsState/clientsState'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { errorHandler } from '../errorState/errorState'
import { SHOW_ALL } from '../../Routes/routesConstants'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { toggleModal } from '../modalState/modalState'
import { updateLastBreadcrumbTitleApi } from '../breadcrumbState/breadcrumbState'
import findAndUpdate from '../../utils/findAndUpdate/findAndUpdate'
import {
  nextPageForLists,
  calculatePagination,
} from '../paginationState/paginationState'
import arrToObject from '../../utils/arrToObject/arrToObject'

const debug = Debug('web:sitesState:')

// --- Actions ---

const UPDATE_SITES_INFO = 'UPDATE_SITES_INFO'
const UPDATE_SITE_BY_ID = 'UPDATE_SITE_BY_ID'
const NEXT_SITE_PAGE = 'NEXT_SITE_PAGE'
const UPDATE_SITE_STATS_BY_MONTH = 'UPDATE_SITE_STATS_BY_MONTH'

// --- Action Creators ---

/**
 * Sites action creator
 * @param {[]} sites
 */
export const updateSitesInfo = (sites, id) => ({
  type: UPDATE_SITES_INFO,
  payload: { [id]: sites },
  reference: 'sites',
})

export const updateSiteById = (site, id) => ({
  type: UPDATE_SITE_BY_ID,
  payload: { [id]: site },
  reference: 'siteById',
})

const nextSitePage = (sites, id) => ({
  type: NEXT_SITE_PAGE,
  payload: sites,
  reference: id,
})

export const updateSiteStatsByMonth = (siteId, monthStats, weeklyStats) => ({
  type: UPDATE_SITE_STATS_BY_MONTH,
  payload: { [siteId]: { monthStats, weeklyStats } },
  reference: 'stats',
})

// --- Create ---

/**
 * Creates a new site for a given client_id
 * TODO remove hard coded client_id
 * @param {{}} site object containing the site's information
 */
export const addNewSiteRequest = (site, client_id = 1) =>
  api(`clients/${client_id}/sites`, { method: 'post', data: site })

/**
 * takes the information from the formik form and sends the request to add a new site
 * @param {{}} site all of the information from the formik form
 * @param {function} setSubmitting the formik function to show that a form is submitting
 * @param {string} modalRef the reference for the modal
 */
export const addNewSite = (site, id, setSubmitting, modalRef) => (
  dispatch,
  getState
) =>
  Promise.resolve()
    .then(() => addNewSiteRequest(site, id))
    .then(response => {
      const currentSites = getSitesState(getState())[id]
      return [...currentSites, response]
    })
    .then(sites => dispatch(updateSitesInfo(sites, id)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created the site:', site.name))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue adding the new site.')
      )
    })

// --- Read ---

/**
 *  Note: Destructured input!
 *  sends an all sites request if no id
 *  or client's sites if there is a clientId
 *  or specific site if there is a siteId
 * @param {number | string} parentId all sites for a client
 * @param {number | string} SiteId specific information about this siteId
 * @param {{}} params params (optional)
 */
export const getSiteRequest = ({
  siteId,
  parentId: clientId,
  params = {
    $limit: 20,
  },
}) => {
  let service = 'sites'
  if (clientId) {
    service = `clients/${clientId}/sites`
  }
  if (siteId) {
    service = `sites/${siteId}`
  }
  return api(service, { params })
}

/**
 * for use in promise chains
 * @param {string} siteId
 */
export const getSingleSiteApi = siteId => dispatch =>
  getSiteRequest({ siteId, params: {} }).then(response =>
    dispatch(updateSiteById(response, response.id))
  )

/**
 * Get Sites thunk
 * @param {number | string} id the clientId to filter by
 */
export const getSitesApi = (pathname, clientId) => (dispatch, getState) => {
  const ref = clientId || SHOW_ALL
  const sitesInStore = getSitesState(getState())[ref]
  const parentsInStore = getClientByIdState(getState())[ref]
  return Promise.resolve()
    .then(() => (sitesInStore ? null : dispatch(toggleLoader(true))))
    .then(() =>
      Promise.all([
        getSiteRequest({ parentId: clientId }).then(
          ({ data, ...pagination }) => {
            debug(data)
            dispatch(calculatePagination(pagination, pathname))
            return dispatch(updateSitesInfo(data, ref))
          }
        ),
        clientId
          ? getClientsRequest({ clientId }).then(response =>
              dispatch(updateClientById(response, clientId))
            )
          : null,
      ])
    )
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      if (clientId) {
        return sitesInStore && parentsInStore
          ? null
          : dispatch(toggleLoader(false, true))
      }
      return sitesInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// -- Next site page --

export const nextSitePageApi = (parentId, params, paginationRef) => dispatch =>
  dispatch(
    nextPageForLists(
      parentId,
      params,
      paginationRef,
      getSiteRequest,
      nextSitePage
    )
  )
// --- Update ---

/**
 * patches a site with updates
 * @param {{}} site
 * @param {string | number} id
 */
const patchSiteRequest = (site, id) =>
  api(`sites/${id}`, { method: 'patch', data: site })

export const updateSiteApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, parentId, ...newValues } = values
  const current = getSitesState(getState())[parentId]
  const { sensor_count, locations, gateway_count, name } = getSiteByIdState(
    getState()
  )[id]
  const newName = values.name || name
  return Promise.resolve()
    .then(() => patchSiteRequest(newValues, id)) // send patch request
    .then(response => {
      const updated = { ...response, sensor_count, locations, gateway_count }
      return dispatch(
        findAndUpdate(
          current,
          updated,
          id,
          parentId,
          updateSiteById,
          updateSitesInfo
        )
      )
    })
    .then(() =>
      newName === name
        ? null
        : dispatch(updateLastBreadcrumbTitleApi('site', id, newName))
    )
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newName))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, `There was an issue updating ${name}.`)
      )
    })
}

// --- Site stats ---

const getSiteStatsRequest = params => api('sites/stats', { params })

export const getSiteStatsApi = ({ ids, date }) => dispatch =>
  getSiteStatsRequest({
    ids,
    breakdown_per_week_for_month: date,
  }).then(({ data }) => {
    const { whole_period, weeks = [] } = data
    const byWeekObj = arrToObject(weeks, 'beginning')
    dispatch(updateSiteStatsByMonth(ids, whole_period, byWeekObj))
  })

// --- Redux ---

const initialState = {
  sites: [],
  siteById: {},
  stats: {},
}

const sites = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_SITES_INFO:
    case UPDATE_SITE_BY_ID:
    case UPDATE_SITE_STATS_BY_MONTH:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case NEXT_SITE_PAGE: {
      const current = state.sites[reference] || []
      return {
        ...state,
        sites: {
          ...state.sites,
          [reference]: [...current, ...payload],
        },
      }
    }
    default:
      return state
  }
}

export const getSites = state => state.sites
export const getSiteById = state => state.siteById
export const getSiteStatsByWeek = (state, siteId, reference) => {
  const { stats } = state
  const { weeklyStats = {} } = stats[siteId] || {}
  return weeklyStats[reference]
}

export default sites
