import Debug from 'debug'
import {
  loginStatus,
  updateLoginEmailDisabled,
  LOGGED_OUT,
} from '../loginState/loginState'
import { LOGIN_REFERENCE } from '../../NoAuthRoutes/LoginPage/LoginPage'
import { history } from '../../redux/store'

const debug = Debug('web:errorState:')

// --- Actions ---

const UPDATE_ERRORS = 'UPDATE_ERRORS'
const UPDATE_404 = 'UPDATE_404'

// --- Action Creators ---

export const updateErrors = (errorRef, msg, type = 'danger') => {
  const error = msg ? { msg, type } : null
  return {
    type: UPDATE_ERRORS,
    payload: { [errorRef]: error },
  }
}

const update404 = pathname => ({
  type: UPDATE_404,
  payload: { four04: { [pathname]: true } },
})

// --- error Handler ---

export const errorHandler = (
  serverErr,
  errorRef,
  msg,
  type = 'danger'
) => dispatch => {
  debug(serverErr.response)

  const { response = {}, message: serverErrMsg = null } = serverErr
  const { data, status } = response || {}
  const { message } = data || {}

  debug(response, 'errorResponse')

  let errorMessage = msg

  // JWT expiry
  if (message === 'jwt token has expired') {
    dispatch(
      updateErrors(
        LOGIN_REFERENCE,
        'Your session has expired, please log back in to continue where you left off.',
        'info'
      )
    )
    dispatch(updateLoginEmailDisabled(true))
    // by updating the status rather than a true log out the state is stored for after login
    return dispatch(loginStatus(LOGGED_OUT))
  }
  if ((status === 404 || status === 403) && !errorRef) {
    const { pathname } = history.location
    return dispatch(update404(pathname))
  }
  // Server down or Offline
  if (typeof errorRef === 'string') {
    // Unable to contact API
    if (serverErrMsg === 'Network Error') {
      errorMessage = window.navigator.onLine
        ? 'The service is currently unavailable please try again at another time.'
        : 'You are offline, please check your internet connection and try again.'
    }
    if (serverErrMsg && serverErrMsg.includes('timeout')) {
      errorMessage =
        'Your request has timed out, the service may not be available please try again at another time.'
    }
    return dispatch(updateErrors(errorRef, errorMessage, type))
  }
  return null
}

// --- Redux ---

const initialState = {}

const errorState = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_ERRORS:
    case UPDATE_404:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getErrors = state => state

export default errorState
