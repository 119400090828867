import Debug from 'debug'
import { errorHandler } from '../errorState/errorState'
import api from '../../api'
import {
  getUserNotificationsBySiteState,
  getAuthUserIdState,
  getMatchParamsState,
  getNotificationMethodsState,
} from '../../redux/reducer'
import {
  addToLoaderWaitList,
  toggleLoader,
  removeFromLoaderWaitList,
} from '../pageLoaderState/pageLoaderState'
import { toggleModal } from '../modalState/modalState'

const debug = Debug('web:notificationsState:')

// --- Actions ---

const TOGGLE_UNSUBSCRIBE_LOADER = 'TOGGLE_UNSUBSCRIBE_LOADER'
const TOGGLE_SUBSCRIBE_LOADER = 'TOGGLE_SUBSCRIBE_LOADER'
const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS'

// --- Action Creators ---

const toggleUnsubscribeLoader = unsubscribeLoading => ({
  type: TOGGLE_UNSUBSCRIBE_LOADER,
  payload: { unsubscribeLoading },
})

const toggleSubscribeLoader = subscribeLoading => ({
  type: TOGGLE_SUBSCRIBE_LOADER,
  payload: { subscribeLoading },
})

export const updateUserNotifications = ({ site_id, compliancy_report }) => {
  const { email, sms, app } = compliancy_report
  const isSubscribed = email || sms || app
  return {
    type: UPDATE_USER_NOTIFICATIONS,
    payload: { [site_id]: { ...compliancy_report, isSubscribed } },
    reference: 'userNotifications',
  }
}

// --- Get User Preferences ---

const getUserPreferenceRequest = userId => api(`users/${userId}/preferences`)

export const getUserPreferencesApi = () => (dispatch, getState) => {
  const userPreferencesInStore = getUserNotificationsBySiteState(getState())
  return Promise.resolve()
    .then(
      () =>
        userPreferencesInStore ||
        dispatch(addToLoaderWaitList('userPreferences'))
    )
    .then(() => getAuthUserIdState(getState()))
    .then(userId => getUserPreferenceRequest(userId))
    .then(({ subscriptions }) =>
      dispatch(updateUserNotifications(subscriptions))
    )
    .then(
      () =>
        userPreferencesInStore ||
        dispatch(removeFromLoaderWaitList('userPreferences'))
    )
    .catch(err => {
      debug(err)
      return userPreferencesInStore || dispatch(toggleLoader(false, true))
    })
}

// --- Patch User Preferences ---

/**
 * patches user preferences
 * @param {number|string} userId
 * @param {{}} payload
 */
const updateUserNotificationPreferences = (userId, data) =>
  api(`users/${userId}/preferences`, { method: 'patch', data })

// --- payload builder ---

/**
 * builds the notification payload based on
 * whether the user is trying to subscribe or not
 * @param {boolean} toSubscribe
 * @param {function} getState function to get the state from the store
 * @returns {{}} payload for notification update
 */
const payloadBuilder = (toSubscribe, getState) => {
  const matchParams = getMatchParamsState(getState())
  const { email, sms, app } = getNotificationMethodsState(getState())
  const site_id = matchParams.siteId
  return {
    subscriptions: {
      compliancy_report: {
        email: toSubscribe && email,
        sms: toSubscribe && sms,
        app: toSubscribe && app,
      },
      site_id,
    },
  }
}

// --- Unsubscribe ---
export const unsubscribeApi = modalRef => (dispatch, getState) => {
  const userId = getAuthUserIdState(getState())
  const payload = payloadBuilder(false, getState)
  return Promise.resolve()
    .then(() => dispatch(toggleUnsubscribeLoader(true)))
    .then(() => updateUserNotificationPreferences(userId, payload))
    .then(() => dispatch(updateUserNotifications(payload.subscriptions)))
    .then(() => {
      dispatch(toggleUnsubscribeLoader(false))
      return dispatch(toggleModal(modalRef))
    })
    .catch(err => {
      debug(err)
      dispatch(toggleUnsubscribeLoader(false))
      return dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue unsubscribing you from this service'
        )
      )
    })
}
// --- Subscribe ---

export const subscribeToNotificationsApi = reference => (
  dispatch,
  getState
) => {
  const userId = getAuthUserIdState(getState())
  const payload = payloadBuilder(true, getState)
  return Promise.resolve()
    .then(() => dispatch(toggleSubscribeLoader(true)))
    .then(() => updateUserNotificationPreferences(userId, payload))
    .then(() => dispatch(updateUserNotifications(payload.subscriptions)))
    .then(() => dispatch(toggleSubscribeLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(toggleSubscribeLoader(false))

      return dispatch(
        errorHandler(
          err,
          reference,
          'There was an issue subscribing you to this service'
        )
      )
    })
}
// --- Redux ---

const initialState = {
  userNotifications: {},
  unsubscribeLoading: false,
  subscribeLoading: false,
  notificationMethods: {
    email: true,
    sms: true,
    app: true,
  },
}

const Notifications = (state = initialState, actions) => {
  const { type, payload, reference } = actions
  switch (type) {
    case TOGGLE_UNSUBSCRIBE_LOADER:
    case TOGGLE_SUBSCRIBE_LOADER:
      return { ...state, ...payload }
    case UPDATE_USER_NOTIFICATIONS:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getUnsubscribeLoading = state => state.unsubscribeLoading
export const getSubscribeLoading = state => state.subscribeLoading
export const getUserNotificationsBySite = state => state.userNotifications
export const getNotificationMethods = state => state.notificationMethods

export default Notifications
