import Debug from 'debug'
import { toast } from 'react-toastify'
import api from '../../api'
import { errorHandler } from '../errorState/errorState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'

const debug = Debug('web:passwordResetState:')

// --- Actions ---

const STORE_PASSWORD_RESET_INFO = 'STORE_PASSWORD_RESET_INFO'
const PASSWORD_RESET_STATUS = 'PASSWORD_RESET_STATUS'

// --- Action Creators ---

const storePasswordResetInfo = resetInfo => {
  const { content } = resetInfo
  const { email } = content
  return {
    type: STORE_PASSWORD_RESET_INFO,
    payload: { info: { email } },
  }
}

const passwordResetStatus = status => ({
  type: PASSWORD_RESET_STATUS,
  payload: { status },
})

// --- Submit Email for Resetting ---
/**
 * sends the email address for resetting the password
 * @param {{}} data object to be sent in the post
 */
const postEmailToPasswordResetRequest = data =>
  api(`/users/passwordreset`, { method: 'post', data })

/**
 * submits an email to for generating a password reset token
 * @param {{}} values
 * @param {function} resetForm
 * @param {string} reference
 */
export const submitEmailToPasswordResetApi = (
  push,
  values,
  resetForm,
  reference
) => dispatch =>
  Promise.resolve()
    .then(() => postEmailToPasswordResetRequest(values))
    .then(() => {
      toast(
        `An email has been sent to ${values.email} with a link to reset your password.`,
        { type: 'success' }
      )
    })
    .then(() => push('/login'))

    .catch(err => {
      debug(err)
      resetForm()
      dispatch(
        errorHandler(
          err,
          reference,
          'There was an issue submitting your password reset request, please check your email is correct.'
        )
      )
    })

// --- Check PW Reset token ---

const checkPWResetTokenRequest = token => api(`/tokens/password-reset/${token}`)

export const checkPWResetTokenApi = token => dispatch =>
  Promise.resolve()
    .then(() => dispatch(passwordResetStatus('loading')))
    .then(() => checkPWResetTokenRequest(token))
    .then(response => dispatch(storePasswordResetInfo(response)))
    .then(() => dispatch(passwordResetStatus('success')))
    .catch(() => dispatch(passwordResetStatus('error')))

// --- Reset Password ---

const resetPasswordRequest = data =>
  api(`/tokens/password-reset/${data.token}`, { method: 'put', data })

export const resetPasswordApi = (
  push,
  values,
  setSubmitting,
  reference
) => dispatch =>
  Promise.resolve()
    .then(() => resetPasswordRequest(values))
    .then(() => successToaster('reset', 'your password'))
    .then(() => push('/login'))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          reference,
          'There was an issue resetting your password, please try again.'
        )
      )
    })
// --- Redux ---

const initialState = {}

const passwordReset = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case STORE_PASSWORD_RESET_INFO:
    case PASSWORD_RESET_STATUS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getPWResetTokenStatus = state => state.status
export const getPWResetInfo = state => state.info

export default passwordReset
