import axios from 'axios'
import Debug from 'debug'
import { errorHandler, updateErrors } from '../errorState/errorState'
import { getAddressByIndexState } from '../../redux/reducer'

const debug = Debug('web:postcodeLookupState:')

// --- Actions ---

const UPDATE_AVAILABLE_ADDRESSES = 'UPDATE_AVAILABLE_ADDRESSES'
const UPDATE_POST_CODE_LOOKUP_STATUS = 'UPDATE_POST_CODE_LOOKUP_STATUS'

// --- Action Creators ---

export const updateAvailableAddresses = (postCodeResults = []) => {
  const addressOptions = postCodeResults.map((address, i) => ({
    title: `${address.line_1}, ${address.town}`,
    value: i,
  }))

  return {
    type: UPDATE_AVAILABLE_ADDRESSES,
    payload: { addressOptions, postCodeResults },
  }
}

const updatePostCodeLookupStatus = (isLookingUpPostCode = false) => ({
  type: UPDATE_POST_CODE_LOOKUP_STATUS,
  payload: { isLookingUpPostCode },
})

// --- Postcode lookup ---

const getPostcodeLookupRequest = postCode =>
  axios({
    url: `https://simpledata.io/api.php?access_token=${
      process.env.REACT_APP_POST_CODE_API_TOKEN
    }&post_code=${postCode.replace(/\s/g, '')}`,
    method: 'GET',
  })

/**
 * sends a post code request to look for available addresses that match the post code
 * @param {string} postCode postcode to look up
 * @param {string} modalRef the modal or error reference for any error messages
 */
export const postCodeLookupApi = (postCode, modalRef) => dispatch => {
  // clears down the post code errors
  dispatch(updateErrors(modalRef, null))
  // clears down the available addresses
  dispatch(updateAvailableAddresses([]))
  return Promise.resolve()
    .then(() => dispatch(updatePostCodeLookupStatus(true))) // start the post code spinner
    .then(() => getPostcodeLookupRequest(postCode)) // make the request
    .then(({ data = {} }) => {
      const { results = [] } = data
      if (results.length === 0) {
        const err = 1 // err 1 = no matching addresses
        throw err
      }
      dispatch(updateAvailableAddresses(results)) // update the available results
    })

    .catch(err => {
      debug(err)
      // default failure message
      let errMsg =
        'Post code look-up failed, please enter the address manually.'
      if (err === 1) {
        errMsg =
          'No matching post code found, please try another or enter the address manually.'
      }
      dispatch(errorHandler(err, modalRef, errMsg))
    })
    .then(() => dispatch(updatePostCodeLookupStatus(false))) // stop the post code spinner
}

// --- Update from post code lookup

export const updateFromPostCodeLookup = (
  lookupRef,
  setFieldValue,
  modalRef
) => (dispatch, getState) => {
  try {
    const address = getAddressByIndexState(getState(), lookupRef)
    const {
      line_1: address_line_1,
      line_2: address_line_2,
      town: city,
      county,
    } = address
    const addressObject = {
      address_line_1,
      address_line_2,
      city,
      county,
    }
    Object.entries(addressObject).forEach(i => {
      if (typeof i[1] !== 'string' || i[1] === '') {
        return false
      }
      return setFieldValue(i[0], i[1])
    })
  } catch (err) {
    debug(err)
    dispatch(
      errorHandler(
        err,
        modalRef,
        `There was an issue updating your address, please enter the address manually.`
      )
    )
  }
}

// --- Redux ---

const initialState = {}

const postCodeReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_AVAILABLE_ADDRESSES:
    case UPDATE_POST_CODE_LOOKUP_STATUS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getAvailableAddresses = state => state.addressOptions
export const getAddressByIndex = (state, index) =>
  state.postCodeResults
    ? state.postCodeResults[index]
    : { line_1: '', line_2: '', town: '', county: '' }

export const getPostCodeLookupStatus = state => state.isLookingUpPostCode

export default postCodeReducer
