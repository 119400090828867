/**
 * takes an array of objects and reduces it to an object by a specific identifying field
 * @param {[]} arr
 * @param {string} itemSelector
 * @returns {{}} object of items specified by the unique key
 */
const arrToObject = (arr, itemSelector) =>
  // eslint-disable-next-line  no-sequences, no-return-assign, no-param-reassign
  arr.reduce((obj, item) => ((obj[item[itemSelector]] = item), obj), {})

export default arrToObject
