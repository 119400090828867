/* eslint jsx-a11y/no-static-element-interactions:0 jsx-a11y/click-events-have-key-events:0 */
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SIDE_MENU_BREAK_POINT } from '../SideMenuContainer/SideMenuContainer'
import { toggleSideMenu } from '../../../reducers/sideMenuState/sideMenuState'
import { fadeIn } from '../../../css/animations'

const StyledMask = styled('div')`
  label: Mask;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${props => props.theme.modalBackground};
  cursor: pointer;
  z-index: 3;
  animation: ${fadeIn} 0.4s;

  @media (min-width: ${SIDE_MENU_BREAK_POINT}) {
    display: none;
  }
`

const SideMenuMask = ({
  children,
  className,
  onClick,
  openMenu,
  onClickOverride,
}) =>
  openMenu ? (
    <StyledMask onClick={onClickOverride || onClick} className={className}>
      <div onClick={ev => ev.stopPropagation()}>{children}</div>
    </StyledMask>
  ) : null

SideMenuMask.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  openMenu: PropTypes.bool,
  onClickOverride: PropTypes.func,
}

SideMenuMask.defaultProps = {
  children: null,
  className: null,
  openMenu: false,
  onClickOverride: null,
}

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(toggleSideMenu(false)),
})

export default connect(
  null,
  mapDispatchToProps
)(SideMenuMask)
