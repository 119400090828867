import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const MobileDropDownContainer = styled('div')`
  label: MobileDropDownContainer;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 40px;
  justify-self: stretch;
  cursor: pointer;
  color: ${props => props.theme.navLink};
  font-weight: 600;
  user-select: none;
  :hover {
    color: ${props => props.theme.hover.primary};
  }
  :after {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    position: absolute;
    right: 5px;
    pointer-events: none;
    align-self: center;
    color: ${props => props.theme.muted};
  }
`

const MobileDropDownText = styled('div')`
  font-size: 0.9rem;
  margin-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  text-transform: capitalize;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`

const MobileDropDownItem = ({ children }) => (
  <MobileDropDownContainer>
    <MobileDropDownText>{children}</MobileDropDownText>
  </MobileDropDownContainer>
)

MobileDropDownItem.propTypes = {
  children: PropTypes.node,
}
MobileDropDownItem.defaultProps = {
  children: null,
}
export default MobileDropDownItem
