import Debug from 'debug'
import api from '../../api'

const debug = Debug('web:createDownloadLinkAndClick:')

// --- downloadPDF ---

/**
 * request to get pdf and convert to a blog URL
 * @param {string} pdfId hash pdfId to download
 * @returns {string} promise containing url of pdf converted from blob
 */
export const downloadDocumentRequest = (url, params, axiosHeaders) =>
  api(url, {
    responseType: 'blob',
    params,
    ...axiosHeaders,
  }).then(response => {
    debug(response)
    const blobUrl = URL.createObjectURL(response)
    return blobUrl
  })

/**
 * create download link helper function
 * @param {string} url
 * @param {string} title
 */
export const createDownloadLinkAndClick = (url, title, type = 'pdf') =>
  Promise.resolve().then(() => {
    const link = document.createElement('a')
    link.href = url
    /**
     * The url can be viewed rather than downloaded if you remove
     * link.setAttribute('download', `${title}.pdf`)
     * however it is slow to change page and must be in the same tab
     * it will display as a blob url
     */
    let newTitle = `${title}.${type}`
    if (typeof title === 'string' && title.endsWith(`.${type}`)) {
      newTitle = title
    }
    link.setAttribute('download', newTitle)
    document.body.appendChild(link)
    link.click()
  })

const downloadDocument = (url, title, params, type, axiosHeaders) =>
  downloadDocumentRequest(url, params, axiosHeaders).then(blobUrl =>
    createDownloadLinkAndClick(blobUrl, title, type)
  )

export default downloadDocument
