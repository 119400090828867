import Debug from 'debug'
import api from '../../api'
import sectionTitleLookup from './sectionTitleLookup/sectionTitleLookup'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import {
  getL8LogbookState,
  getSiteByIdState,
  getJobUserListsState,
} from '../../redux/reducer'
import { errorHandler, updateErrors } from '../errorState/errorState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { reducerGetSum } from '../../utils/reducerFunctions/reducerFunctions'
import { updateDocumentMeta } from '../l8LogbookDocumentState/l8LogbookDocumentState'
import sectionTypeLookup from './sectionTypeLookup/sectionTypeLookup'
import userForHistory from './userForHistory/userForHistory'
import { updateJob } from '../jobsState/jobsState'
import { getSiteRequest } from '../sitesState/sitesState'
import { getUserRequest, updateUserById } from '../userState/userState'
import { updateSideBarJobCounts } from '../jobsPageState/jobsPageState'

const debug = Debug('web:l8LogbookState:')

// --- Constants ---

export const riskPrefix = 'v1/risk'
export const riskConversion = {
  1: 'low',
  2: 'medium',
  3: 'high',
}

// --- Actions ---

const LOAD_LOGBOOK = 'LOAD_LOGBOOK'
const UPDATE_LOGBOOK_ENTITY_JOB_ID = 'UPDATE_LOGBOOK_ENTITY_JOB_ID'
const UPDATE_LOGBOOK_ENTITY_SUB_JOB_LIST = 'UPDATE_LOGBOOK_ENTITY_SUB_JOB_LIST'
const ADD_SUB_JOB = 'ADD_SUB_JOB'
const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS'
const RESET_UPLOAD_PROGRESS = 'RESET_UPLOAD_PROGRESS'
const LOAD_ENTITIES = 'LOAD_ENTITIES'
const UPDATE_ACTIVE_LOGBOOKS = 'UPDATE_ACTIVE_LOGBOOKS'
const UPDATE_JOB_USER_LIST = 'UPDATE_JOB_USER_LIST'
const UPDATE_LOGBOOK_SUMMARY_SETTINGS = 'UPDATE_LOGBOOK_SUMMARY_SETTINGS'

// --- Action creator ---
const loadLogbook = (siteId, logbookData = {}, yearRequested) => {
  const { year: logbookReturnYear, ...logbookToIterate } = logbookData
  const year = yearRequested || logbookReturnYear
  let logbook = {}
  Object.entries(logbookToIterate).forEach(timescale => {
    const [timeFrame, logEntries] = timescale
    if (timeFrame === 'summary') {
      logbook = {
        ...logbook,
        [timeFrame]: [
          ...Object.entries(logEntries).map(entry => {
            const [section, originalData] = entry
            let data = originalData
            switch (section) {
              case 'risks': {
                data = data.map(d => {
                  const { name, count } = d
                  return {
                    name: riskConversion[name],
                    count,
                  }
                })
                break
              }
              case 'users': {
                data = data.map(d => ({ count: d }))
                break
              }
              default:
            }

            return {
              title: sectionTitleLookup(section, true),
              data,
              section,
            }
          }),
          ...[
            {
              title: 'job completion',
              data: {
                percentage: logEntries.jobs_progress,
                numerator: logEntries.jobs_completed,
                denominator: logEntries.jobs_count,
              },
              section: 'job_completion',
              isProgress: true,
            },
            {
              title: 'task completion',
              data: {
                percentage: logEntries.tasks_progress,
                numerator: logEntries.tasks_completed,
                denominator: logEntries.tasks_count,
              },
              section: 'task_completion',
              isProgress: true,
            },
          ],
        ],
      }
    } else {
      logbook = {
        ...logbook,
        [timeFrame]: Object.entries(logEntries).map(entry => {
          const [section, metadata] = entry
          const { compliant, data } = metadata
          return {
            title: sectionTitleLookup(section),
            compliant,
            data,
            section,
          }
        }),
      }
    }
  })

  return {
    type: LOAD_LOGBOOK,
    payload: { [`${siteId}-${year}`]: logbook },
    reference: 'logbook',
  }
}

export const updateLogbookEntityJobId = (jobId_Ref, jobId) => ({
  type: UPDATE_LOGBOOK_ENTITY_JOB_ID,
  payload: { [jobId]: jobId_Ref },
  reference: 'jobs',
})
const updateLogbookEntitySubJobList = (subJobs, jobId) => ({
  type: UPDATE_LOGBOOK_ENTITY_SUB_JOB_LIST,
  payload: { [jobId]: subJobs },
  reference: 'subJobs',
})

export const addSubJob = (jobs, jobId) => ({
  type: ADD_SUB_JOB,
  payload: jobs,
  reference: jobId,
})

const updateUploadProgress = (
  fileNumber,
  uploadProgress,
  dropZoneRef = 'singleDropZone'
) => ({
  type: UPDATE_UPLOAD_PROGRESS,
  payload: { [fileNumber]: uploadProgress },
  reference: dropZoneRef,
})

const resetUploadProgress = (dropZoneRef = 'singleDropZone') => ({
  type: RESET_UPLOAD_PROGRESS,
  payload: { [dropZoneRef]: {} },
  reference: 'uploadProgress',
})

const loadEntities = entities => {
  const { sections = [] } = entities || {}
  const sectionsWithTitles = sections.map(section => {
    const { name, ...meta } = section
    return { ...meta, name, title: sectionTitleLookup(name) }
  })
  return {
    type: LOAD_ENTITIES,
    payload: { ...entities, sections: sectionsWithTitles },
    reference: 'entities',
  }
}

const updateActiveLogbooks = (siteId, isActive) => ({
  type: UPDATE_ACTIVE_LOGBOOKS,
  payload: { [siteId]: isActive },
  reference: 'activeLogbooks',
})

const updateJobUserList = (jobMongoId, userList) => ({
  type: UPDATE_JOB_USER_LIST,
  payload: { [jobMongoId]: userList },
  reference: 'userLists',
})

const updateLogbookSummarySettings = (summary = []) => {
  let payload = {}
  let currentSettings = {}
  const storedSettings = localStorage.logbookSettings
  if (storedSettings) {
    currentSettings = JSON.parse(storedSettings)
  }
  Object.keys({ ...currentSettings, ...summary }).forEach(i => {
    const storedValue = currentSettings[`logbook-${i}`] === true
    payload = {
      ...payload,
      [i]: storedValue,
    }
  })
  return {
    type: UPDATE_LOGBOOK_SUMMARY_SETTINGS,
    payload,
    reference: 'summarySettings',
  }
}

// --- helper functions ---

export const convertTimeFrameToNumber = timeFrame => {
  const timeFrames = {
    weekly: 1,
    monthly: 2,
    quarterly: 3,
    annually: 4,
  }
  return timeFrames[timeFrame]
}

export const jobIdCreator = ({ siteId, site_id, year, section, timeName }) =>
  `${siteId || site_id}-${year}-${section}-${timeName || 'annual'}`

// --- Get logbook ---

const getLogbookRequest = (siteId, year = 2019) =>
  api(`${riskPrefix}/sites/${siteId}/logbook/${year}`)

export const getLogbookApi = (siteId, year) => (dispatch, getState) => {
  const inStore = getL8LogbookState(getState())[`${siteId}-${year}`]
  const siteInStore = getSiteByIdState(getState())[siteId]
  return Promise.resolve()
    .then(() => (inStore ? null : dispatch(toggleLoader(true))))
    .then(() => siteInStore || getSiteRequest({ siteId }))
    .then(site =>
      site.logbook === 1
        ? Promise.resolve()
            .then(() => dispatch(updateActiveLogbooks(siteId, true)))
            .then(() => getLogbookRequest(siteId, year))
            .then(response => dispatch(loadLogbook(siteId, response)))
        : dispatch(updateActiveLogbooks(siteId, false))
    )
    .then(() => dispatch(updateLogbookSummarySettings()))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return inStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- get entities ---

const getEntitiesRequest = (version = 1) =>
  api(`${riskPrefix}/entities/${version}`)

export const getEntitiesApi = () => dispatch =>
  Promise.resolve()
    .then(() => getEntitiesRequest())
    .then(response => dispatch(loadEntities(response)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
    })

// --- get job ---

const getJobRequest = (siteId, params) =>
  api(`${riskPrefix}/sites/${siteId}/jobs`, { params })

export const postJobRequest = (siteId, data) =>
  api(`${riskPrefix}/sites/${siteId}/jobs`, { data, method: 'post' })

// -- get sub jobs --

export const getSubJobs = ({
  siteId: site_id,
  section,
  timeFrame,
  year,
  timeName,
  modalRef,
}) => dispatch => {
  const jobId = jobIdCreator({ site_id, section, year, timeName })
  Promise.resolve()
    .then(() => getJobRequest(site_id, { timeFrame, timeName, year, section }))
    .then(response => ({
      ...response,
      data: response.data.filter(j => j.parent !== true),
    }))
    .then(response => {
      debug(response, 'jobs')
      return response
    })
    .then(({ data }) => {
      // very important this is above updating the list
      data.forEach(j => dispatch(updateJob(j, j._id)))
      dispatch(updateLogbookEntitySubJobList(data.map(doc => doc._id), jobId))
    })
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err, modalRef))
    })
}

export const getJob = ({
  siteId: site_id,
  section,
  timeFrame,
  year,
  timeName,
  modalRef,
}) => dispatch => {
  const jobId = jobIdCreator({ site_id, section, year, timeName })
  return Promise.resolve()
    .then(() => getJobRequest(site_id, { name: jobId, parent: true }))
    .then(({ data }) => {
      debug(data)
      if (data.length > 0) {
        return data[0]
      }
      return postJobRequest(site_id, {
        type: sectionTypeLookup(section),
        name: jobId,
        metadata: {
          section,
          year,
          site_id,
          timeFrame,
          risk: 1,
          parent: true,
          timeName: `${timeName ||
            year}` /* timeName must be input as a string */,
        },
      })
    })
    .then(response => {
      debug(response)
      return response
    })
    .then(response => {
      dispatch(updateJob(response, response._id))
      dispatch(updateLogbookEntityJobId(response._id, jobId))
      dispatch(
        getSubJobs({
          siteId: site_id,
          section,
          timeFrame,
          timeName,
          modalRef,
          year,
        })
      )
      return response
    })

    .catch(err => {
      debug(err)
      dispatch(errorHandler(err, modalRef))
    })
}

// --- patch a job ---

export const patchJobRequest = (jobId, data) =>
  api(`${riskPrefix}/jobs/${jobId}`, { method: 'patch', data })

/**
 * For use when updating a job via the metadata object
 * Note: Input is destructured
 * @param {{}} destructured - {patch, jobMongoId,modalRef,setSubmitting}
 * @param {function} destructured.setSubmitting
 * @param {string} destructured.jobMongoId
 * @param {string} destructured.modalRef
 * @param {{}} destructured.patch containing the patch body
 */
export const patchJobMetadata = ({
  patch,
  jobMongoId,
  modalRef,
  setSubmitting,
}) => dispatch =>
  patchJobRequest(jobMongoId, {
    ...patch,
    metadata: patch.metadata,
  })
    .then(response => dispatch(updateJob(response, response._id)))
    .then(() => (setSubmitting ? setSubmitting(false) : null))
    .then(() => {
      // to update the side menu count
      const { status } = patch
      if (status) {
        dispatch(updateSideBarJobCounts())
      }
    })
    // returns true to allow the form to do a new action
    .then(() => true)
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an error updating the ${
            Object.keys(patch)[0]
          } for this task.`
        )
      )
      return setSubmitting ? setSubmitting(false) : null
    })

// --- upload a document ---

const postMaintenanceDoc = (siteId, data, onUploadProgress) =>
  api(`${riskPrefix}/sites/${siteId}/documents`, {
    method: 'post',
    data,
    onUploadProgress,
  })

export const attachDetachDocWithJob = (jobId, docId, toAttach) =>
  api(
    `${riskPrefix}/jobs/${jobId}/${toAttach ? 'attach' : 'detach'}/${docId}`,
    { method: 'patch' }
  )

// export const getJobByIdRequest = jobMongoId =>
//   api(`${riskPrefix}/jobs/${jobMongoId}`)

export const uploadMaintenanceDoc = ({
  siteId,
  files = [],
  jobMongoId,
  documentParent,
  modalRef,
  year,
  dropZoneRef,
  ...metadata
}) => dispatch => {
  debug(files)
  const fileCount = files.length
  const percentagePerFile = 1 / fileCount
  const user = dispatch(userForHistory())
  dispatch(updateUploadProgress('total', 5, dropZoneRef))
  return Promise.all(
    files.map((file, i) =>
      Promise.resolve()
        .then(() => {
          // must be FormData to upload the file
          const data = new FormData()
          data.append('file', file) // adds the file to FormData
          data.append('metadata', JSON.stringify({ ...metadata, user, year })) // adds the metadata

          /**
           * function called on the progress of the upload
           * @param {number} progressEvent the event value returned by axios
           */
          const onUploadProgress = progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            debug('percent Completed', percentCompleted)
            const percentageComplete =
              percentCompleted > 10 ? percentCompleted : 10

            // update the loader as a percentage of the total number of files being uploaded
            dispatch(
              updateUploadProgress(
                i,
                percentageComplete * (percentagePerFile / 2),
                dropZoneRef
              )
            )
          }
          // post the FormData
          return postMaintenanceDoc(siteId, data, onUploadProgress)
        })
        .then(doc =>
          jobMongoId
            ? attachDetachDocWithJob(jobMongoId, doc._id, true)
                /** This updates the job with the new document ID
                 * This could possibly be improved //todo: get request at end of promise chain if #1042 is closed
                 */
                .then(response => dispatch(updateJob(response, response._id)))
                .then(() => doc)
            : doc
        )
        .then(doc => {
          let document = doc
          if (doc.type.includes('image')) {
            const preview = URL.createObjectURL(file) || null
            document = { ...doc, preview }
          }
          dispatch(updateDocumentMeta(document, documentParent))
          dispatch(
            updateUploadProgress(i, 100 * percentagePerFile, dropZoneRef)
          )
          successToaster(`uploaded ${doc.name}`, `to your ${year} logbook`)
          return doc
        })
    )
  )

    .then(doc => {
      setTimeout(() => dispatch(resetUploadProgress(dropZoneRef)), 1200)
      return doc
    })
    .catch(err => {
      debug(err)
      dispatch(resetUploadProgress())
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue uploading your file, please try again later.`
        )
      )
    })
}

// --- Add a comment to a job ---

const addACommentRequest = (jobId, data) =>
  api(`${riskPrefix}/jobs/${jobId}/attach-comment`, { method: 'post', data })

export const addJobCommentApi = ({
  jobMongoId,
  setSubmitting,
  resetForm,
  comment,
  modalRef,
}) => dispatch =>
  addACommentRequest(jobMongoId, { body: comment })
    .then(response => dispatch(updateJob(response, response._id)))
    .then(() => resetForm())
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue submitting your comment, please try again later.`
        )
      )
    })
    .then(() => setTimeout(() => dispatch(updateErrors(modalRef, null)), 2500))

// -- Remove a comment --

const removeCommentRequest = (jobMongoId, commentId) =>
  api(`${riskPrefix}/jobs/${jobMongoId}/detach-comment/${commentId}`, {
    method: 'patch',
  })

export const removeJobCommentApi = ({
  jobMongoId,
  commentId,
  modalRef,
}) => dispatch =>
  removeCommentRequest(jobMongoId, commentId)
    .then(response => dispatch(updateJob(response, response._id)))
    .then(() => successToaster('deleted', 'your comment', 'error'))
    .then(() => dispatch(updateErrors(modalRef, null)))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue deleting your comment, please try again later.`
        )
      )
    })

// --- get Users to assign ---

export const getUsersToAssignToJob = ({ jobMongoId, site_id, modalRef }) => (
  dispatch,
  getState
) => {
  const inStore = getJobUserListsState(getState())[jobMongoId]
  Promise.resolve()
    .then(() =>
      inStore ? null : dispatch(toggleLoader(true, false, modalRef))
    )
    .then(() => getUserRequest({ params: { site_id } }))
    .then(({ data }) =>
      Promise.all(
        data.map(u => {
          dispatch(updateUserById(u.id, u))
          return u.id
        })
      )
    )
    .then(users => dispatch(updateJobUserList(jobMongoId, users)))
    .then(() => dispatch(toggleLoader(false, false, modalRef)))

    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue getting your users to assign, please try again later.`
        )
      )
    })
}

// --- Assign user ---
const assignRemoveUserToJob = (jobId, userId, isAssigned) =>
  api(`${riskPrefix}/jobs/${jobId}/users/${userId}`, {
    method: isAssigned ? 'post' : 'delete',
  })
export const assignRemoveUser = (
  jobMongoId,
  userId,
  isAssigned,
  modalRef
) => dispatch =>
  assignRemoveUserToJob(jobMongoId, userId, isAssigned)
    .then(response => dispatch(updateJob(response, response._id)))
    .then(() => isAssigned && dispatch(updateSideBarJobCounts()))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue ${
            isAssigned ? 'assigning the user to' : 'removing the user from'
          } this job, please try again later.`
        )
      )
    })

// --- Redux ---

const initialState = {
  logbook: {},
  jobs: {},
  subJobs: {},
  uploadProgress: { singleDropZone: {} },
  activeLogbooks: {},
  userLists: {},
  entities: {},
  summarySettings: {
    job_completion: true,
    task_completion: true,
    showSummary: false,
    // risks: true,
    // tasks_completed: true,
    // users_count: true,
    // jobs_by_status: true,
    // jobs_by_type: true,
    // job_comments_count: true,
    // jobs_completed: true,
    // jobs_count: true,
    // jobs_progress: true,
  },
}

const l8Logbook = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case LOAD_LOGBOOK:
    case UPDATE_LOGBOOK_ENTITY_JOB_ID:
    case UPDATE_LOGBOOK_ENTITY_SUB_JOB_LIST:
    case UPDATE_ACTIVE_LOGBOOKS:
    case UPDATE_JOB_USER_LIST:
    case UPDATE_LOGBOOK_SUMMARY_SETTINGS:
    case LOAD_ENTITIES:
    case RESET_UPLOAD_PROGRESS:
      return { ...state, [reference]: { ...state[reference], ...payload } }

    case ADD_SUB_JOB: {
      const alreadyPresentJobs = state.subJobs[reference] || []
      return {
        ...state,
        subJobs: {
          ...state.subJobs,
          [reference]: [...payload, ...alreadyPresentJobs],
        },
      }
    }

    case UPDATE_UPLOAD_PROGRESS: {
      const progressArray = Object.entries({
        ...state[reference],
        ...payload,
      })
        .filter(i => i[0] !== 'total')
        .map(progress => progress[1])

      const [incoming] = Object.values(payload)

      const total =
        progressArray.length > 0
          ? Math.round(progressArray.reduce(reducerGetSum))
          : incoming

      return {
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          [reference]: {
            ...state.uploadProgress[reference],
            ...payload,
            total,
          },
        },
      }
    }

    default:
      return state
  }
}

export const getLogbook = state => state.logbook
export const getLogbookEntityReferences = state => state.jobs
export const getLogbookSubJobReferences = state => state.subJobs
export const getUploadProgress = (state, dropZoneRef = 'singleDropZone') =>
  state.uploadProgress[dropZoneRef]
export const getActiveLogbooks = state => state.activeLogbooks
export const getUserJobLists = state => state.userLists
export const getLogbookSummarySettings = state => state.summarySettings
export const getLogbookSections = state => state.entities.sections

export default l8Logbook
