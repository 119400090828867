import React, { lazy } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Switch, Route } from 'react-router-dom'
import Header from '../../modules/Header/Header'
import {
  getSideMenuOpenState,
  getActionButtonModalRefState,
  getActionButtonFuncState,
} from '../../redux/reducer'
import SideMenu from '../../modules/SideMenu/SideMenu'
import { SIDE_MENU_BREAK_POINT } from '../../modules/SideMenu/SideMenuContainer/SideMenuContainer'
import Container from '../../components/Container/Container'
import PagePaddingBottom from '../../components/PagePaddingBottom/PagePaddingBottom'
import PrivateRoute from '../../modules/PrivateRoute/PrivateRoute'
import SuspenseLoader from '../../components/SuspenseLoader/SuspenseLoader'

export const Routes = lazy(() =>
  import(/* webpackChunkName: "Routes" */ '../../Routes/Routes')
)
const Gateways = lazy(() =>
  import(/* webpackChunkName: "Gateways" */ '../../Routes/Gateways/Gateways')
)

const FormBuilder = lazy(() =>
  import(
    /* webpackChunkName: "FormBuilder" */ '../../Routes/FormBuilderPage/FormBuilderPage'
  )
)

const HeaderAndContent = styled('div')`
  label: headerAndContent;
  transition: margin-left 0.4s linear;
  will-change: transition;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  @media (min-width: ${SIDE_MENU_BREAK_POINT}) {
    margin-left: 270px;
  }
`

const Page = styled('div')`
  label: page;
  display: flex;
  flex: 1 1 auto;
  overflow-y: scroll;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${props => props.theme.pagePaddingTop};
  background-color: ${props => props.theme.pageBackground};
`

const PageContainer = styled(Container)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
`

// todo: bundle split the account pages from the rest of the routes

const AuthenticatedRoutes = ({
  sideMenuOpen,
  actionButtonFunc,
  actionButtonModalRef,
}) => (
  <>
    <SideMenu openMenu={sideMenuOpen} />
    <HeaderAndContent>
      <Header
        actionButtonModalRef={actionButtonModalRef}
        actionButtonFunc={actionButtonFunc}
      />
      <Page data-cy="page">
        <PageContainer>
          <SuspenseLoader>
            <Switch>
              <Route exact path="/gateways" component={Gateways} />
              <Route
                exact
                path="/sites/:siteId/gateways"
                component={Gateways}
              />
              <Route exact path="/gateways/firmware" component={Gateways} />
              <Route
                exact
                path="/sites/:siteId/gateways/:gatewayId"
                component={Gateways}
              />
              <Route
                exact
                path="/sites/:siteId/sensor-management"
                component={Gateways}
              />
              <PrivateRoute
                exact
                path="/companies/:companyId/templates/create"
                permissionType="read"
                permissionName="admin"
                component={FormBuilder}
                componentProps={{
                  tMenuRef: 'companies',
                }}
              />
              <PrivateRoute
                exact
                path="/companies/:companyId/templates/create/:templateId"
                permissionType="read"
                permissionName="admin"
                component={FormBuilder}
                componentProps={{
                  tMenuRef: 'companies',
                }}
              />
              {/* <Route path="/account" component={AccountRoutes} /> */}
              <Route component={Routes} />
            </Switch>
          </SuspenseLoader>
          <PagePaddingBottom />
        </PageContainer>
      </Page>
    </HeaderAndContent>
  </>
)

AuthenticatedRoutes.propTypes = {
  sideMenuOpen: PropTypes.bool,
  actionButtonFunc: PropTypes.string,
  actionButtonModalRef: PropTypes.string,
}
AuthenticatedRoutes.defaultProps = {
  sideMenuOpen: false,
  actionButtonFunc: null,
  actionButtonModalRef: null,
}

const mapStateToProps = state => ({
  actionButtonModalRef: getActionButtonModalRefState(state),
  actionButtonFunc: getActionButtonFuncState(state),
  sideMenuOpen: getSideMenuOpenState(state),
})

export default connect(mapStateToProps)(AuthenticatedRoutes)
