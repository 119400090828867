/**
 * reads a file as a promise rather than event handler
 * https://blog.shovonhasan.com/using-promises-with-filereader/ for how to wrap the file reader as a promise
 * @param {file} file the file to be read
 * @param {boolean} readAsBuffer define if the file should be read as a buffer or text (default = as buffer)
 * @returns either a buffer array of the file or the file as text dependant upon the read as buffer input
 */
const readFileAsPromise = (file, readAsBuffer = true) => {
  const tempFileReader = new FileReader()
  return new Promise((resolve, reject) => {
    tempFileReader.onerror = () => {
      tempFileReader.abort()
      reject(new Error('problem parsing file'))
    }

    tempFileReader.onload = () => {
      resolve(tempFileReader.result)
    }
    if (readAsBuffer) {
      tempFileReader.readAsArrayBuffer(file)
    } else {
      tempFileReader.readAsText(file)
    }
  })
}

export default readFileAsPromise
