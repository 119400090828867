import Debug from 'debug'
import api from '../../api'
import breadcrumbObjectToArray from './breadcrumbObjectToArray/breadcrumbObjectToArray'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import {
  getAuthUserRoleState,
  getBreadcrumbsByIdState,
  getCurrentBreadcrumbIdState,
} from '../../redux/reducer'
import { errorHandler } from '../errorState/errorState'

const debug = Debug('web:breadcrumbState:')
// --- helper functions ---

const crumbIdentifier = (type, id) => `${type}-${id}`

// --- Actions ---

const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB'
const UPDATE_BREADCRUMB_LOADED = 'UPDATE_BREADCRUMB_LOADED'
const UPDATE_BREADCRUMB_REFERENCE = 'UPDATE_BREADCRUMB_REFERENCE'
const UPDATE_CURRENT_BREADCRUMB_ID = 'UPDATE_CURRENT_BREADCRUMB_ID'

// --- Action Creators ---

export const updateBreadcrumb = breadcrumbs => ({
  type: UPDATE_BREADCRUMB,
  payload: { breadcrumbs },
})

export const updateBreadcrumbLoaded = (breadcrumbLoaded = false) => ({
  type: UPDATE_BREADCRUMB_LOADED,
  payload: { breadcrumbLoaded },
})

export const updateBreadcrumbReference = (breadcrumbs, identifier) => ({
  type: UPDATE_BREADCRUMB_REFERENCE,
  payload: { [identifier]: breadcrumbs },
})

export const updateCurrentBreadcrumbId = breadcrumbId => ({
  type: UPDATE_CURRENT_BREADCRUMB_ID,
  payload: { breadcrumbId },
})

// --- get breadcrumbs ---

/**
 * requests the breadcrumb for a given type and id
 * @param {number | string} id
 * @param {string} type
 */
export const getOrgStructureRequest = (id, type) =>
  api(`org-structure/${id}`, { params: { type } })

const breadcrumbBuilder = (
  breadcrumbs = [],
  breadcrumbId,
  role,
  identifier,
  lastCrumb
) => (dispatch, getState) => {
  const currentBreadcrumbIdMatchesStore =
    breadcrumbId === getCurrentBreadcrumbIdState(getState())
  return Promise.resolve()
    .then(() => {
      const crumbsArray =
        breadcrumbs.length > 0
          ? breadcrumbs
          : breadcrumbObjectToArray(breadcrumbs, role)

      // dispatch without the lastCrumbs so they are only the api return and more re-usable
      dispatch(updateBreadcrumbReference(crumbsArray, identifier))

      if (typeof lastCrumb === 'string') {
        return [...crumbsArray, { title: lastCrumb }]
      }
      if (Array.isArray(lastCrumb)) {
        return [
          ...crumbsArray,
          ...lastCrumb.map(crumb => ({ title: crumb.title, to: crumb.to })),
        ]
      }
      return crumbsArray
    })
    .then(crumbsArray =>
      currentBreadcrumbIdMatchesStore
        ? null
        : dispatch(updateBreadcrumb(crumbsArray))
    )
    .then(() => dispatch(updateCurrentBreadcrumbId(breadcrumbId)))
    .then(() => dispatch(updateBreadcrumbLoaded(true)))
}

/**
 * sends the api request and dispatches the breadcrumbs update action creator
 * The request will only update if the breadcrumbId is not the same as in the store
 * If the breadcrumbId is in the store then the cached breadcrumb has already been shown
 * In which case it updates the reference in the background if there was a change
 * @param {number | string} id
 * @param {string} type
 */
export const getBreadcrumbsApi = (id, type, lastCrumb) => (
  dispatch,
  getState
) => {
  const identifier = crumbIdentifier(type, id)
  const role = getAuthUserRoleState(getState())
  const breadCrumbInStore = getBreadcrumbsByIdState(getState(), identifier)
  const preLoadedBreadcrumbs = typeof breadCrumbInStore !== 'undefined'
  const breadcrumbId = `${id}${type}${lastCrumb ? lastCrumb.toString() : ''}`

  return Promise.resolve()
    .then(() => {
      if (!preLoadedBreadcrumbs) {
        return dispatch(updateBreadcrumbLoaded(false))
      }
      return Promise.resolve().then(() =>
        dispatch(
          breadcrumbBuilder(
            [...breadCrumbInStore],
            breadcrumbId,
            role,
            identifier,
            lastCrumb
          )
        )
      )
    })
    .then(() =>
      typeof type === 'string' ? getOrgStructureRequest(id, type) : []
    )
    .then(response =>
      dispatch(
        breadcrumbBuilder(response, breadcrumbId, role, identifier, lastCrumb)
      )
    )

    .catch(err => {
      debug(err)
      dispatch(updateBreadcrumbLoaded(true))
      if (typeof breadCrumbInStore === 'undefined') {
        dispatch(toggleLoader(false, true))
      }
      dispatch(errorHandler(err))
    })
}

// --- Update Breadcrumbs ---
export const updateLastBreadcrumbTitleApi = (type, id, newTitle) => (
  dispatch,
  getState
) => {
  const identifier = `${type}-${id}`
  const currentBreadcrumbs = getBreadcrumbsByIdState(getState(), identifier)
  const crumbs = [...currentBreadcrumbs]
  const cut = crumbs.length - 1
  const lastCrumb = crumbs[cut]
  lastCrumb.title = newTitle

  dispatch(updateBreadcrumb(crumbs))
  dispatch(updateBreadcrumbReference(crumbs, identifier))
}
// --- Redux ---

const initialState = {
  breadcrumbs: [],
  breadcrumbLoaded: false,
  breadcrumbId: null,
}

const breadcrumbReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_BREADCRUMB:
    case UPDATE_BREADCRUMB_LOADED:
    case UPDATE_BREADCRUMB_REFERENCE:
    case UPDATE_CURRENT_BREADCRUMB_ID:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getBreadcrumbs = state => state.breadcrumbs
export const getBreadcrumbLoaded = state => state.breadcrumbLoaded
export const getBreadcrumbsById = (state, id) => state[id]
export const getCurrentBreadcrumbId = state => state.breadcrumbId

export default breadcrumbReducer
