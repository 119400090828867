import Debug from 'debug'
import { errorHandler } from '../errorState/errorState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import api from '../../api'

const debug = Debug('web:SupportState:')
// --- Actions ---

const UPDATE_SELECTED_SUPPORT_TOPICS = 'UPDATE_SELECTED_SUPPORT_TOPICS'
const RESET_TOPICS = 'RESET_TOPICS'

// --- Action Creators ---

export const updateSelectedSupportTopics = topic => ({
  type: UPDATE_SELECTED_SUPPORT_TOPICS,
  payload: topic,
  reference: 'topics',
})

const resetTopics = (topics = {}) => ({
  type: RESET_TOPICS,
  payload: { topics },
})

// --- Submit support ticket ---

const postSupportTicketRequest = data =>
  api('support', { method: 'post', data })

export const submitSupportTicket = (
  values,
  setSubmitting,
  formRef,
  resetForm
) => dispatch => {
  const { name, email, topics, content } = values
  debug(values)

  const categories = Object.entries(topics)
    .filter(topic => topic[1])
    .map(topic => topic[0])

  // todo: utilise the other support information
  const support = { name, email, categories, content }
  debug(support)
  return Promise.resolve()
    .then(() => postSupportTicketRequest(support))
    .then(() => {
      // clears down form
      resetForm({ name, email, content: '' })
      dispatch(resetTopics())
      successToaster(
        'submitted',
        'your support question and it will be looked at by a member of our team'
      )
    })
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          formRef,
          'There was an issue sending your support question please email support@l8log.com'
        )
      )
    })
}

// --- Redux ---

const {
  REACT_APP_API_URL,
  REACT_APP_GIT_SHA1,
  REACT_APP_GIT_BRANCH,
  REACT_APP_CI_WORKFLOW_ID,
  REACT_APP_CI_BUILD_NUMBER,
} = process.env

const initialState = {
  topics: {},
  buildInformation: {
    apiUrl: REACT_APP_API_URL,
    git: {
      buildCommit: REACT_APP_GIT_SHA1,
      branch: REACT_APP_GIT_BRANCH,
    },
    ci: {
      workflowId: REACT_APP_CI_WORKFLOW_ID,
      buildNo: REACT_APP_CI_BUILD_NUMBER,
    },
  },
}

const support = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case RESET_TOPICS:
      return { ...state, ...payload }
    case UPDATE_SELECTED_SUPPORT_TOPICS:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getSupportTopics = state => state.topics

export default support
