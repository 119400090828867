import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import L8LogLogo from '../../../components/L8LogLogo/L8LogLogo'
import Link from '../../../components/Link/Link'

const HomeLink = styled(Link)`
  justify-self: center;
`

const Logo = styled(L8LogLogo)`
  padding-top: 1rem;
  margin-right: 0;
  padding-bottom: -0.75rem;
  height: auto;
  max-height: 5.5rem;
  line-height: 5rem;
  max-width: 200px;
`

const SideMenuLogoContainer = ({ siteLogo }) => (
  <HomeLink to="/">
    <Logo siteLogo={siteLogo} inverted />
  </HomeLink>
)

SideMenuLogoContainer.propTypes = {
  siteLogo: PropTypes.string,
}
SideMenuLogoContainer.defaultProps = {
  siteLogo: null,
}

export default React.memo(SideMenuLogoContainer)
