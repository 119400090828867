import Debug from 'debug'
import qsStringify from 'qs/lib/stringify'
import moment from 'moment'
import {
  getOutletsState,
  getOutletByIdState,
  getLocationByIdState,
  getDRPEndDateState,
  getDRPStartDateState,
  getMatchParamsIdRefState,
  getMatchParamsState,
  getPipeTypesState,
  getTriggerTypesState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import {
  toggleModal,
  toggleAreYouSureButtonLoading,
} from '../modalState/modalState'
import {
  sendOutletReadingsRequestForGraph,
  getOutletRequest,
  getReadingByIdRequest,
} from './read/read'
import { errorHandler } from '../errorState/errorState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { SHOW_ALL } from '../../Routes/routesConstants'
import {
  getLocationRequest,
  updateLocationById,
} from '../locationsState/locationsState'
import api from '../../api'
import findAndUpdate from '../../utils/findAndUpdate/findAndUpdate'
import { updateLastBreadcrumbTitleApi } from '../breadcrumbState/breadcrumbState'
import {
  calculatePagination,
  nextPageForLists,
} from '../paginationState/paginationState'
import arrToObject from '../../utils/arrToObject/arrToObject'

// todo: move readings to a separate section in the state

// --- Constants ---

const debug = Debug('web:OutletDevice:')
const debugError = Debug('web:OutletDevice:ERROR:')
export const OUTLET_SELECTOR_LOADER_NAME = 'outletSelectors'

// --- Actions ---

const UPDATE_OUTLET_INFO = 'UPDATE_OUTLET_INFO'
const UPDATE_OUTLET_READINGS = 'UPDATE_OUTLET_READINGS'
const UPDATE_OUTLET_BY_ID = 'UPDATE_OUTLET_BY_ID'
const TOGGLE_READINGS_LOADER = 'TOGGLE_READINGS_LOADER'
const UPDATE_CURRENT_SENSOR_INFO_PAGE_REF =
  'UPDATE_CURRENT_SENSOR_INFO_PAGE_REF'
const TOGGLE_GRAPH_READINGS_LOADER = 'TOGGLE_GRAPH_READINGS_LOADER'
const UPDATE_CREATE_OUTLET_SELECTORS = 'UPDATE_CREATE_OUTLET_SELECTORS'
const NEXT_OUTLET_PAGE = 'NEXT_OUTLET_PAGE'
const BULK_UPDATE_OUTLET_BY_ID = 'BULK_UPDATE_OUTLET_BY_ID'
const BULK_UPDATE_STATS_BY_OUTLET_ID = 'BULK_UPDATE_STATS_BY_OUTLET_ID'

// --- Action Creators ---

/**
 * Outlet information Action creator
 * @param {[]} outlets
 * @param {string | number} id
 */
const updateOutletInfo = (outlets, id) => ({
  type: UPDATE_OUTLET_INFO,
  payload: { [id]: outlets },
  reference: 'outlets',
})

const nextOutletPage = (outlets, id) => ({
  type: NEXT_OUTLET_PAGE,
  payload: outlets,
  reference: id,
})

/**
 * Outlet Readings Action creator
 * @param {string | number} outletId
 * @param {[]} readings
 */
export const updateOutletReadings = (outletId, readings) => ({
  type: UPDATE_OUTLET_READINGS,
  payload: { outletId, readings },
})

/**
 * Outlet Action creator
 * @param {string | number} outletId
 * @param {object} data
 */
const updateOutletById = (info, outletId) => ({
  type: UPDATE_OUTLET_BY_ID,
  payload: { outletId, info },
})

const bulkUpdateOutletById = outlets => ({
  type: BULK_UPDATE_OUTLET_BY_ID,
  payload: outlets,
  reference: 'outletById',
})

/**
 * toggles the loader for the readings only
 * @param {boolean} loaderOn
 * @param {boolean | null} error
 */
const toggleReadingsLoader = (loaderOn, error = null) => ({
  type: TOGGLE_READINGS_LOADER,
  payload: { readingsLoader: { loaderOn, error } },
})

const bulkUpdateStatsByOutletId = (outlets, ref) => ({
  type: BULK_UPDATE_STATS_BY_OUTLET_ID,
  payload: { [ref]: outlets },
  reference: 'outletStats',
})

/**
 * references what the outlet or device the sensor info page is showing in redux for other functions to lookup against
 * @param {object} currentSensorInfoPageRef { type: 'device'|| 'outlet', id }
 * @exports
 */
export const updateCurrentSensorInfoPageRef = currentSensorInfoPageRef => ({
  type: UPDATE_CURRENT_SENSOR_INFO_PAGE_REF,
  payload: { currentSensorInfoPageRef },
})

const toggleGraphReadingsLoader = (loaderOn, error) => ({
  type: TOGGLE_GRAPH_READINGS_LOADER,
  payload: { graphReadingsLoader: { loaderOn, error } },
})

const updateCreateOutletSelectors = selectors => ({
  type: UPDATE_CREATE_OUTLET_SELECTORS,
  payload: { ...selectors },
})

// --- Create Outlet Selector Info ---

const getPipesRequest = () => api(`/pipes`)

const getTriggersRequest = () => api(`/triggers`)

export const getCreateOutletSelectorsApi = modalRef => (dispatch, getState) => {
  const pipesInStore = getPipeTypesState(getState())
  const triggersInStore = getTriggerTypesState(getState())
  const inStore = pipesInStore && triggersInStore
  if (!inStore) {
    dispatch(toggleLoader(true, false, OUTLET_SELECTOR_LOADER_NAME))
  }
  return Promise.all([getPipesRequest(), getTriggersRequest()])
    .then(([{ data: pipes }, { data: triggers }]) =>
      dispatch(updateCreateOutletSelectors({ pipes, triggers }))
    )
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue getting the pipe and trigger types, please refresh the page.'
        )
      )
    })
    .then(() =>
      !inStore
        ? dispatch(toggleLoader(false, false, OUTLET_SELECTOR_LOADER_NAME))
        : null
    )
}

// --- Create ---

const addNewOutletRequest = (data, locationId) =>
  api(`locations/${locationId}/outlets`, { method: 'post', data })

export const addNewOutletApi = (outlet, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const idRef = getMatchParamsIdRefState(getState())
  const matchParams = getMatchParamsState(getState())
  const locationId = matchParams[idRef]
  const { name } = outlet
  return Promise.resolve()
    .then(() => addNewOutletRequest(outlet, locationId))
    .then(response => {
      const current = getOutletsState(getState())[locationId]

      return [...current, response]
    })
    .then(outlets => dispatch(updateOutletInfo(outlets, locationId)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created the outlet:', name))

    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, 'There was an issue adding the new outlet.')
      )
    })
}

// --- Read ---

export const updateOutletByIdReturnIds = (
  arr,
  itemSelector = 'id'
) => dispatch => {
  dispatch(bulkUpdateOutletById(arrToObject(arr, itemSelector)))
  return arr.map(o => o.id)
}

const getStatsRequest = params =>
  api(`/outlets/stats`, {
    params,
    paramsSerializer: p => qsStringify(p, { arrayFormat: 'repeat' }),
  })

export const getOutletStatsApi = (params, ref, modalRef) => dispatch =>
  getStatsRequest(params)
    .then(({ data }) =>
      dispatch(bulkUpdateStatsByOutletId(arrToObject(data, 'outlet_id'), ref))
    )
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'Could not get compliancy information for your outlets.'
        )
      )
    })

/**
 * get outlets thunk
 * @param {string | number} locationId
 */
export const getOutletsApi = (
  pathname,
  locationId,
  params = {
    $limit: 20,
    compliance_months_back: 2,
  }
) => (dispatch, getState) => {
  const ref = locationId || SHOW_ALL
  const outletInStore = getOutletsState(getState())[ref]
  const parentInStore = getLocationByIdState(getState())[ref]

  return Promise.resolve()
    .then(() => (outletInStore ? null : dispatch(toggleLoader(true))))
    .then(() =>
      Promise.all([
        getOutletRequest({ parentId: locationId, params }).then(
          ({ data, ...pagination }) => {
            debug(data)
            dispatch(calculatePagination(pagination, pathname))

            dispatch(
              updateOutletInfo(dispatch(updateOutletByIdReturnIds(data)), ref)
            )
          }
        ),
        locationId
          ? getLocationRequest({ locationId }).then(response =>
              dispatch(updateLocationById(response, locationId))
            )
          : null,
      ])
    )
    .then(() => dispatch(toggleLoader(false)))
    .then(() => dispatch(toggleLoader(false, false, 'search')))
    .catch(err => {
      debugError(err)
      dispatch(errorHandler(err))
      if (locationId) {
        return outletInStore && parentInStore
          ? null
          : dispatch(toggleLoader(false, true))
      }
      return outletInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- next outlet page ---

export const nextOutletPageApi = (
  parentId,
  params,
  paginationRef
) => dispatch =>
  dispatch(
    nextPageForLists(
      parentId,
      params,
      paginationRef,
      getOutletRequest,
      (data, ref) => dispatch2 =>
        dispatch2(
          nextOutletPage(dispatch2(updateOutletByIdReturnIds(data)), ref)
        )
    )
  )

/**
 * thunk to get information on a single outlet
 * @param {string | number} outletId
 */
export const getOutletByIdApi = outletId => (dispatch, getState) => {
  const outletByIdInStore = getOutletByIdState(getState())[outletId]
  return Promise.resolve()
    .then(() => (outletByIdInStore ? null : dispatch(toggleLoader(true))))
    .then(() => getOutletRequest({ outletId }))
    .then(data => {
      debug(data)
      dispatch(updateOutletById(data, outletId))
    })
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debugError(err)
      dispatch(errorHandler(err))
      return outletByIdInStore ? null : dispatch(toggleLoader(false, true))
    })
}

/**
 * Thunk to get the readings for the graph and control the loaders
 * @param {string | number} outletId
 * @param {*} startDate
 * @param {*} endDate
 */
export const getGraphReadingsByOutletId = (outletId, startDate, endDate) => (
  dispatch,
  getState
) => {
  const outletByIdInStore = getOutletByIdState(getState())[outletId]
  const oldestFirstReadingsInStoreForOutlet =
    outletByIdInStore && outletByIdInStore.readingsOldestFirst
  return Promise.resolve()
    .then(() => {
      if (oldestFirstReadingsInStoreForOutlet) {
        dispatch(toggleGraphReadingsLoader(true))
        dispatch(toggleReadingsLoader(true))
      }
    })
    .then(() => sendOutletReadingsRequestForGraph(outletId, startDate, endDate))
    .then(({ data }) => dispatch(updateOutletReadings(outletId, data)))
    .then(() => dispatch(toggleGraphReadingsLoader(false)))
    .catch(err => {
      debugError(err)
      if (oldestFirstReadingsInStoreForOutlet) {
        dispatch(toggleGraphReadingsLoader(false, true))
        dispatch(toggleReadingsLoader(false, true))
      }
      return dispatch(errorHandler(err))
    })
}

// --- Update ---

const patchOutletRequest = (data, id) =>
  api(`outlets/${id}`, { method: 'patch', data })

export const updateOutletApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, parentId, ...newValues } = values
  const current = getOutletsState(getState())[parentId]
  const { name } = getOutletByIdState(getState())[id]
  const newName = values.name || name

  return Promise.resolve()
    .then(() => patchOutletRequest(newValues, id))
    .then(response =>
      dispatch(
        findAndUpdate(
          current,
          response,
          id,
          parentId,
          updateOutletById,
          updateOutletInfo
        )
      )
    )
    .then(() =>
      newName === name
        ? null
        : dispatch(updateLastBreadcrumbTitleApi('outlet', id, newName))
    )
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newName))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, `There was an issue updating ${name}.`)
      )
    })
}

// --- Delete ---

/**
 * Request to delete a reading by ID
 * Used to delete manual readings
 * @param {number} id the id of the reading to delete
 */
const deleteReadingRequest = id => api(`readings/${id}`, { method: 'delete' })

export const removeManualReading = ({ readingId, outletId, modalRef }) => (
  dispatch,
  getState
) => {
  const outlet = getOutletByIdState(getState())[outletId]
  const readingsArray = outlet.readings || []
  return Promise.resolve()
    .then(() => dispatch(toggleAreYouSureButtonLoading(true)))
    .then(() => deleteReadingRequest(readingId))
    .then(() => {
      const l = readingsArray.length
      for (let i = 0; i < l; i++) {
        if (readingsArray[i].id === readingId) {
          return i
        }
      }
      return null
    })
    .then(i =>
      dispatch(
        updateOutletReadings(outletId, [
          ...readingsArray.slice(0, i),
          ...readingsArray.slice(i + 1),
        ])
      )
    )
    .then(() => dispatch(toggleAreYouSureButtonLoading(false)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('deleted reading', `#${readingId}`, 'error'))
    .then(() => dispatch(getOutletByIdApi(outletId)))
    .catch(err => {
      debugError(err)
      dispatch(toggleAreYouSureButtonLoading(false))
      errorHandler(err, modalRef, `There was an issue deleting the reading.`)
    })
}

// --- Update Reading From Websocket ---

/**
 * updates an outlet or device page with a new reading when the websocket updates
 * @param {{deviceId,outletId,type,newReading}} object object of deviceId, outletId, type, new reading
 */
export const updateReadingsFromWebSocket = ({ outletId, wsReading }) => (
  dispatch,
  getState
) => {
  // gets the current readings in the state for the outlet or device
  const { readings = [], readingsOldestFirst = [] } =
    getOutletByIdState(getState())[outletId] || {}
  const timeNow = moment()
  const { message_type, id } = wsReading || {}
  return message_type === 3
    ? Promise.resolve()
        // takes the Id from the websocket info and sends a request to get the full reading
        .then(() => getReadingByIdRequest(id))
        .then(newReading => {
          /**
           *  checks that the manual reading is within the date range in the date
           *  range picker and therefore whether it should show on the graph
           */
          if (
            getDRPStartDateState(getState()) < timeNow &&
            getDRPEndDateState(getState()) > timeNow
          ) {
            const newReadingsOldestFirst = [...readingsOldestFirst, newReading]
            const newReadings = [newReading, ...readings]
            return dispatch(
              updateOutletReadings(
                outletId,
                newReadings,
                newReadingsOldestFirst
              )
            )
          }
          return newReading
        })
        .catch(err => {
          // this error is not important as the page still works so don't handle as normal
          debug(err)
        })
    : null
}

// --- Redux ---

export const initialState = {
  outlets: {},
  outletById: {},
  graphReadingsLoader: { loaderOn: true, error: null },
  readingsLoader: { loaderOn: true, error: null },
  deviceById: {},
  outletStats: {},
}

/**
 * default exported reducer
 * @param {object} state
 * @param {object} action
 */
const outletDevice = (state = initialState, action) => {
  const { type, payload, reference } = action

  switch (type) {
    case TOGGLE_READINGS_LOADER:
    case TOGGLE_GRAPH_READINGS_LOADER:
    case UPDATE_CURRENT_SENSOR_INFO_PAGE_REF:
    case UPDATE_CREATE_OUTLET_SELECTORS:
      return { ...state, ...payload }
    case UPDATE_OUTLET_INFO:
    case BULK_UPDATE_OUTLET_BY_ID:
    case BULK_UPDATE_STATS_BY_OUTLET_ID:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case UPDATE_OUTLET_BY_ID: {
      const { outletId, info } = payload
      return {
        ...state,
        outletById: {
          ...state.outletById,
          [outletId]: {
            ...state.outletById[outletId],
            ...info,
          },
        },
      }
    }
    case UPDATE_OUTLET_READINGS: {
      const { outletId, readings } = payload
      return {
        ...state,
        outletById: {
          ...state.outletById,
          [outletId]: {
            ...state.outletById[outletId],
            readings: readings || state.outletById[outletId].readings || [],
          },
        },
      }
    }

    case NEXT_OUTLET_PAGE: {
      const current = state.outlets[reference] || []
      return {
        ...state,
        outlets: { ...state.outlets, [reference]: [...current, ...payload] },
      }
    }

    default:
      return state
  }
}

/* 
Selectors go to combined reducer file for the actual usage.
As a rule I add state at the end of the selector to actually pull into components.
*/

export const getOutlets = state => state.outlets
export const getOutletById = state => state.outletById
export const getDeviceById = state => state.deviceById
export const getReadingsLoader = state => state.readingsLoader
export const getGraphReadingsLoader = state => state.graphReadingsLoader
export const getSensorInfoPageRef = state => state.currentSensorInfoPageRef
export const getPipeTypes = state => state.pipes
export const getTriggerTypes = state => state.triggers
export const getOutletStats = (state, ref) => state.outletStats[ref]

export default outletDevice
