import api from '../../../api'
// This file contains Read for Sites, locations, outlets devices and readings

/**
 * Note destructured input
 * if no ids gets all outlets
 * if a locationId is present it gets outlets for that location
 * if an outlet id is present it gets info on that outlet
 * API request to get the outlets for a locationId
 * @param {number | string} outletId (optional)
 * @param {number | string} parentId (optional)
 * @param {{}} params (optional)
 */
export const getOutletRequest = ({
  parentId: locationId,
  outletId,
  params: potentialParams = {
    $limit: 20,
    compliance_months_back: 2,
  },
}) => {
  let service = 'outlets'
  let params = potentialParams
  if (locationId) {
    service = `locations/${locationId}/outlets`
  }
  if (outletId) {
    service = `outlets/${outletId}`
    params = {}
  }
  return api(service, { params })
}

/**
 * API request to return the outlet readings by ID for a given time period
 * 1st Oct 2019 - now used for general reading requests as well
 * @param {string | number} outlet_id
 * @param {object} startDate date from moment
 * @param {object} endDate date from moment
 */
export const sendOutletReadingsRequestForGraph = (
  outlet_id,
  startDate,
  endDate
) =>
  api('readings', {
    params: {
      outlet_id,
      created_at: {
        $lt: endDate,
        $gt: startDate,
      },
      $sort: {
        created_at: -1,
      },
      $select: [
        'id',
        'created_at',
        'reported_by',
        'sensor_id',
        'inserted_at',
        'value',
        'compliant',
        'tolerance',
        'signal_strength',
      ],
    },
  })

/**
 * API request to get information on a single device
 * @param {string | number} deviceId
 */
export const sendDeviceByIdRequest = deviceId => api(`sensors/${deviceId}`)

/**
 * API request to get the readings for a device
 * @param {string | number} deviceId
 */
export const sendDeviceReadingsRequest = (sensor_id, limit = 15) =>
  api('readings', {
    params: {
      sensor_id,
      $limit: limit,
      $sort: { created_at: -1 },
    },
  })

/**
 * API request to return the device readings by ID for a given time period
 * @param {string | number} device_id
 * @param {object} startDate date from moment
 * @param {object} endDate date from moment
 */
export const sendDeviceReadingsRequestForGraph = (
  sensor_id,
  startDate,
  endDate
) =>
  api('readings', {
    params: {
      sensor_id,
      created_at: {
        $lt: endDate.toISOString(),
        $gt: startDate.toISOString(),
      },
      $limit: 100,
      $sort: {
        created_at: -1,
      },
      $select: ['value', 'created_at', 'id'],
    },
  })

/**
 * get a specific reading
 * @param {string|number} id the id of the reading you want to request
 */
export const getReadingByIdRequest = id => api(`readings/${id}`)
