import styled from '@emotion/styled'

const SideMenuSectionTitle = styled('div')`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  color: ${props => props.theme.sideMenuSectionTitle};
  line-height: 1.5;
  display: block;
  padding: 10px 30px;
`
export default SideMenuSectionTitle
