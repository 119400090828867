import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InputIcon from '../../../../components/form/InputIcon/InputIcon'
import {
  updateSearchInput,
  getSearchFromQS,
} from '../../../../reducers/searchState/searchState'

const SearchContainer = styled('div')`
  position: relative;
`

const SearchInput = ({
  className,
  sendSearchRequest,
  resetForm,
  values,
  location: { pathname, search: searchQS },
  resetSearchValue,
  debouncedSearch,
  setFieldValue,
}) => {
  // resets the search form on a path change
  useEffect(() => {
    resetForm()
    resetSearchValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  useEffect(() => {
    Promise.resolve()
      .then(() => getSearchFromQS(searchQS))
      .then(search => {
        if (search) {
          setFieldValue('search', `${search}`)
        }
      })
    // empty array means it only runs the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchContainer className={className}>
      <InputIcon
        type="search"
        name="search"
        placeholder="Search…"
        onKeyUp={e => {
          const { value } = e.target
          return value !== '' ? debouncedSearch(value) : debouncedSearch()
        }}
        icon={values.search ? 'far fa-times' : 'far fa-search'}
        data-cy="localPageSearchInput"
        iconEnd="true"
        iconClick={
          values.search
            ? () => {
                resetForm()
                sendSearchRequest()
              }
            : null
        }
        formikInput
      />
    </SearchContainer>
  )
}

SearchInput.propTypes = {
  className: PropTypes.string,
  sendSearchRequest: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string),
  location: PropTypes.objectOf(PropTypes.any),
  resetSearchValue: PropTypes.func.isRequired,
  debouncedSearch: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

SearchInput.defaultProps = {
  className: null,
  values: {},
  location: {},
}

const mapDispatchToProps = dispatch => ({
  resetSearchValue: () => dispatch(updateSearchInput()),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(React.memo(SearchInput))
)
