import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../../../components/Loader/Loader'
import UserRegistrationCard from './UserRegistrationCard/UserRegistrationCard'
import ErrorSlate from '../../../components/ErrorSlate/ErrorSlate'

const UserRegistrationStatus = ({
  tokenStatus,
  humanReadableRole,
  initialValues,
}) => {
  if (tokenStatus === 'error') {
    return (
      <ErrorSlate>
        There was an error with your token, it might have expired. Please check
        you entered it correctly in the URL bar.
      </ErrorSlate>
    )
  }
  if (tokenStatus === 'success') {
    return (
      <UserRegistrationCard
        humanReadableRole={humanReadableRole}
        initialValues={initialValues}
      />
    )
  }
  return <Loader />
}

UserRegistrationStatus.propTypes = {
  tokenStatus: PropTypes.string,
  humanReadableRole: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.node),
}

UserRegistrationStatus.defaultProps = {
  tokenStatus: 'loading',
  humanReadableRole: 'new user',
  initialValues: null,
}
export default UserRegistrationStatus
