import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SinglePage from '../SinglePage/SinglePage'
import { loader } from '../../css/animations'

const LoaderContainer = styled('div')`
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLoader = styled('div')`
  display: block;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  margin: auto;
  text-align: center;
  padding: 30px;
  color: ${props => props.theme.primary || 'inherit'};
  :before,
  :after {
    position: absolute;
    margin: -1.25rem 0 0 -1.25rem;
    content: '';
    top: 50%;
    left: 50%;
    width: 2.5rem;
    height: 2.5rem;
  }
  :before {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    border: 3px solid currentColor;
    opacity: 0.15;
  }
  :after {
    animation: ${loader} 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 50%;
    border: 3px solid;
    border-color: transparent;
    border-top-color: currentColor;
    box-shadow: 0 0 0 1px transparent;
  }
`

const Loader = ({ className, containerClassName }) => (
  <LoaderContainer className={containerClassName}>
    <StyledLoader className={className} />
  </LoaderContainer>
)

Loader.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
}
Loader.defaultProps = {
  className: null,
  containerClassName: null,
}

export class PageLoader extends React.Component {
  constructor() {
    super()
    this.state = { showLoader: false }
  }

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ showLoader: true }), 200)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { showLoader } = this.state
    const { className, style } = this.props
    return showLoader ? (
      <SinglePage className={className} style={style}>
        <Loader />
      </SinglePage>
    ) : null
  }
}

PageLoader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
}

PageLoader.defaultProps = {
  className: null,
  style: null,
}

export default Loader
