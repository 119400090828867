import styled from '@emotion/styled'
import PropTypes from 'prop-types'

export const SIDE_MENU_BREAK_POINT = '992px'

const SideMenuContainer = styled('div')`
  will-change: transform;
  background-color: ${props => props.theme.sideMenuBackground};
  /* border-right: 1px solid #eaedf3; */
  height: 100%;
  width: 270px;
  overflow-y: auto;
  z-index: 50;
  position: fixed;
  right: 100%;
  top: 0;
  bottom: 0;
  color: #fff;
  transform: ${props => (props.openMenu ? 'translateX(100%)' : null)};
  will-change: transform;
  transition: transform 0.4s linear;
  display: grid;
  grid-template-rows: ${props =>
    props.logoTop ? 'auto 1fr auto' : '1fr auto'};
  box-shadow: ${props =>
    props.openMenu
      ? `2px 0 3px rgba(0,0,0,.12), 2px 0 2px rgba(0,0,0,.24)`
      : null};

  @media (min-width: ${SIDE_MENU_BREAK_POINT}) {
    transform: translateX(100%);
    box-shadow: none;
    z-index: 1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`

SideMenuContainer.propTypes = {
  openMenu: PropTypes.bool,
}

SideMenuContainer.defaultProps = {
  openMenu: true,
}

export default SideMenuContainer
