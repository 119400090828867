import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'
import Icon from '../Icon/Icon'

const StyledCloseButton = styled(Icon)`
  padding: 16px;
  margin: -16px;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.danger};
  }
  :active {
    color: ${props => props.theme.danger};
  }
`

const CloseButton = ({ className, ...otherProps }) => (
  <ClassNames>
    {({ cx }) => (
      <StyledCloseButton
        className={cx('far fa-times', className)}
        {...otherProps}
      />
    )}
  </ClassNames>
)

CloseButton.propTypes = {
  className: PropTypes.string,
}

CloseButton.defaultProps = {
  className: null,
}
export default CloseButton
