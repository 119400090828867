const sectionTypeLookup = section => {
  switch (section) {
    case 'flushing':
      return 'flushing'

    case 'showerHead':
      return 'descaling'

    case 'riskAssessment':
    case 'healthSafetyChemicals':
    case 'waterTreatmentServiceRecords':
    case 'microBiologicalResults':
    case 'cleanAndDisinfection':
    case 'inspectionRecords':
    case 'equipmentServiceRecord':
      return 'report'

    case 'readings':
      return 'readings'

    case 'tmvMaintenance':
    default:
      return 'maintenance'
  }
}

export default sectionTypeLookup
