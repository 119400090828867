export const reducerGetSum = (accumulator, currentValue) =>
  accumulator + currentValue

/**
 * reduce an array into groups of a certain property
 * @param {[{}]} objectArray array of objects
 * @param {string} propertyToGroupBy string named field to group by
 * @param {*} groupBy is an empty object or an empty array and defines the output type of the grouping
 */
export const reducerGroupBy = (
  objectArray,
  propertyToGroupBy,
  subPropertyToGroupBy,
  groupBy = {}
) => {
  try {
    return objectArray.reduce((acc, obj) => {
      let key = obj[propertyToGroupBy]
      if (subPropertyToGroupBy) {
        key = key[subPropertyToGroupBy]
      }
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, groupBy)
  } catch (err) {
    return []
  }
}
