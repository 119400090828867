import React from 'react'
import PropTypes from 'prop-types'
import { ClassNames } from '@emotion/core'
import Card from '../../../../components/Card/Card'
import UserRegistrationForm from './UserRegistrationForm/UserRegistrationForm'

const UserRegistrationCard = ({ humanReadableRole, initialValues }) => (
  <ClassNames>
    {({ css }) => (
      <Card
        title={`Register as a ${humanReadableRole}`}
        cardBodyClassName={css`
          width: 400px;
          max-width: 90vw;
        `}
      >
        <UserRegistrationForm
          initialValues={initialValues}
          formRef="userRegistration"
          humanReadableUserRole={humanReadableRole}
        />
      </Card>
    )}
  </ClassNames>
)

UserRegistrationCard.propTypes = {
  humanReadableRole: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.node),
}

UserRegistrationCard.defaultProps = {
  humanReadableRole: 'new user',
  initialValues: null,
}
export default UserRegistrationCard
