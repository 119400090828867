import React from 'react'
import PropTypes from 'prop-types'
import Crumb from '../../Crumb/Crumb'

const MapCrumbs = ({ crumbs, numberOfCrumbs, crumbLengthToInclude }) =>
  crumbs.map((crumb, index) => {
    const { to, title } = crumb

    // if no crumbLengthToInclude provided just include all
    if (
      !crumbLengthToInclude ||
      index > numberOfCrumbs - crumbLengthToInclude
    ) {
      return (
        <Crumb
          key={`${title}${to}`}
          to={to}
          lastCrumb={index === numberOfCrumbs}
          tabIndex={0}
          zIndex={index + 2}
        >
          {title}
        </Crumb>
      )
    }
    return null
  })

MapCrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
  numberOfCrumbs: PropTypes.number,
  crumbLengthToInclude: PropTypes.number,
}

MapCrumbs.defaultProps = {
  crumbs: [],
  numberOfCrumbs: 0,
  crumbLengthToInclude: null,
}

export default MapCrumbs
