import moment from 'moment'

// --- process report into UI format ---

/**
 * converts the reports api response to a presentable array of report objects
 * @param {[{}]} info array of report objects from the reports api
 */
const reportApiToReducerReady = (info = []) =>
  info.map(report => {
    const {
      starting,
      ending,
      site_id,
      site_name,
      type,
      download_token,
      title: id,
    } = report || {}
    const startDay = moment.utc(starting)
    const endDay = moment.utc(ending)
    const frequency = type.replace(/-/g, ' ')
    return {
      title: `Week ${startDay.week()} report - ${endDay.year()}`,
      publicationDate: `${startDay.format('L')} - ${endDay.format(
        'L'
      )} (${frequency})`,
      pdf: download_token,
      id,
      siteId: site_id,
      siteName: site_name,
      ending,
      starting,
    }
  })

export default reportApiToReducerReady
