import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Link from '../../components/Link/Link'
import { PrimaryButton } from '../../components/Buttons/Buttons'
import MutedText from '../../components/Text/MutedText/MutedText'
import SinglePage from '../../components/SinglePage/SinglePage'

const Four04Grid = styled('div')`
  display: grid;
  left: 0;
  right: 0;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
  align-items: start;
  grid-row-gap: 16px;
  grid-template-areas:
    'Four04'
    'homeButton';
  padding: 0 16px 100px;
  align-self: center;
`

const Message404 = styled('div')`
  label: Four04;
  grid-area: Four04;
  text-align: center;
`
const GoHome = styled('div')`
  label: home;
  grid-area: homeButton;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-column-gap: 16px;
`

const Four04 = ({ location: { pathname } }) => (
  <SinglePage>
    <Four04Grid>
      <Message404>
        <h1>404</h1>
        <MutedText>
          {`The${
            pathname ? ` ${pathname}` : ''
          } page does not exist or you do not have the access
        rights to view it.`}
        </MutedText>
      </Message404>
      <GoHome>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <PrimaryButton>Go to Dashboard</PrimaryButton>
        </Link>
      </GoHome>
    </Four04Grid>
  </SinglePage>
)

Four04.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
}

Four04.defaultProps = {
  location: {},
}

export default Four04
