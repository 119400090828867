export default {
  primary: '#119AD4', // '#5A2167',
  oldPrimary: '#467fcf',
  primaryLight: '#7fa5db',
  primaryLightTransparent: 'rgba(127, 165, 219,0.7)',
  danger: '#C70A24',
  dangerLight: 'rgba(199, 10, 36,0.2)',
  success: '#5eba00',
  successLight: 'rgba(94, 186, 0,0.2)',
  warning: '#F0C52F',
  warningLight: '#f7e297',
  compliant: '#B2D235',
  nonCompliant: '#F8333C',
  noData: '#D8D8D8',
  cardHeader: '#fff',
  muted: '#9aa0ac',
  lightMuted: 'rgba(0, 40, 100, 0.18)',
  hoverLink: '#6e7687',
  headerBackground: '#fff',
  grayBackground: '#e9ecef',
  default: '#495047',
  coldWater: '#119AD4',
  hotWater: '#e74c3c',
  modalBackground: 'rgba(0,0,0,0.5)',
  tableHeader: '#edf2fa',
  sideMenuBackground: '#252529',
  sideMenuSectionTitle: '#9ea0a5',
  navLink: 'rgb(154, 160, 172)',
  borderColor: 'rgba(0,40,100,0.12)',
  pageBackground: 'rgba(0, 0, 0, 0.005)',
  tourAccent: 'orange',
  hover: {
    compliant: '#869E28',
    nonCompliant: '#C42323',
    primary: '#1186d4',
    danger: '#961A1E',
    success: '#488d03',
    warning: '#efac2e',
    table: 'rgba(0, 0, 0, 0.04)',
  },
  l8logStatic: {
    l8logPrimary: '#119AD4',
    l8logPrimaryHover: '#1186d4',
    l8logPrimaryLight: '#7fa5db',
    l8logPrimaryLightTransparent: 'rgba(127, 165, 219,0.7)',
  },
  graph: {
    compliant: '#B2D235',
    flushing: '#00BCFF',
    noData: '#EAEAEA',
    nonCompliant: '#F72E2E',
  },
  sensorInfoGraph: {
    lineColor: '#1F78B4',
    complianceLineColor: '#707070',
    chartBackground: '#EAEAEA',
    symbolSize: 4,
    tabletSymbolSize: 6,
    symbolBorder: 2,
    nonCompliantSymbolSize: 3,
  },
  logbook: {
    completed: '#467fcf',
    notCompleted: 'lightBlue',
    noData: 'rgba(0, 40, 100, 0.18)',
    current: '#fff',
    hover: {
      completed: 'steelblue',
      notCompleted: 'lightskyblue',
      noData: 'lightsteelblue',
      current: 'rgba(0, 40, 100, 0.18)',
    },
  },
  stats: {
    compliant: 'black',
    nonCompliant: 'white',
    noData: 'black',
  },
  iconSize: '20px',
  borderRadius: '3px',
  largeBorderRadius: '8px',
  successMsgDuration: 5000,
  pageMarginBottom: '1rem',
  pagePaddingTop: '10px',
  cardBoxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
}
