import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import MediaQueryCrumbs from './MediaQueryCrumbs/MediaQueryCrumbs'

const BreadCrumbsBase = ({ crumbs, className }) => (
  <div className={className}>
    <MediaQueryCrumbs crumbs={crumbs} />
  </div>
)

BreadCrumbsBase.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}

BreadCrumbsBase.defaultProps = {
  crumbs: [],
  className: null,
}

const BreadCrumbs = styled(BreadCrumbsBase)`
  grid-area: breadcrumbs;
  display: ${props =>
    !props.breadcrumbsLoaded || props.pageLoaderOn.loaderOn ? 'none' : null};
  z-index: 0;
`

export default BreadCrumbs
