import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'
import LineItemDropDown from '../../LineItemDropDown/LineItemDropDown'
import NavLinks from '../../Navigation/NavLinks/NavLinks'
import MobileDropDownItem from './MobileDropDownItem/MobileDropDownItem'
import { LargeDesktop } from '../../../components/Responsive/Responsive'
import TabFallBack from './TabFallBack/TabFallBack'
import NameWithCount from '../../../components/NameWithCount/NameWithCount'

const MobileDropDown = styled('div')`
  label: MobileDropDown;
  display: grid;
`

const DesktopLinkBar = styled('div')`
  label: DesktopLinkBar;
  align-self: end;
`

const DropDown = styled(LineItemDropDown)`
  label: DropDown;
  align-self: center;
  justify-self: center;
  width: 100%;
  display: grid;
`

const DropDownButton = ({ navLinks = [], pathname }) => {
  const linkCheck = navLinks.filter(i => i.link === pathname)

  if (linkCheck.length === 0) {
    return <TabFallBack />
  }
  return linkCheck.map(l => {
    const { link, name, count } = l
    return (
      <MobileDropDownItem key={link}>
        <NameWithCount name={name} count={count} />
      </MobileDropDownItem>
    )
  })
}

const TabBarSwitcher = ({ className, navLinks, pathname }) => (
  <LargeDesktop>
    {matches =>
      !matches ? (
        <MobileDropDown className={className}>
          <ClassNames>
            {({ css }) => (
              <DropDown
                closeOnMenuClick
                dropDownClass={css`
                  width: 100%;
                  cursor: pointer;
                  padding: 0;
                  left: 0;
                  right: auto;
                  margin-top: -5px;
                  :before,
                  :after {
                    content: none;
                  }
                  li:last-child {
                    border-bottom: none;
                  }
                `}
                dropDownButton={() =>
                  (
                    <DropDownButton navLinks={navLinks} pathname={pathname} />
                  ) || <TabFallBack />
                }
              >
                <NavLinks navLinks={navLinks} pathname={pathname} />
              </DropDown>
            )}
          </ClassNames>
        </MobileDropDown>
      ) : (
        <DesktopLinkBar className={className}>
          <NavLinks navLinks={navLinks} pathname={pathname} />
        </DesktopLinkBar>
      )
    }
  </LargeDesktop>
)

TabBarSwitcher.propTypes = {
  className: PropTypes.string,
  navLinks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
  pathname: PropTypes.string,
}

TabBarSwitcher.defaultProps = {
  className: null,
  navLinks: [],
  pathname: null,
}
export default TabBarSwitcher
