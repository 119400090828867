import React from 'react'
import ReactHelmet from 'react-helmet'
import capitaliseFirstLetter from '../../utils/capitaliseFirstLetter/capitaliseFirstLetter'
// import PropTypes from 'prop-types'

const Helmet = ({ title, ...props }) => (
  <ReactHelmet title={capitaliseFirstLetter(title)} {...props} />
)

Helmet.propTypes = {}

export default Helmet
