import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const CardBody = styled('div')`
  padding: ${props => props.cardBodyPadding || '1.5rem 1.5rem'};
  flex: 1 1 auto;
  margin: 0;
  position: relative;
  overflow-y: auto;
`

CardBody.propTypes = {
  children: PropTypes.node,
  cardBodyPadding: PropTypes.string,
  className: PropTypes.string,
}
CardBody.defaultProps = {
  children: null,
  cardBodyPadding: null,
  className: null,
}

export default CardBody
