import Debug from 'debug'
import api from '../../api'
import imageDownsampling from '../../utils/imageDownsampling'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import {
  getUserAccountHistoryState,
  getAuthUserIdState,
  getAuthState,
} from '../../redux/reducer'
import { errorHandler } from '../errorState/errorState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import { updateLoginNotice } from '../loginState/loginState'
import { updateUserById } from '../userState/userState'

const debug = Debug('web:userAccountState:')
const debugImg = Debug('web:userAccountState:Image:')

// --- Actions ---

const LOGIN_HISTORY_SUCCESS = 'LOGIN_HISTORY_SUCCESS'
const PROFILE_PHOTO_LOADING_PROGRESS = 'PROFILE_PHOTO_LOADING_PROGRESS'
export const UPDATE_AUTH_USER_PROFILE_PHOTO = 'UPDATE_AUTH_USER_PROFILE_PHOTO'
const UPDATE_EMAIL_STATUS = 'UPDATE_EMAIL_STATUS'

// --- Action Creators ---

const loginHistorySuccess = accountHistoryRecord => ({
  type: LOGIN_HISTORY_SUCCESS,
  payload: { accountHistoryRecord },
})

/**
 * dispatches an action for the profile photo loader progress
 * @param {number} profileLoadProgress number between 0 and 100
 */
const profilePhotoLoadingProgress = profilePhotoLoadProgress => ({
  type: PROFILE_PHOTO_LOADING_PROGRESS,
  payload: { profilePhotoLoadProgress },
})

const updateAuthUserProfilePhoto = profile_photo => ({
  type: UPDATE_AUTH_USER_PROFILE_PHOTO,
  payload: { profile_photo },
})

const updateEmailStatus = statusUpdateEmail => ({
  type: UPDATE_EMAIL_STATUS,
  payload: { statusUpdateEmail },
})

// --- Login History ---

const getLoginHistoryAPIRequest = userId => api(`users/${userId}/access`)

export const getLoginHistory = () => (dispatch, getState) => {
  const userId = getAuthUserIdState(getState())
  const loginHistoryRecordInStore =
    getUserAccountHistoryState(getState()).length > 0
  Promise.resolve()
    .then(() =>
      loginHistoryRecordInStore ? null : dispatch(toggleLoader(true))
    )
    .then(() => getLoginHistoryAPIRequest(userId))
    .then(({ data }) => {
      debug(data)
      return dispatch(loginHistorySuccess(data))
    })
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

// --- Profile Photo ---

/**
 * downscales to the maximum height or width whichever is the smaller
 * Uploads to the profile
 * @param {element} img an image element referenced by Id
 * @param {{}} file the uploaded file into the browser
 * @param {*} maxHeight max height of the output image
 * @param {*} maxWidth max width of the output image
 */
const downscaleAndUploadImg = (
  img,
  file,
  ref,
  maxHeight = 500,
  maxWidth = 500
) => (dispatch, getState) =>
  Promise.resolve()
    .then(() =>
      imageDownsampling({
        img,
        maxWidth,
        maxHeight,
      })
    )
    .then(downscaledImg => {
      dispatch(profilePhotoLoadingProgress(10))
      debugImg(downscaledImg)
      const formFile = downscaledImg || file
      const data = new FormData()
      data.append('uri', formFile)
      debug('submit photo now')

      const onUploadProgress = progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        debug('percent Completed', percentCompleted)
        const percentageComplete = percentCompleted > 10 ? percentCompleted : 10
        dispatch(profilePhotoLoadingProgress(percentageComplete))
      }

      return api(`uploads/profilePhoto`, {
        data,
        onUploadProgress,
        method: 'post',
      })
    })
    .then(({ url }) => {
      dispatch(updateAuthUserProfilePhoto(url))
      // clear down img
      img.remove()
      URL.revokeObjectURL(img.src)
      // set the loader to null to remove from screen
      dispatch(profilePhotoLoadingProgress(null))
      return url
    })
    .then(url => {
      const { user, ...authUser } = getAuthState(getState())
      dispatch(updateUserById(authUser.id, { ...authUser, profile_photo: url }))
    })
    .then(() => successToaster('changed', 'your profile photo'))
    .catch(err => {
      debug(err)
      return dispatch(
        errorHandler(
          err,
          ref,
          'There was an issue uploading your new profile photo'
        )
      )
    })

/**
 * thunk to process the image before sending to the saga
 * @param {blob} file the file uploaded
 */
export const submitUserProfilePhoto = (
  { file },
  ref = 'profilePhoto'
) => dispatch => {
  debug(file)
  dispatch(profilePhotoLoadingProgress(1))
  const img = document.createElement('IMG')
  debug(img)
  img.onload = () => {
    debug('img loaded in element')
    return dispatch(downscaleAndUploadImg(img, file, ref, 500, 500))
  }
  // Causes the onLoad function to be called
  img.src = URL.createObjectURL(file)
  debug(img)
}

// --- Update Email ---

const updateEmailAddressConfirmation = data =>
  api('tokens', { method: 'put', data })

export const updateEmailApi = token => dispatch =>
  Promise.resolve()
    .then(() => updateEmailAddressConfirmation(token))
    .then(({ value }) => {
      dispatch(
        updateLoginNotice(
          `Your email address has successfully updated to ${value}
          .Please log in to continue.`
        )
      )
      return dispatch(updateEmailStatus('success'))
    })

    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return dispatch(updateEmailStatus('error'))
    })

// --- Redux ---

const defaultState = {
  accountHistoryRecord: [],
  profilePhotoLoadProgress: null,
}

const userAccountReducer = (state = defaultState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROFILE_PHOTO_LOADING_PROGRESS:
    case LOGIN_HISTORY_SUCCESS:
    case UPDATE_EMAIL_STATUS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getProfilePhotoProgress = state => state.profilePhotoLoadProgress
export const getUserAccountHistory = state => state.accountHistoryRecord
export const getUpdateEmailStatus = state => state.statusUpdateEmail

export default userAccountReducer
