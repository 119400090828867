const tertiaryMenuBuilder = (tertiaryLinks = [], id, can) => {
  const links = tertiaryLinks.filter(tLink => {
    if (!tLink.permissionName) {
      return true
    }
    return (
      can[tLink.permissionType] &&
      can[tLink.permissionType].includes(tLink.permissionName)
    )
  })
  return links.map(tLink => {
    const linkObject = { ...tLink }
    linkObject.link = linkObject.link
      ? linkObject.link.replace(':id', id)
      : null
    linkObject.name = linkObject.name.replace(':id', id)
    return linkObject
  })
}

export default tertiaryMenuBuilder
