import Debug from 'debug'
import api from '../../api'
import { errorHandler } from '../errorState/errorState'
import { loginApi } from '../loginState/loginState'

const debug = Debug('web:userRegistrationState:')

// --- actions ---
const STATUS_USER_REG_TOKEN = 'STATUS_USER_REG_TOKEN'
const LOAD_INVITE_INFO = 'LOAD_INVITE_INFO'

// --- action creators ---

const statusCheckUserRegToken = tokenStatus => ({
  type: STATUS_USER_REG_TOKEN,
  payload: { tokenStatus },
})

const loadInviteInfo = inviteInfo => {
  const userInformation = {
    ...inviteInfo,
    humanReadableRole: inviteInfo.role.replace('-', ' '),
  }
  return {
    type: LOAD_INVITE_INFO,
    payload: { userInformation },
  }
}

// --- Check Token ---

/**
 * Request the invite information using the token
 * @param {string} token token provided by the api to register the user
 */
const getInviteUserRequest = token => api(`tokens/invite-user/${token}`)

/**
 * Uses the token to get information about the invite from the api
 * @param {string} token token provided by the api to register the user
 */
export const checkUserRegistrationToken = token => dispatch =>
  Promise.resolve()
    .then(() => dispatch(statusCheckUserRegToken('loading')))
    .then(() => getInviteUserRequest(token))
    .then(({ content, expires_at, key }) => {
      dispatch(loadInviteInfo({ ...content, token: key, expires_at }))
      return dispatch(statusCheckUserRegToken('success')) // must come after loading info in
    })
    .catch(err => {
      debug(err)
      return dispatch(statusCheckUserRegToken('error'))
    })

// --- Register New User ---

/**
 * Request to register the new user
 * @param {{}} user user object to create
 * @param {string} token token provided by the api to register the user
 */
const registerUserRequest = (user, token) =>
  api(`/tokens/invite-user/${token}`, { method: 'put', data: user })

/**
 * registers the user
 * @param {{}} values from Formik
 * @param {function} setSubmitting
 * @param {string} formRef
 */
export const registerUser = (
  push,
  { token, ...user },
  setSubmitting,
  formRef
) => dispatch =>
  Promise.resolve()
    .then(() => registerUserRequest(user, token)) // posts a new user
    .then(() => dispatch(loginApi(user, setSubmitting)))
    .then(() => push('/'))
    .catch(err => {
      debug(err)
      const { role } = user
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          formRef,
          `There was an issue registering you as a ${role.replace('-', ' ')}.`
        )
      )
    })

// --- Redux ---

const initialState = {}

const userRegistration = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case STATUS_USER_REG_TOKEN:
    case LOAD_INVITE_INFO:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getUserRegistrationInfo = state => state.userInformation
export const getUserRegistrationTokenStatus = state => state.tokenStatus

export default userRegistration
