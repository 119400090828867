/**
 * finds the index of the id
 * @param {[{}]} array
 * @param {number} l
 * @param {string} id
 */
export const checkForIndex = (array, l, id) => {
  for (let i = 0; i < l; i++) {
    if (array[i].id === id) {
      return i
    }
  }
  return null
}
