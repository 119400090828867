import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HeaderDropDownMenu from './HeaderDropDownMenu/HeaderDropDownMenu'
import UserObject from '../../UserObject/UserObject/UserObject'
import LineItemDropDown from '../../LineItemDropDown/LineItemDropDown'
import {
  getAuthUserFirstNameState,
  getAuthUserLastNameState,
  getAuthUserRoleState,
  getAuthUserProfilePhotoState,
} from '../../../redux/reducer'

const HeaderUser = ({
  first_name,
  last_name,
  role,
  profilePhoto,
  className,
}) => {
  const userName = `${first_name} ${last_name}`

  return (
    <LineItemDropDown
      className={className}
      closeOnMenuClick
      dropDownButton={() => (
        <UserObject
          profilePic={profilePhoto}
          userName={userName}
          userRole={role}
          cursor="pointer"
        />
      )}
    >
      <HeaderDropDownMenu />
    </LineItemDropDown>
  )
}

HeaderUser.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  role: PropTypes.string,
  profilePhoto: PropTypes.string,
  className: PropTypes.string,
}

HeaderUser.defaultProps = {
  first_name: null,
  last_name: null,
  role: null,
  profilePhoto: null,
  className: null,
}

const mapStateToProps = state => ({
  first_name: getAuthUserFirstNameState(state),
  last_name: getAuthUserLastNameState(state),
  role: getAuthUserRoleState(state),
  profilePhoto: getAuthUserProfilePhotoState(state),
})

export default connect(mapStateToProps)(React.memo(HeaderUser))
