import React from 'react'
import PropTypes from 'prop-types'
import Debug from 'debug'
import { captureException } from '@sentry/browser'
import SinglePage from '../SinglePage/SinglePage'

const debug = Debug('web:ErrorBoundary:')

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    debug(error, info)
    if (process.env.NODE_ENV !== 'development') {
      captureException(error, { extra: info })
    }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <SinglePage>
          <h2>Something went wrong. Please try refreshing your browser.</h2>
        </SinglePage>
      )
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
