/**
 * determines the status based on the message type
 * @param {string} type
 */
const statusType = type => {
  switch (type) {
    case 'sensorConfigGetRefresh':
      return 'sync'
    case 'sensorConfigSet':
    case 'sensorConfigGet':
    case 'gatewayConnectedToMqtt':
      return 'good'
    case 'gatewayMainsConnected':
      return 'powerOn'
    case 'reading':
      return 'reading'
    case 'gatewayFirmwareChangeReceived':
      return 'messageReceived'
    case 'gatewayFirmwareUpgradeSent':
      return 'messageSent'
    case 'comment':
      return 'comment'
    default:
      return 'warning'
  }
}

export default statusType
