import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import MutedText from '../Text/MutedText/MutedText'
import RequiredStar from './RequiredStar/RequiredStar'

const Message = styled(MutedText)`
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 87.5%;
  @media (min-width: 400px) {
    margin-bottom: -22px;
    text-align: right;
  }
`

const RequiredMessage = ({ className }) => (
  <Message className={className}>
    <RequiredStar>*</RequiredStar> Required Information
  </Message>
)

RequiredMessage.propTypes = {
  className: PropTypes.string,
}

RequiredMessage.defaultProps = {
  className: null,
}
export default RequiredMessage
