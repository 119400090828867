import Debug from 'debug'
import api from '../../api'
import { getContactsState } from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import {
  toggleModal,
  toggleAreYouSureButtonLoading,
} from '../modalState/modalState'
import { errorHandler } from '../errorState/errorState'
import { deleteRequest } from '../generalDelete/generalDelete'
import { checkForIndex } from '../../utils/checkForIndex/checkForIndex'
import { successToaster } from '../../modules/Toasters/ToasterMethods'

const debug = Debug('web:contactsState:')

// --- ACTIONS ---
const UPDATE_CONTACTS = 'UPDATE_CONTACTS'

// --- ACTION CREATORS ---

/**
 * updates the contacts by org type and id
 * @param {string} org one of 'company'|| 'client' || 'site'
 * @param {number | string} orgId the id of the org type
 * @param {[{}]} contacts array of contact objects
 */
const updateContacts = (org, orgId, contacts) => ({
  type: UPDATE_CONTACTS,
  payload: { [orgId]: contacts },
  reference: org,
})

// --- CREATE ---

const createContactRequest = (orgType, id, contact) =>
  api(`${orgType}/${id}/contacts`, { method: 'post', data: contact })

export const addNewContact = (values, setSubmitting, modalRef, id, idType) => (
  dispatch,
  getState
) => {
  const orgType = idType === 'company' ? 'companies' : `${idType}s`
  const { name } = values
  return Promise.resolve()
    .then(() => createContactRequest(orgType, id, values))
    .then(response => {
      const currentContacts = getContactsState(getState())[idType][id]
      return [...currentContacts, response]
    })
    .then(contacts => dispatch(updateContacts(idType, id, contacts)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('created a contact for', name))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue adding the new contact.'
        )
      )
    })
}

// --- READ ---

const getContactsRequest = (org_structure_id, type) =>
  api('contacts', {
    params: {
      org_structure_id,
      type,
      $limit: 100,
      $select: ['name', 'position', 'id', 'email', 'phone'],
    },
  })

export const getContactsAPI = (org, id) => (dispatch, getState) => {
  const contactsInStore = getContactsState(getState())[org][id]
  return Promise.resolve()
    .then(() => (contactsInStore ? null : dispatch(toggleLoader(true))))
    .then(() => getContactsRequest(id, org))
    .then(({ data }) => dispatch(updateContacts(org, id, data)))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return contactsInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- UPDATE ---

const patchContactsRequest = (contact, id) =>
  api(`contacts/${id}`, { data: contact })

export const updateContact = (
  values,
  setSubmitting,
  modalRef,
  orgId,
  orgType
) => (dispatch, getState) => {
  const { id, ...contact } = values
  const current = getContactsState(getState())[orgType][orgId]
  const i = checkForIndex(current, current ? current.length : 0, id)
  const { name } = current[i]
  const newName = values.name || name
  return Promise.resolve()
    .then(() => patchContactsRequest(contact, id)) // send patch request
    .then(updated => {
      if (typeof i !== 'number') {
        return null
      }
      return dispatch(
        updateContacts(orgType, orgId, [
          ...current.slice(0, i),
          updated,
          ...current.slice(i + 1),
        ])
      ) // update contact list
    })
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newName))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(err, modalRef, `There was an issue updating ${name}.`)
      )
    })
}

// --- DELETE ---

/**
 * deletes and removes the contact from the list
 * @param {number|string} id
 * @param {string} orgType
 * @param {number|string} orgId
 * @param {string} modalRef
 * @param {string} msg
 */
export const deleteContactApi = (id, orgType, orgId, modalRef, msg) => (
  dispatch,
  getState
) => {
  const currentContacts = getContactsState(getState())[orgType][orgId]
  return Promise.resolve()
    .then(() => dispatch(toggleAreYouSureButtonLoading(true)))
    .then(() => deleteRequest('contacts', id))
    .then(() => {
      const l = currentContacts.length
      for (let i = 0; i < l; i++) {
        if (currentContacts[i].id === id) {
          return i
        }
      }
      return null
    })
    .then(i =>
      dispatch(
        updateContacts(orgType, orgId, [
          ...currentContacts.slice(0, i),
          ...currentContacts.slice(i + 1),
        ])
      )
    )
    .then(() => dispatch(toggleAreYouSureButtonLoading(false)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .catch(err => {
      debug(err)
      dispatch(toggleAreYouSureButtonLoading(false))
      dispatch(
        errorHandler(err, modalRef, `There was an issue when trying to ${msg}.`)
      )
    })
}

// --- REDUX ---

const initialState = {
  company: {},
  client: {},
  site: {},
}

const contacts = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_CONTACTS:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getContacts = state => state

export default contacts
