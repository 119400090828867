const tertiaryLinks = {
  dashboard: [
    {
      name: 'Overview',
      link: '/',
    },
  ],
  users: [
    {
      name: 'All Users',
      link: '/users',
    },
  ],
  gateways: [
    {
      name: 'All Gateways',
      link: '/gateways',
    },
    {
      name: 'Firmware',
      link: '/gateways/firmware',
    },
  ],
  company: [
    {
      name: 'All Companies',
      link: '/companies',
    },
    {
      name: 'Admins',
      link: '/admins',
    },
    {
      name: 'Technicians',
      link: '/technicians',
    },
  ],

  companies: [
    {
      name: 'Clients',
      link: '/companies/:id',
    },
    {
      name: 'Contacts',
      link: '/companies/:id/contacts',
    },
    {
      name: 'Managers',
      link: '/companies/:id/managers',
    },
    {
      name: 'Engineers',
      link: '/companies/:id/engineers',
    },
    {
      name: 'Templates',
      link: '/companies/:id/templates',
      permissionName: 'admin',
      permissionType: 'read',
    },
  ],
  client: [
    {
      name: 'All Clients',
      link: '/clients',
    },
  ],
  clients: [
    {
      name: 'Sites',
      link: '/clients/:id',
    },
    {
      name: 'Contacts',
      link: '/clients/:id/contacts',
    },
    {
      name: 'Users',
      link: '/clients/:id/users',
    },
  ],
  site: [
    {
      name: 'All Sites',
      link: '/sites',
    },
  ],
  sites: [
    {
      name: 'Locations',
      link: '/sites/:id',
    },
    {
      name: 'Logbook',
      link: '/sites/:id/logbook',
    },
    {
      name: 'Reports',
      link: '/sites/:id/reports',
    },
    {
      name: 'Contacts',
      link: '/sites/:id/contacts',
    },
    {
      name: 'Site Managers',
      link: '/sites/:id/site-managers',
    },
    {
      name: 'Gateways',
      link: '/sites/:id/gateways',
      permissionName: 'admin',
      permissionType: 'read',
    },
    {
      name: 'Sensor Management',
      link: '/sites/:id/sensor-management',
      permissionName: 'gateways',
      permissionType: 'update',
    },
  ],
  location: [
    {
      name: 'All Locations',
      link: '/locations',
    },
  ],
  locations: [
    {
      name: 'Outlets',
      link: '/locations/:id',
    },
  ],
  outlet: [
    {
      name: 'All Outlets',
      link: '/outlets',
    },
  ],
  outlets: [
    {
      name: 'Outlet :id',
      link: '/outlets/:id',
    },
    // {
    //   name: 'All Readings',
    //   link: '/outlet/:id/readings',
    // },
  ],
  devices: [
    {
      name: 'Device :id',
      link: '/devices/:id',
    },
    // {
    //   name: 'All Readings',
    //   link: '/device/:id/readings',
    // },
  ],
  account: [
    {
      name: 'My Account',
      link: '/account',
    },
    {
      name: 'Account Security',
      link: '/account/security',
    },
    {
      name: 'Account History',
      link: '/account/history',
    },
  ],
  userManagement: [
    {
      name: 'All Users',
      link: '/users',
    },
    {
      name: 'User #:id',
      link: '/users/:id',
    },
  ],
  reports: [
    {
      name: 'All Reports',
      link: '/reports',
    },
  ],
  jobs: [
    {
      name: 'Upcoming jobs',
      link: '/jobs',
    },
    {
      name: 'Incomplete jobs',
      link: '/jobs/incomplete',
    },
    {
      name: 'Completed jobs',
      link: '/jobs/completed',
    },
  ],
  myJobs: [
    {
      name: 'Upcoming jobs',
      link: '/my-jobs',
    },
    {
      name: 'Incomplete jobs',
      link: '/my-jobs/incomplete',
    },
    {
      name: 'Completed jobs',
      link: '/my-jobs/completed',
    },
  ],
}
export default tertiaryLinks
