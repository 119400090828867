import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import reducer from './reducer'

export const history = createBrowserHistory()

const middleware = [thunkMiddleware]

let composeEnhancers = compose
if (
  process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...middleware)
  )(createStore)
  const store = createStoreWithMiddleware(reducer, initialState)
  return { store }
}
const { store } = configStore()
export default store
