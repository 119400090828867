import {
  getAuthUserFirstNameState,
  getAuthUserLastNameState,
  getAuthUserIdState,
} from '../../../redux/reducer'
// todo: remove if api applies user to document uploads
const userForHistory = () => (dispatch, getState) => ({
  userName: `${getAuthUserFirstNameState(
    getState()
  )} ${getAuthUserLastNameState(getState())}`,
  id: getAuthUserIdState(getState()),
})

export default userForHistory
