import styled from '@emotion/styled'

const Badge = styled('div')`
  display: inline-block;
  padding: 0.25rem 0.4rem;
  margin: 0.25rem auto;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  background-color: ${props => props.bgColor || props.theme.success};
  color: #fff;
`

export default Badge
