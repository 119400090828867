import styled from '@emotion/styled'

const DropDownIcon = styled('i')`
  font-size: 0.8rem;
  margin-right: 0.85rem;
  width: 1em;
  display: inline-block;
  text-align: center;
  color: ${props => props.theme.muted};
`

export default DropDownIcon
