import { checkForIndex } from '../checkForIndex/checkForIndex'

/**
 * Thunk to perform an update.
 * extracted due to it's replication across redux store items.
 * finds and updates a single item following the byId and byParentList structure.
 * @param {[]} current
 * @param {{}} updated
 * @param {string|number} id
 * @param {string|number} parentId
 * @param {func} updateById updates the specific reference to the object
 * @param {func} updateParentList updates the parent's list
 */
const findAndUpdate = (
  current,
  updated,
  id,
  parentId,
  updateById,
  updateParentList
) => dispatch => {
  if (updateById) {
    dispatch(updateById(updated, id)) // update locationById
  }
  if (updateParentList) {
    const i = checkForIndex(current, current ? current.length : 0, id)
    if (typeof i !== 'number') {
      return null
    }
    return dispatch(
      updateParentList(
        [...current.slice(0, i), updated, ...current.slice(i + 1)],
        parentId
      )
    ) // update any previous lists containing the location
  }
  return null
}

export default findAndUpdate
