import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import {
  getPermissionsState,
  getPageLoaderState,
  getSearchValueState,
  getBreadcrumbLoadedState,
  getBreadcrumbsState,
  getAuthUserRoleState,
  getErrorsState,
} from '../../redux/reducer'
import Four04 from '../../Routes/Four04/Four04'

const PrivateRoute = ({
  component: Component,
  componentProps,
  permissionType,
  permissionName,
  canVisit,
  allPageProps,
  four04,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      canVisit={canVisit}
      render={props =>
        !four04 &&
        canVisit &&
        permissionType &&
        permissionName &&
        canVisit[permissionType].includes(permissionName) ? (
          <Component {...props} {...componentProps} {...allPageProps} />
        ) : (
          <Four04 {...allPageProps} {...props} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  canVisit: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.node)),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  permissionType: PropTypes.string,
  permissionName: PropTypes.string,
  componentProps: PropTypes.objectOf(PropTypes.any),
  allPageProps: PropTypes.objectOf(PropTypes.any).isRequired,
  four04: PropTypes.bool,
}
PrivateRoute.defaultProps = {
  canVisit: null,
  permissionName: null,
  permissionType: null,
  componentProps: null,
  four04: null,
}
const mapStateToProps = (state, { location }) => {
  const { pathname } = location
  const canVisit = getPermissionsState(state)
  const { [pathname]: four04 } = getErrorsState(state).four04 || {}
  return {
    canVisit,
    four04,
    allPageProps: {
      canVisit,
      loader: getPageLoaderState(state),
      searchValue: getSearchValueState(state),
      breadcrumbLoaded: getBreadcrumbLoadedState(state),
      crumbs: getBreadcrumbsState(state),
      authUserRole: getAuthUserRoleState(state),
    },
  }
}

export default connect(mapStateToProps)(PrivateRoute)
