import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import { breakpoints } from '../../../../components/Responsive/Responsive'
import MapCrumbs from './MapCrumbs/MapCrumbs'
import Crumb from '../Crumb/Crumb'

const { desktop, tablet } = breakpoints

const MediaQueryCrumbs = ({ crumbs }) => {
  const numberOfCrumbs = crumbs.length - 1
  return (
    <>
      <MediaQuery minWidth={desktop + 201}>
        <MapCrumbs crumbs={crumbs} numberOfCrumbs={numberOfCrumbs} />
      </MediaQuery>
      <MediaQuery maxWidth={desktop + 200} minWidth={tablet}>
        {numberOfCrumbs > 3 && (
          <Crumb zIndex={1} style={{ cursor: 'auto' }}>
            ...
          </Crumb>
        )}
        <MapCrumbs
          crumbs={crumbs}
          numberOfCrumbs={numberOfCrumbs}
          crumbLengthToInclude={3}
        />
      </MediaQuery>
      <MediaQuery maxWidth={tablet - 1}>
        {numberOfCrumbs > 0 ? (
          <Crumb to={crumbs[numberOfCrumbs - 1].to} left>
            {crumbs[numberOfCrumbs - 1].title}
          </Crumb>
        ) : null}
      </MediaQuery>
    </>
  )
}

MediaQueryCrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
}

MediaQueryCrumbs.defaultProps = {
  crumbs: [],
}

export default MediaQueryCrumbs
