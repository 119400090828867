import styled from '@emotion/styled'

const DropDownItem = styled('div')`
  color: #6e7687;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.25rem 1.5rem 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  font-size: 1rem;
  margin-left: 2px;

  cursor: pointer;
  :hover {
    color: #16181b;
    text-decoration: none;
    margin-left: 0;
    border-left: 2px solid ${props => props.theme.primary};
    background-color: ${props => props.theme.hover.table};
  }
  @media (min-width: 992px) {
    font-size: 0.87rem;
  }
`

DropDownItem.propTypes = {}

export default DropDownItem
