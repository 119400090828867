import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { PageLoader } from '../Loader/Loader'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

const SuspenseLoader = ({ children, style, className }) => (
  <ErrorBoundary>
    <Suspense fallback={<PageLoader style={style} className={className} />}>
      {children}
    </Suspense>
  </ErrorBoundary>
)

SuspenseLoader.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.node),
  className: PropTypes.string,
}

SuspenseLoader.defaultProps = {
  style: null,
  className: null,
}

export default SuspenseLoader
