import React from 'react'
import PropTypes from 'prop-types'

const NameWithCount = ({ name, count }) => (
  <>
    {name}
    {typeof count === 'number' ? ` (${count.toLocaleString()})` : ''}
  </>
)

NameWithCount.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
}

NameWithCount.defaultProps = {
  name: null,
  count: null,
}

export default React.memo(NameWithCount)
