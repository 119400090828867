import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import Debug from 'debug'
import { loginWithTwoFactorAuth } from '../../../reducers/loginState/loginState'
import VerificationTokenFormComponent from './VerificationTokenFormComponent/VerificationTokenFormComponent'

const debugError = Debug('web:loginForm:Error')

const VerificationTokenForm = ({ initialValues, handleSubmit, reference }) => (
  <Formik
    initialValues={{
      ...{
        token: '',
      },
      ...initialValues,
    }}
    onSubmit={(values, { resetForm }) =>
      Promise.resolve()
        .then(() => handleSubmit(values, reference, resetForm))
        .catch(err => {
          debugError(err)
          resetForm()
        })
    }
    validationSchema={yup.object().shape({
      token: yup
        .string()
        .matches(/\d{6}/, 'Your authentication code must be 6 characters long.')
        .max(6, 'Your authentication code must be 6 characters long.')
        .required(
          'An authentication code is required to enable Two-Factor Authentication.'
        ),
    })}
    render={formikProps => (
      <VerificationTokenFormComponent {...formikProps} reference={reference} />
    )}
  />
)

VerificationTokenForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.node),
  handleSubmit: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
}
VerificationTokenForm.defaultProps = {
  initialValues: {},
}

const mapDispatchToProps = dispatch => ({
  handleSubmit: (values, reference, resetForm) =>
    dispatch(loginWithTwoFactorAuth(values, reference, resetForm)),
})

export default connect(
  null,
  mapDispatchToProps
)(VerificationTokenForm)
