import Debug from 'debug'
import api from '../../api'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import {
  getGatewaysBySiteState,
  getGatewayState,
  getSiteByIdState,
  getGatewayFirmwareState,
  getSensorManagementState,
  getBatchSwitchChangesState,
} from '../../redux/reducer'
import { errorHandler } from '../errorState/errorState'
import { getSiteRequest, updateSiteById } from '../sitesState/sitesState'
import { toggleModal } from '../modalState/modalState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import multiLogAccumulator from './multiLogAccumilator/multiLogAccumulator'
import { checkForIndex } from '../../utils/checkForIndex/checkForIndex'
import { updateSelectedSensor } from '../selectedState/selectedState'
import { getSensorApi } from '../sensorState/sensorState'

const debug = Debug('web:gatewaysState:')

// --- Constants ---

export const ALL_GATEWAYS = 'ALL_GATEWAYS'

// --- Actions ---

const UPDATE_GATEWAYS = 'UPDATE_GATEWAYS'
const UPDATE_GATEWAY_BY_ID = 'UPDATE_GATEWAY_BY_ID'
const LOAD_FIRMWARE_INFO = 'LOAD_FIRMWARE_INFO'
const LOAD_SENSOR_MANAGEMENT = 'LOAD_SENSOR_MANAGEMENT'
const STORE_BATCH_SWITCH_CHANGES = 'STORE_BATCH_SWITCH_CHANGES'
const LOAD_FIRMWARE_SELECTION = 'LOAD_FIRMWARE_SELECTION'
const GATEWAY_SYNC_STATUS = 'GATEWAY_SYNC_STATUS'

// --- Action Creators ---

const updateGateways = (gateways, siteId) => ({
  type: UPDATE_GATEWAYS,
  payload: { [siteId]: gateways },
  reference: 'gatewaysBySite',
})

const updateGatewayById = (gateway, gatewayId) => {
  const { logs: rawLogs = [], ...gatewayOtherInfo } = gateway || {}
  const logs = multiLogAccumulator(rawLogs)
  return {
    type: UPDATE_GATEWAY_BY_ID,
    payload: { [gatewayId]: { ...gatewayOtherInfo, logs, rawLogs } },
    reference: 'gatewaysById',
  }
}

const loadFirmwareInfo = firmware => ({
  type: LOAD_FIRMWARE_INFO,
  payload: { firmware },
})

const loadSensorManagement = (gateways, siteId) => ({
  type: LOAD_SENSOR_MANAGEMENT,
  payload: {
    sensorManagement: { [siteId]: gateways },
  },
})

export const storeBatchSwitchChanges = batchChanges => ({
  type: STORE_BATCH_SWITCH_CHANGES,
  payload: { batchChanges },
})

const loadFirmwareSelection = firmwareSelectOptions => ({
  type: LOAD_FIRMWARE_SELECTION,
  payload: { firmwareSelectOptions },
})

const gatewaySyncStatus = syncStatus => ({
  type: GATEWAY_SYNC_STATUS,
  payload: { syncStatus },
})

// --- Get gateways ---

const getGatewaysRequest = site_id => {
  const url =
    site_id !== ALL_GATEWAYS ? `sites/${site_id}/gateways` : `gateways`
  return api(url)
}

export const getGatewaysApi = siteId => (dispatch, getState) => {
  const gatewaysInStore = getGatewaysBySiteState(getState())[siteId]
  return Promise.resolve()
    .then(() => (gatewaysInStore ? null : dispatch(toggleLoader(true))))
    .then(() => getGatewaysRequest(siteId))
    .then(({ data }) => dispatch(updateGateways(data, siteId)))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return gatewaysInStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- Get Gateway ---

const getGatewayRequest = gatewayId => api(`gateways/${gatewayId}`)

const getGatewaySensorsRequest = gatewayId =>
  api(`gateways/${gatewayId}/sensors`)

export const getGatewayApi = (gatewayId, siteId) => (dispatch, getState) => {
  const gatewayInStore = getGatewayState(getState())[gatewayId]
  const parentInStore = getSiteByIdState(getState())[siteId]
  return Promise.resolve()
    .then(() =>
      gatewayInStore && parentInStore ? null : dispatch(toggleLoader(true))
    )
    .then(() => dispatch(gatewaySyncStatus(true))) /* for gateway sync button */

    .then(() =>
      Promise.all([
        Promise.all([
          getGatewayRequest(gatewayId),
          getGatewaySensorsRequest(gatewayId),
        ]).then(([gateway, { data: sensors }]) =>
          dispatch(updateGatewayById({ ...gateway, sensors }, gatewayId))
        ),
        getSiteRequest({ siteId }).then(response =>
          dispatch(updateSiteById(response, siteId))
        ),
      ])
    )
    .then(() =>
      dispatch(gatewaySyncStatus(false))
    ) /* for gateway sync button */
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return gatewayInStore && parentInStore
        ? null
        : dispatch(toggleLoader(false, true))
    })
}

// --- Get Firmware ---

const getGatewayFirmwareRequest = params =>
  api('gateways/firmwares', { params })

export const getGatewayFirmwareApi = () => (dispatch, getState) => {
  const inStore = getGatewayFirmwareState(getState())
  return Promise.resolve()
    .then(() => (inStore ? null : dispatch(toggleLoader(true))))
    .then(() => getGatewayFirmwareRequest())
    .then(({ data }) => dispatch(loadFirmwareInfo(data)))
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return inStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- load firmware for add and patch gateway ---

const getFirmwaresRequest = params => api('firmwares', { params })

export const getFirmwareSelectApi = modalRef => dispatch =>
  Promise.resolve()
    .then(() =>
      getFirmwaresRequest({
        $select: ['firmwares', 'product_id'],
      })
    )
    .then(({ data = [] }) => {
      const firmwareVersions = {}

      data.forEach(firmware => {
        firmwareVersions[firmware.product_id] = firmware.firmwares
          .map(fw => fw.version)
          .reverse()
      })
      dispatch(loadFirmwareSelection(firmwareVersions))
    })
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue loading the firmware selection list.'
        )
      )
    })

// --- Add new gateway ---

const postNewGatewayRequest = (siteId, data) =>
  api(`/sites/${siteId}/gateways`, { method: 'post', data })

export const AddNewGatewayApi = (values, setSubmitting, modalRef, siteId) => (
  dispatch,
  getState
) =>
  Promise.resolve()
    .then(() => postNewGatewayRequest(siteId, values))
    .then(response => {
      const currentGateways = getGatewaysBySiteState(getState())[siteId]
      return [...currentGateways, response]
    })
    .then(data => dispatch(updateGateways(data, siteId)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('added', `${values.name} to site ${siteId}.`))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      errorHandler(
        err,
        modalRef,
        `There was an issue adding the new gateway to the site.`
      )
    })

// --- patch gateway ---

const patchGatewayRequest = (id, data) =>
  api(`gateways/${id}`, { method: 'patch', data })

/**
 * update name only
 * @param {{}} values
 * @param {func} setSubmitting
 * @param {string} modalRef
 */
export const updateGatewayApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, parentId, numericId, ...newValues } = values
  const storedInParent = getGatewaysBySiteState(getState())[parentId] || []
  const currentGateway = getGatewayState(getState())[id] || {}
  return Promise.resolve()
    .then(() => patchGatewayRequest(id, newValues))
    .then(response => {
      const updatedGateway = { ...currentGateway, ...response }
      dispatch(updateGatewayById(updatedGateway, id))
      const i = checkForIndex(
        storedInParent,
        storedInParent ? storedInParent.length : 0,
        numericId
      )
      if (typeof i !== 'number') {
        // important if there is nothing stored in the parent array
        return null
      }
      return dispatch(
        updateGateways(
          [
            ...storedInParent.slice(0, i),
            updatedGateway,
            ...storedInParent.slice(i + 1),
          ],
          parentId
        )
      )
    })
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => successToaster('updated', newValues.name))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue updating ${newValues.name}.`
        )
      )
    })
}

// --- Sensor Switching Setup ---

export const getSensorSwitchingSetupApi = siteId => (dispatch, getState) => {
  const gatewayInStore = getSensorManagementState(getState())[siteId]
  const parentInStore = getSiteByIdState(getState())[siteId]
  const inStore = !!gatewayInStore && parentInStore
  Promise.resolve()
    .then(() => (inStore ? null : dispatch(toggleLoader(true))))
    .then(() =>
      Promise.all([
        getGatewaysRequest(siteId)
          .then(({ data }) =>
            Promise.all(
              data.map((gw, index) =>
                getGatewaySensorsRequest(gw.gateway_id).then(
                  ({ data: sensors = [] }) => ({
                    gateway: data[index],
                    sensors,
                  })
                )
              )
            )
          )
          .then(response => dispatch(loadSensorManagement(response, siteId))),
        getSiteRequest({ siteId }).then(response =>
          dispatch(updateSiteById(response, siteId))
        ),
      ])
    )
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      return inStore ? null : dispatch(toggleLoader(false, true))
    })
}

// --- Batch sensor switch ---

const patchBatchSensorSwitch = data =>
  api('gateways/sensors', { method: 'patch', data })

export const updateSensorGatewayConfigurationApi = (modalRef, siteId, data) => (
  dispatch,
  getState
) => {
  const patchData = data || getBatchSwitchChangesState(getState())
  return Promise.resolve()
    .then(() => patchBatchSensorSwitch(patchData))
    .then(response => {
      debug(response)
      return response
    })
    .then(() => dispatch(toggleModal(modalRef, false)))
    .then(() => dispatch(storeBatchSwitchChanges()))
    .then(() => successToaster('updated', `your gateway's sensor lists`))
    .then(() => dispatch(getSensorSwitchingSetupApi(siteId)))
    .catch(err => {
      debug(err)
      dispatch(
        errorHandler(
          err,
          modalRef,
          'There was an issue switching sensors between gateways. Your changes have not been saved.'
        )
      )
    })
}

// --- Add Gateway log comment ---

const postGatewayCommentRequest = (data, gatewayId) =>
  api(`gateways/${gatewayId}/comments`, { method: 'post', data })

export const addCommentApi = (values, setSubmitting, modalRef) => (
  dispatch,
  getState
) => {
  const { id, parentId, ...newValues } = values
  const current = getGatewayState(getState())[id]
  const { rawLogs: logs = [] } = current || {}

  return Promise.resolve()
    .then(() => postGatewayCommentRequest(newValues, id))
    .then(response => ({ ...current, logs: [response, ...logs] }))
    .then(updated => dispatch(updateGatewayById(updated, id)))
    .then(() => dispatch(toggleModal(modalRef, false)))
    .catch(err => {
      debug(err)
      setSubmitting(false)
      dispatch(
        errorHandler(
          err,
          modalRef,
          `There was an issue adding your comment to the gateway's logs.`
        )
      )
    })
}

// --- Show Sensor Management Modal ---

export const showSensorManagementModal = sensorId => dispatch => {
  dispatch(updateSelectedSensor(sensorId))
  dispatch(getSensorApi(sensorId))
  dispatch(toggleModal('sensorManagementInfo', true))
}

// --- Redux ---

const initialState = {
  gatewaysBySite: {},
  gatewaysById: {},
  sensorManagement: {},
}

const gateways = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case GATEWAY_SYNC_STATUS:
    case LOAD_FIRMWARE_INFO:
    case LOAD_SENSOR_MANAGEMENT:
    case STORE_BATCH_SWITCH_CHANGES:
    case LOAD_FIRMWARE_SELECTION:
      return { ...state, ...payload }
    case UPDATE_GATEWAYS:
    case UPDATE_GATEWAY_BY_ID:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    default:
      return state
  }
}

export const getGatewaysBySite = state => state.gatewaysBySite
export const getGateway = state => state.gatewaysById
export const getGatewayFirmware = state => state.firmware
export const getSensorManagement = state => state.sensorManagement
export const getBatchSwitchChanges = state => state.batchChanges
export const getFirmwareSelectOptions = state => state.firmwareSelectOptions
export const getGatewaySyncStatus = state => state.syncStatus

export default gateways
