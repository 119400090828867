import styled from '@emotion/styled'
import React from 'react'
import PropTypes from 'prop-types'
import BlankSlate from '../BlankSlate/BlankSlate'
import Icon from '../Icon/Icon'

const ErrorSlateBase = styled(BlankSlate)`
  background-color: #fceeec;
  color: ${props => props.theme.danger};
`

const ErrorSlate = ({ children }) => (
  <ErrorSlateBase>
    <Icon
      className="far fa-exclamation-circle"
      style={{ paddingRight: '16px' }}
    />
    {children}
  </ErrorSlateBase>
)

ErrorSlate.propTypes = {
  children: PropTypes.node,
}

ErrorSlate.defaultProps = {
  children: null,
}
export default ErrorSlate
