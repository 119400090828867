import moment from 'moment'

const TOGGLE_DATE_PICKER = 'TOGGLE_DATE_PICKER'
const UPDATE_START_DATE = 'UPDATE_START_DATE'
const UPDATE_END_DATE = 'UPDATE_END_DATE'

export const toggleDatePicker = showDatePicker => ({
  type: TOGGLE_DATE_PICKER,
  payload: { showDatePicker },
})

export const updateStartDate = startDate => ({
  type: UPDATE_START_DATE,
  payload: { startDate },
})

export const updateEndDate = endDate => ({
  type: UPDATE_END_DATE,
  payload: { endDate },
})

const initialState = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString(),
  showDatePicker: false,
}

const dateRangePickerReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_START_DATE:
    case UPDATE_END_DATE:
      return { ...state, ...payload }
    case TOGGLE_DATE_PICKER:
      if (payload.showDatePicker === false) {
        return initialState
      }
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getDRPStartDate = state => state.startDate
export const getDRPEndDate = state => state.endDate
export const getShowDatePicker = state => state.showDatePicker

export default dateRangePickerReducer
