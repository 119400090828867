import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import ProfilePhoto from '../../../components/ProfilePhoto/ProfilePhoto'

const UserObjectLayout = styled('div')`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: ${({ orientation }) => {
    let columns = ''
    if (orientation === 'flip') {
      columns = '1fr auto'
    } else if (orientation === 'stacked') {
      columns = '1fr'
    } else {
      columns = 'auto 1fr'
    }
    return columns
  }};
  grid-column-gap: ${({ noProfilePic }) => (noProfilePic ? null : '0.5rem')};
  grid-row-gap: ${({ orientation }) =>
    orientation === 'stacked' ? '0.2rem' : null};
  grid-template-areas: ${({ orientation }) => {
    if (orientation === 'flip') {
      return `'userName profilePic'`
    }
    if (orientation === 'stacked') {
      return `' profilePic''userName'`
    }
    return `'profilePic userName'`
  }};
  user-select: none;
  align-items: center;
  cursor: ${({ cursor }) => cursor};
`
const UserName = styled('div')`
  /* grid-area: userName; */
  font-weight: 400;
  color: ${({ theme, hoverText, isHovered }) => {
    const color = theme.default
    if (hoverText) {
      if (isHovered) {
        return color
      }
      return 'transparent'
    }
    return color
  }};
  line-height: 14px;
  font-size: 14px;
  margin-top: 4px;

  text-align: ${({ orientation }) => {
    if (orientation === 'flip') {
      return 'right'
    }
    if (orientation === 'stacked') {
      return `center`
    }
    return `left`
  }};
  @media (max-width: 576px) {
    display: ${({ textAlwaysOn }) => (textAlwaysOn ? null : 'none')};
  }
`

const Role = styled('div')`
  /* grid-area: userRole; */
  text-transform: capitalize;
  color: ${props => {
    const color = props.theme.muted
    if (props.hoverText) {
      if (props.isHovered) {
        return color
      }
      return 'transparent'
    }
    return color
  }};
  font-size: 13px;
  line-height: 13px;
  margin-top: 4px;
  align-self: center;
  text-align: ${props => {
    if (props.orientation === 'flip') {
      return 'right'
    }
    if (props.orientation === 'stacked') {
      return `center`
    }
    return `left`
  }};
  @media (max-width: 576px) {
    display: ${props => (props.textAlwaysOn ? null : 'none')};
  }
`

const UserObject = ({
  profilePic,
  userName,
  userRole,
  orientation,
  noProfilePic,
  hoverText,
  isHovered,
  minPageWidthForText,
  textAlwaysOn,
  fallbackIcon,
  fallbackIconSize,
  size,
  onClick,
  cursor,
  ...other
}) => (
  <UserObjectLayout
    orientation={orientation}
    {...other}
    onClick={onClick}
    cursor={cursor}
    noProfilePic={noProfilePic}
  >
    {!noProfilePic && (
      <ProfilePhoto
        profilePic={profilePic}
        fallbackIcon={fallbackIcon}
        size={size}
        fallbackIconSize={fallbackIconSize}
      />
    )}
    <div style={{ gridArea: 'userName' }}>
      {userName && (
        <UserName
          orientation={orientation}
          hoverText={hoverText}
          isHovered={isHovered}
          minPageWidthForText={minPageWidthForText}
          textAlwaysOn={textAlwaysOn}
        >
          {userName}
        </UserName>
      )}

      {userRole && (
        <Role
          orientation={orientation}
          hoverText={hoverText}
          isHovered={isHovered}
          minPageWidthForText={minPageWidthForText}
          textAlwaysOn={textAlwaysOn}
        >
          {userRole}
        </Role>
      )}
    </div>
  </UserObjectLayout>
)

UserObject.propTypes = {
  profilePic: PropTypes.string,
  userName: PropTypes.string,
  userRole: PropTypes.node,
  orientation: PropTypes.string,
  noProfilePic: PropTypes.bool,
  isHovered: PropTypes.bool,
  hoverText: PropTypes.bool,
  minPageWidthForText: PropTypes.number,
  textAlwaysOn: PropTypes.bool,
  fallbackIcon: PropTypes.string,
  size: PropTypes.number,
  fallbackIconSize: PropTypes.number,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
}

UserObject.defaultProps = {
  profilePic: null,
  userName: null,
  userRole: null,
  orientation: null,
  noProfilePic: false,
  isHovered: false,
  hoverText: false,
  minPageWidthForText: 500,
  textAlwaysOn: false,
  fallbackIcon: null,
  size: 32,
  fallbackIconSize: null,
  cursor: 'auto',
  onClick: null,
}

export default React.memo(UserObject)
