import Debug from 'debug'
import moment from 'moment'
import api from '../../api'
import { updateJob } from '../jobsState/jobsState'
import {
  getPrimaryLinksState,
  getAuthUserIdState,
  getAdminLinksState,
  getTertiaryLinksState,
  getMyJobsPageByReferenceState,
} from '../../redux/reducer'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { reducerGroupBy } from '../../utils/reducerFunctions/reducerFunctions'
import { getSingleSiteApi } from '../sitesState/sitesState'
import {
  updateNavigation,
  updateTertiaryNavigationLink,
} from '../navigationState/navigationState'
import {
  calculatePagination,
  nextPageForLists,
} from '../paginationState/paginationState'
import { SHOW_ALL } from '../../Routes/routesConstants'
import { errorHandler } from '../errorState/errorState'

const debug = Debug('web:jobsPageState:')

// --- Actions ---

const UPDATE_LIST_OF_MY_JOBS = 'UPDATE_LIST_OF_MY_JOBS'
const UPDATE_LIST_OF_MY_COMPLETED_JOBS = 'UPDATE_LIST_OF_MY_COMPLETED_JOBS'
const NEXT_COMPLETED_JOBS_PAGE = 'NEXT_COMPLETED_JOBS_PAGE'
const NEXT_MY_JOBS_PAGE = 'NEXT_MY_JOBS_PAGE'
const UPDATE_JOB_PAGE_VIEW = 'UPDATE_JOB_PAGE_VIEW'
const UPDATE_JOB_SITE_COUNTS = 'UPDATE_JOB_SITE_COUNTS'

// --- Action creators ---

/**
 * initial load for jobs page
 * @param {{}} futureOrPastObject
 * @param {string} reference
 */
const updateListOfMyJobs = futureOrPastObject => ({
  type: UPDATE_LIST_OF_MY_JOBS,
  payload: futureOrPastObject,
})

/**
 * initial load for completed jobs page
 * @param {[]} jobIds
 * @param {string} reference
 */
const updateListOfMyCompletedJobs = (jobIds, reference) => ({
  type: UPDATE_LIST_OF_MY_COMPLETED_JOBS,
  payload: { [reference]: jobIds },
})

const nextCompletedJobsPage = (jobIds, reference) => ({
  type: NEXT_COMPLETED_JOBS_PAGE,
  payload: jobIds,
  reference,
})

const nextMyJobsPage = (date, jobIds, reference, lastNewDate) => ({
  type: NEXT_MY_JOBS_PAGE,
  payload: { date, jobIds, lastNewDate },
  reference,
})

export const updateJobPageView = jobPageView => ({
  type: UPDATE_JOB_PAGE_VIEW,
  payload: { jobPageView },
})

const updateJobSiteCounts = (siteCounts, reference) => ({
  type: UPDATE_JOB_SITE_COUNTS,
  payload: { [reference]: siteCounts },
})

const nextJobsSiteCountPage = (siteCounts, reference) => ({
  type: NEXT_COMPLETED_JOBS_PAGE,
  payload: siteCounts,
  reference,
})

// --- helper functions ---

/**
 * to create references to the redux sections
 * @param {string} sectionReference the section of the jobs page
 * @param {number} userId (optional) the id of the user or null for show all
 */
export const jobsPageReference = (sectionReference, userId) =>
  `${userId || SHOW_ALL}-${sectionReference}`

// --- Thunks ---

// --- get my jobs ---

/**
 * /jobs request
 * @param {{}} params object of params for the request
 */
export const getJobsRequest = params => api(`/jobs`, { params })

/**
 * processes the raw jobs into a schedule format
 * @param {[]} data array of data from the api
 * @param {*} today date function for today
 * @param {*} monthLater date function for a month after today
 * @returns {{[],{}}} {site ids array, object of dates of array with jobId, day out of booking and total duration}
 */
const jobProcessingForSchedule = (
  data,
  today,
  monthLater,
  showFuture
) => dispatch => {
  let dateSets = {}
  const siteIds = data.map(j => {
    const { _id, site_id, work_start, work_end } = j
    dispatch(updateJob(j, _id))
    if (showFuture) {
      const workStart = moment.utc(work_start)

      const workEnd = moment.utc(work_end)
      const diff = workEnd.diff(workStart, 'days')
      let i
      for (i = 0; i <= diff; i++) {
        const momentDate = moment
          .utc(work_start)
          .endOf('day')
          .add(i, 'day')

        if (momentDate > today && momentDate < monthLater) {
          const date = momentDate.toISOString()
          const currentDate = dateSets[date] || []
          dateSets = {
            ...dateSets,
            [date]: [
              ...currentDate,
              /** [_id,index,diff] */
              [_id, i + 1, diff + 1],
            ],
          }
        }
      }
    } else {
      const date = moment
        .utc(work_end)
        .endOf('day')
        .toISOString()
      const currentDate = dateSets[date] || []

      dateSets = { ...dateSets, [date]: [...currentDate, [_id]] }
    }
    return { site_id }
  })
  return { siteIds, dateSets }
}

/**
 *
 * @param {{}} params current params
 * @param {bool} byUser if checking if a user has been assigned
 * @param {bool} showFuture if the function is upcoming jobs or past jobs
 * @param {*} today date function for today
 * @param {*} monthLater date function for a month later
 */
const jobRequestParams = (params, byUser, showFuture, today, monthLater) => (
  dispatch,
  getState
) => {
  let reqParams = params
  if (byUser) {
    const assigned_to = getAuthUserIdState(getState())
    reqParams = { ...reqParams, assigned_to: [assigned_to] }
  }
  if (showFuture) {
    reqParams = {
      ...reqParams,
      work_end: {
        $gte: today.toISOString(),
      },
      work_start: {
        $lte: monthLater.toISOString(),
      },
    }
  } else {
    reqParams = {
      ...reqParams,
      work_end: { $lte: moment.utc().toISOString() },
    }
  }
  return reqParams
}

/**
 * returns job dates used by myJobs functions
 * @returns {{}} returns object of {today, monthLater} date functions for destructuring
 */
const myJobDates = () => {
  const monthLater = moment
    .utc()
    .endOf('day')
    .add(1, 'month')
    .add(1, 'day')

  const today = moment.utc().startOf('day')
  return { today, monthLater }
}

/**
 * maps through siteIds to get and update redux store
 * refactored as used in multiple functions
 * @param {[]} sites array of sites
 */
const getAllSites = sites => dispatch =>
  Promise.all(sites.map(siteId => dispatch(getSingleSiteApi(siteId))))

export const getMyJobsApi = (params, byUser, showFuture) => (
  dispatch,
  getState
) => {
  const ref = jobsPageReference(
    showFuture ? 'future' : 'past',
    byUser ? getAuthUserIdState(getState()) : SHOW_ALL
  )

  const inStore = getMyJobsPageByReferenceState(getState(), ref)
  if (!inStore) {
    dispatch(toggleLoader(true))
  }
  const { monthLater, today } = myJobDates()
  const reqParams = dispatch(
    jobRequestParams(params, byUser, showFuture, today, monthLater)
  )

  return getJobsRequest(reqParams)
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination(pagination, ref))
      const { siteIds, dateSets } = dispatch(
        jobProcessingForSchedule(data, today, monthLater, showFuture)
      )
      const dataLength = data.length
      if (showFuture) {
        let lastNewDate
        if (dataLength > 0) {
          lastNewDate = moment
            .utc(data[dataLength - 1].work_start)
            .endOf('day')
            .toISOString()
        }
        const [first = {}] = data || []
        const { _id } = first
        dispatch(
          updateListOfMyJobs({
            [ref]: dateSets,
            [`${ref}-firstFutureJob`]: _id,
            [`${ref}-lastNewDate`]: lastNewDate,
          })
        )
      } else {
        dispatch(updateListOfMyJobs({ [ref]: dateSets }))
      }

      return Object.keys(reducerGroupBy(siteIds, 'site_id'))
    })
    .then(sites => dispatch(getAllSites(sites)))

    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

// -- next job page --

const nextPageThunk = (data, byUser, showFuture) => (dispatch, getState) => {
  const ref = jobsPageReference(
    showFuture ? 'future' : 'past',
    byUser ? getAuthUserIdState(getState()) : SHOW_ALL
  )
  const { today, monthLater } = myJobDates()
  const { dateSets } = dispatch(
    jobProcessingForSchedule(data, today, monthLater, showFuture)
  )
  const sites = Object.keys(reducerGroupBy(data, 'site_id'))
  return Promise.resolve()
    .then(() => dispatch(getAllSites(sites)))
    .then(() => {
      if (showFuture) {
        return moment
          .utc(data[data.length - 1].work_start)
          .endOf('day')
          .toISOString()
      }
      return null
    })
    .then(lastNewDate =>
      Promise.all(
        Object.entries(dateSets).map(ds => {
          const [date, jobs] = ds

          return dispatch(nextMyJobsPage(date, jobs, ref, lastNewDate))
        })
      )
    )
}

export const getNextJobPageApi = (
  params,
  paginationRef,
  byUser,
  showFuture
) => dispatch =>
  dispatch(
    nextPageForLists(
      null,
      params,
      paginationRef,
      ({ params: p }) => {
        const { today, monthLater } = myJobDates()
        return getJobsRequest(
          dispatch(jobRequestParams(p, byUser, showFuture, today, monthLater))
        )
      },
      data => d => d(nextPageThunk(data, byUser, showFuture))
    )
  )

// --- my jobs count ---

const getMyJobsCountApi = (params, linkUrl, navType, byUser, tMenuRef) => (
  dispatch,
  getState
) => {
  const userId = getAuthUserIdState(getState())
  const reqParams = byUser ? { ...params, assigned_to: [userId] } : params
  return getJobsRequest({
    is_parent: false,
    count_only: true,
    ...reqParams,
  }) // note adding a limit actually slowed down the request
    .then(({ available }) => {
      let links
      switch (navType) {
        case 'primary':
          links = getPrimaryLinksState(getState())
          break
        case 'admin':
          links = getAdminLinksState(getState())
          break
        case 'tertiary':
          links = getTertiaryLinksState(getState())[tMenuRef]
          break
        default:
      }
      const updatedLinks = links.map(l => {
        const { link } = l
        let newLink = l
        if (link === linkUrl) {
          newLink = { ...l, count: available }
        }
        return newLink
      })
      if (navType === 'tertiary') {
        dispatch(updateListOfMyJobs({ [linkUrl]: available }))
        return dispatch(updateTertiaryNavigationLink(updatedLinks, tMenuRef))
      }
      return dispatch(updateNavigation(navType, updatedLinks))
    })
}

// -- completed jobs --

const jobUpdateAndIdExtraction = data => dispatch =>
  data.map(j => {
    const { _id } = j
    dispatch(updateJob(j, _id))
    return _id
  })

export const getCompletedJobsApi = (params, byUser) => (dispatch, getState) => {
  const assigned_to = byUser ? getAuthUserIdState(getState()) : SHOW_ALL
  const ref = jobsPageReference('completed', assigned_to)
  const inStore = getMyJobsPageByReferenceState(getState(), ref)

  if (!inStore) {
    dispatch(toggleLoader(true))
  }
  const reqParams = byUser ? { ...params, assigned_to: [assigned_to] } : params
  return getJobsRequest(reqParams)
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination(pagination, ref))
      const jobIds = dispatch(jobUpdateAndIdExtraction(data))
      dispatch(updateListOfMyCompletedJobs(jobIds, ref))
      return Object.keys(reducerGroupBy(data, 'site_id'))
    })
    .then(sites =>
      Promise.all(sites.map(siteId => dispatch(getSingleSiteApi(siteId))))
    )

    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err))
      dispatch(toggleLoader(false, true))
    })
}

// -- next completed jobs page --

const nextPageReturnThunk = (data, ref) => dispatch => {
  const sites = Object.keys(reducerGroupBy(data, 'site_id'))
  return Promise.resolve()
    .then(() => dispatch(getAllSites(sites)))
    .then(() =>
      dispatch(
        nextCompletedJobsPage(dispatch(jobUpdateAndIdExtraction(data)), ref)
      )
    )
}

export const getNextCompletedJobPageApi = (params, paginationRef, byUser) => (
  dispatch,
  getState
) => {
  const assigned_to = byUser ? getAuthUserIdState(getState()) : SHOW_ALL
  dispatch(
    nextPageForLists(
      null, // no parentId for companies
      params,
      paginationRef,
      ({ params: p }) => {
        let reqParams = p
        if (byUser) {
          reqParams = { ...p, assigned_to: [assigned_to] }
        }
        return getJobsRequest(reqParams)
      },
      data => d => d(nextPageReturnThunk(data, paginationRef))
    )
  )
}

// --- update job side bar counts thunk ---

export const updateSideBarJobCounts = () => dispatch => {
  const { monthLater } = myJobDates()
  const params = {
    is_complete: false,
    work_start: { $lte: monthLater.toISOString() },
  }
  /** for my jobs upcoming and incomplete */
  dispatch(getMyJobsCountApi(params, '/my-jobs', 'primary', true))
  /** for all jobs upcoming and incomplete */
  dispatch(getMyJobsCountApi(params, '/jobs', 'admin', false))
}

// --- update job tab counts ---

export const updateJobTabCountsApi = (byUser, tMenuRef, path) => dispatch => {
  const { today, monthLater } = myJobDates()
  return Promise.resolve()
    .then(() =>
      dispatch(
        getMyJobsCountApi(
          {
            is_complete: false,
            work_end: {
              $gte: today.toISOString(),
            },
            work_start: {
              $lte: monthLater.toISOString(),
            },
          },
          path,
          'tertiary',
          byUser,
          tMenuRef
        )
      )
    )
    .then(() =>
      dispatch(
        getMyJobsCountApi(
          {
            is_complete: false,
            work_end: { $lte: moment.utc().toISOString() },
          },
          `${path}/incomplete`,
          'tertiary',
          byUser,
          tMenuRef
        )
      )
    )
    .then(() =>
      dispatch(
        getMyJobsCountApi(
          { is_complete: true },
          `${path}/completed`,
          'tertiary',
          byUser,
          tMenuRef
        )
      )
    )
    .catch(err => {
      debug(err, 'ERROR')
    })
}

// --- Job site counts ---

export const getJobSiteCountsApi = (params, byUser, showFuture) => (
  dispatch,
  getState
) => {
  const reference = jobsPageReference(
    showFuture ? 'future-sites' : 'past-sites',
    byUser ? getAuthUserIdState(getState()) : SHOW_ALL
  )
  const inStore = getMyJobsPageByReferenceState(getState(), reference)
  if (!inStore) {
    dispatch(toggleLoader(true))
  }
  const { monthLater, today } = myJobDates()
  const reqParams = dispatch(
    jobRequestParams(params, byUser, showFuture, today, monthLater)
  )
  return getJobsRequest(reqParams)
    .then(({ data, ...pagination }) => {
      debug(data)
      dispatch(calculatePagination(pagination, reference))
      dispatch(nextJobsSiteCountPage(data, reference))
      dispatch(updateJobSiteCounts(data, reference))
      return Object.keys(reducerGroupBy(data, 'site_id'))
    })
    .then(sites =>
      Promise.all(sites.map(siteId => dispatch(getSingleSiteApi(siteId))))
    )
    .then(() => dispatch(toggleLoader(false)))
    .catch(err => {
      debug(err)
      dispatch(toggleLoader(false, true))
      return dispatch(errorHandler(err))
    })
}

const nextJobSiteCountPageThunk = (data, ref) => dispatch => {
  const sites = Object.keys(reducerGroupBy(data, 'site_id'))
  return Promise.resolve()
    .then(() => dispatch(getAllSites(sites)))
    .then(() => dispatch(nextJobsSiteCountPage(data, ref)))
}

export const getNextSiteCountApi = (
  params,
  paginationRef,
  byUser,
  showFuture
) => dispatch => {
  const { today, monthLater } = myJobDates()
  dispatch(
    nextPageForLists(
      null, // no parentId for companies
      dispatch(jobRequestParams(params, byUser, showFuture, today, monthLater)),
      paginationRef,
      ({ params: p }) => getJobsRequest(p),
      data => d => d(nextJobSiteCountPageThunk(data, paginationRef))
    )
  )
}

// --- Redux ---

const initialState = {}

const jobPageReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_JOB_PAGE_VIEW:
    case UPDATE_LIST_OF_MY_JOBS:
    case UPDATE_LIST_OF_MY_COMPLETED_JOBS:
    case UPDATE_JOB_SITE_COUNTS:
      return { ...state, ...payload }
    case NEXT_MY_JOBS_PAGE: {
      const { jobIds, date } = payload || {}
      const current = state[reference] || {}
      const dateData = current[date] || []
      return {
        ...state,
        [reference]: {
          ...current,
          [date]: [...dateData, ...jobIds],
        },
      }
    }
    case NEXT_COMPLETED_JOBS_PAGE: {
      const current = state[reference] || []
      return { ...state, [reference]: [...current, ...payload] }
    }

    default:
      return state
  }
}

export const getMyJobsPageByReference = (state, reference) => state[reference]
export const getJobPageView = state => state.jobPageView

export default jobPageReducer
