import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { Form } from 'formik'
import { FixedPrimaryButton } from '../../../../components/Buttons/Buttons'
import Field from '../../../../components/form/FormikField/FormikField'
import FormErrors from '../../../../components/form/FormErrors/FormErrors'
import {
  getErrorsState,
  getLoginEmailDisabledState,
} from '../../../../redux/reducer'
import RequiredMessage from '../../../../components/RequiredMessage/RequiredMessage'
import Link from '../../../../components/Link/Link'
import InputIcon from '../../../../components/form/InputIcon/InputIcon'

const LoginFormGrid = styled(Form)`
  label: LoginFormGrid;
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'email'
    'password'
    'forgottenPassword'
    'submit';
`
const ForgottenPasswordContainer = styled('div')`
  grid-area: forgottenPassword;
  text-align: right;
`
const ForgottenPassword = styled(Link)`
  text-align: right;
  align-self: end;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 4px 0;
  color: ${props => props.theme.muted};
  :hover {
    color: ${props => props.theme.l8logStatic.l8logPrimary};
  }
`

const LoginFormComponent = ({
  errors,
  touched,
  isSubmitting,
  dirty,
  isValid,
  externalErrors,
  isEmailDisabled,
  from,
}) => {
  const [showPassword, setShowPasswordState] = useState(false)
  return (
    <div>
      <FormErrors externalErrors={externalErrors} />

      <RequiredMessage />

      <LoginFormGrid>
        <Field
          type="string"
          name="email"
          title="Email address"
          error={touched.email && errors.email}
          disabled={isEmailDisabled}
          autoFocus
          data-cy="loginEmail"
          isRequired
        />
        <InputIcon
          type={showPassword ? 'text' : 'password'}
          name="password"
          title="Password"
          error={touched.password && errors.password}
          icon={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
          iconEnd="true"
          iconClick={() => setShowPasswordState(!showPassword)}
          formikInput
          isFastField={false}
          data-cy="loginPassword"
          isRequired
        />
        <ForgottenPasswordContainer>
          <ForgottenPassword
            to={{ pathname: '/password-reset', state: { from } }}
            data-cy="pwResetLink"
          >
            Forgotten Password?
          </ForgottenPassword>
        </ForgottenPasswordContainer>
        <FixedPrimaryButton
          style={{ gridArea: 'submit' }}
          disabled={!dirty || !isValid}
          loading={isSubmitting}
          type="submit"
          data-cy="loginButton"
        >
          Login
        </FixedPrimaryButton>
      </LoginFormGrid>
    </div>
  )
}

LoginFormComponent.propTypes = {
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  externalErrors: PropTypes.objectOf(PropTypes.node),
  isEmailDisabled: PropTypes.bool,
  from: PropTypes.objectOf(PropTypes.string),
}

LoginFormComponent.defaultProps = {
  externalErrors: null,
  isEmailDisabled: false,
  from: { location: { pathname: '/' } },
}

const mapStateToProps = (state, { reference }) => ({
  externalErrors: getErrorsState(state)[reference],
  isEmailDisabled: getLoginEmailDisabledState(state),
})

export default connect(mapStateToProps)(LoginFormComponent)
