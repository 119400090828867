import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import styled from '@emotion/styled'
import SideMenuNavLink from '../SideMenuNavLink/SideMenuNavLink'
import Icon from '../../../components/Icon/Icon'
import { toggleSideMenu } from '../../../reducers/sideMenuState/sideMenuState'
import Badge from '../../../components/Badge/Badge'

const Count = styled(Badge)`
  background-color: #f3f3f3;
  color: #2a2a2a;
`
const SideMenuNavLinks = ({ links, pathname, iconSize, onClick }) =>
  links.map(link => {
    const { link: to, icon, name, count } = link
    const activeLink =
      pathname === to || (pathname && pathname.startsWith(to) && to !== '/')

    return (
      <SideMenuNavLink
        key={to}
        to={to}
        icon={typeof icon === 'string'}
        iconSize={iconSize}
        activeLink={activeLink}
        onClick={onClick}
        data-cy={`sideMenu:${to}`}
      >
        {icon && <Icon iconSize={iconSize} className={icon} />}
        {name}
        {typeof count === 'number' && count > 0 && (
          <Count>{count.toLocaleString()}</Count>
        )}
      </SideMenuNavLink>
    )
  })

SideMenuNavLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  pathname: PropTypes.string,
  iconSize: PropTypes.string,
}

SideMenuNavLinks.defaultProps = {
  links: [],
  pathname: null,
  iconSize: '18px',
}

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(toggleSideMenu(false)),
})

export default connect(
  null,
  mapDispatchToProps
)(SideMenuNavLinks)
