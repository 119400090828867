import React, { lazy } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { withRouter, Switch, Route } from 'react-router-dom'
import Helmet from 'react-helmet'
import AuthenticatedRoutes from './AuthenticatedRoutes/AuthenticatedRoutes'
import LoginPage from '../NoAuthRoutes/LoginPage/LoginPage'
import UserRegistrationPage from '../NoAuthRoutes/UserRegistrationPage/UserRegistrationPage'
import AuthRoute from '../modules/AuthRoute/AuthRoute'
import { initialLoginApi } from '../reducers/loginState/loginState'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import Toasters from '../modules/Toasters/Toasters'
import SuspenseLoader from '../components/SuspenseLoader/SuspenseLoader'

const AppContainer = styled('div')`
  label: AppContainer;
  height: 100%;
  min-height: 100%;
`

const UpdateEmailPage = lazy(() =>
  import(
    /* webpackChunkName: "UpdateEmailPage" */ '../NoAuthRoutes/UpdateEmailPage/UpdateEmailPage'
  )
)

const PasswordResetPage = lazy(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ '../NoAuthRoutes/PasswordResetPage/PasswordResetPage'
  )
)

const PasswordResetterPage = lazy(() =>
  import(
    /* webpackChunkName: "PasswordResetterPage" */ '../NoAuthRoutes/PasswordResetterPage/PasswordResetterPage'
  )
)

const SupportPage = lazy(() =>
  import(
    /* webpackChunkName: "SupportPage" */ '../NoAuthRoutes/SupportPage/SupportPage'
  )
)

/**
 * it is vital that the AuthRoute is below the other routes
 */
class App extends React.Component {
  componentDidMount() {
    const { handleDidMount } = this.props
    handleDidMount()
  }

  render() {
    return (
      <ErrorBoundary>
        <AppContainer>
          <Helmet
            defaultTitle="L8Log"
            titleTemplate="%s | L8Log"
            meta={[
              {
                name: 'description',
                content:
                  'L8Log is your interactive water temperature log book.',
              },
            ]}
          />
          <SuspenseLoader>
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/password-reset"
                component={PasswordResetPage}
              />
              <Route
                exact
                path="/account/password-reset/:token"
                component={PasswordResetterPage}
              />
              <Route exact path="/support" component={SupportPage} />
              <Route
                exact
                path="/account/email-change/:token"
                component={UpdateEmailPage}
              />
              <Route
                exact
                path="/register/:token"
                component={UserRegistrationPage}
              />
              <AuthRoute component={AuthenticatedRoutes} />
            </Switch>
          </SuspenseLoader>
          <Toasters />
        </AppContainer>
      </ErrorBoundary>
    )
  }
}

App.propTypes = {
  handleDidMount: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  handleDidMount: () => dispatch(initialLoginApi()),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(App)
)
