/**
 * This file is for items that are selected to provide reference
 */

// --- Actions ---

const UPDATE_SELECTED_READING = 'UPDATE_SELECTED_READING'
const UPDATE_SELECTED_CONTACT = 'UPDATE_SELECTED_CONTACT'
const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER'
const UPDATE_SELECTED_PDF = 'UPDATE_SELECTED_PDF'
const UPDATE_SELECTED_SENSOR = 'UPDATE_SELECTED_SENSOR'
const UPDATE_SELECTED_LOGBOOK_ENTRY = 'UPDATE_SELECTED_LOGBOOK_ENTRY'
const UPDATE_SELECTED_LOGBOOK_DOCUMENT = 'UPDATE_SELECTED_LOGBOOK_DOCUMENT'

// --- Action Creators ---

export const updateSelectedReading = selectedReading => ({
  type: UPDATE_SELECTED_READING,
  payload: { selectedReading },
})

export const updateSelectedContact = selectedContact => ({
  type: UPDATE_SELECTED_CONTACT,
  payload: { selectedContact },
})

export const updateSelectedUser = selectedUser => ({
  type: UPDATE_SELECTED_USER,
  payload: { selectedUser },
})

export const updateSelectedPdf = selectedPdf => ({
  type: UPDATE_SELECTED_PDF,
  payload: { selectedPdf },
})

export const updateSelectedSensor = selectedSensor => ({
  type: UPDATE_SELECTED_SENSOR,
  payload: { selectedSensor },
})

export const updateSelectedLogbookEntry = selectedLogbookEntry => ({
  type: UPDATE_SELECTED_LOGBOOK_ENTRY,
  payload: { selectedLogbookEntry },
})

export const updateSelectedLogbookDocument = selectedLogbookDoc => ({
  type: UPDATE_SELECTED_LOGBOOK_DOCUMENT,
  payload: { selectedLogbookDoc },
})

// --- redux ---

const initialState = {
  selectedContact: {},
}

const selectedReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_SELECTED_READING:
    case UPDATE_SELECTED_CONTACT:
    case UPDATE_SELECTED_USER:
    case UPDATE_SELECTED_PDF:
    case UPDATE_SELECTED_SENSOR:
    case UPDATE_SELECTED_LOGBOOK_ENTRY:
    case UPDATE_SELECTED_LOGBOOK_DOCUMENT:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getSelectedReading = state => state.selectedReading
export const getSelectedContact = state => state.selectedContact
export const getSelectedUser = state => state.selectedUser
export const getSelectedPdf = state => state.selectedPdf
export const getSelectedSensor = state => state.selectedSensor
export const getSelectedLogbookEntry = state => state.selectedLogbookEntry
export const getSelectedLogbookDocument = state => state.selectedLogbookDoc

export default selectedReducer
