import styled from '@emotion/styled'

const Container = styled('div')`
  label: container;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  /* @media (min-width: 768px) { */
  /* max-width: 90vw; */
  /* } */

  /* @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    max-width: 1200px;
  } */
  @media (min-width: 1600px) {
    max-width: 1600px;
  }
  @media print {
    max-width: none;
  }
`

export default Container
