import tertiaryLinks from './tertiaryLinks/tertiaryLinks'
import primaryLinks from './primaryLinks/primaryLinks'
import adminLinks from './adminLinks/adminLinks'
import account from './accountLinks/accountLinks'
import { getAuthUserState } from '../../redux/reducer'

const LOAD_NAVIGATION = 'LOAD_NAVIGATION'
const UPDATE_MATCH_PARAMS = 'UPDATE_MATCH_PARAMS'
const UPDATE_MATCH_PARAM_ID_REF = 'UPDATE_MATCH_PARAM_ID_REF'
const UPDATE_TERTIARY_MENU_REF = 'UPDATE_TERTIARY_MENU_REF'
const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION'
const UPDATE_TERTIARY_NAVIGATION_LINK = 'UPDATE_TERTIARY_NAVIGATION_LINK'

const loadNavigation = nav => ({
  type: LOAD_NAVIGATION,
  payload: nav,
})
export const updateMatchParams = matchParams => ({
  type: UPDATE_MATCH_PARAMS,
  payload: { matchParams },
})

export const updateMatchParamIdRef = idRef => ({
  type: UPDATE_MATCH_PARAM_ID_REF,
  payload: { idRef },
})

export const updateTertiaryMenuRef = tMenuRef => ({
  type: UPDATE_TERTIARY_MENU_REF,
  payload: { tMenuRef },
})

export const updateNavigation = (navRef, newNavLinks) => ({
  type: UPDATE_NAVIGATION,
  payload: { [navRef]: newNavLinks },
})

export const updateTertiaryNavigationLink = (navLinks, ref) => ({
  type: UPDATE_TERTIARY_NAVIGATION_LINK,
  payload: { [ref]: navLinks },
  reference: 'tertiaryLinks',
})

export const resetMatchParams = () => dispatch => {
  dispatch(updateMatchParamIdRef())
  dispatch(updateMatchParams())
}

export const resetTertiaryMenu = () => dispatch => {
  dispatch(updateTertiaryMenuRef())
  dispatch(resetMatchParams())
}

export const navigationSuccess = () => (dispatch, getState) => {
  const parent = getAuthUserState(getState()).associated_org || {}
  const primary = primaryLinks
    .filter(link => (link.parent ? link.parent.includes(parent.type) : true))
    .map(i => {
      const pLink = i
      pLink.link = pLink.link.replace(':id', parent.id)
      return pLink
    })
  const admin = adminLinks.filter(link =>
    link.parent ? link.parent === parent.type : true
  )

  dispatch(loadNavigation({ primary, account, tertiaryLinks, admin }))
}

export const initialState = {
  primary: [],
  account: [],
  tertiaryLinks: {},
  admin: [],
  matchParams: null,
  tMenuRef: null,
  idRef: null,
}

const navigationReducer = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case LOAD_NAVIGATION:
    case UPDATE_MATCH_PARAMS:
    case UPDATE_MATCH_PARAM_ID_REF:
    case UPDATE_TERTIARY_MENU_REF:
    case UPDATE_NAVIGATION:
      return { ...state, ...payload }
    case UPDATE_TERTIARY_NAVIGATION_LINK:
      return {
        ...state,
        [reference]: { ...state[reference], ...payload },
      }
    default:
      return state
  }
}

export const getPrimaryLinks = state => state.primary
export const getAccountLinks = state => state.account
export const getAdminLinks = state => state.admin
export const getTertiaryLinks = state => state.tertiaryLinks
export const getMatchParams = state => state.matchParams
export const getMatchParamsIdRef = state => state.idRef
export const getTMenuRef = state => state.tMenuRef

export default navigationReducer
