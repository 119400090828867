import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import Debug from 'debug'
import UserRegistrationComponent from './UserRegistrationComponent/UserRegistrationComponent'
import { registerUser } from '../../../../../reducers/userRegistrationState/userRegistrationState'

const debug = Debug('web:AddNewUserForm:')

const UserRegistrationForm = ({
  initialValues,
  handleSubmit,
  formRef,
  humanReadableUserRole,
}) => (
  <Formik
    initialValues={{
      ...{
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      ...initialValues,
    }}
    onSubmit={(values, { setSubmitting }) => {
      debug(values)
      const { confirmPassword, ...submittedValues } = values
      debug(submittedValues)
      return Promise.resolve()
        .then(() => handleSubmit(submittedValues, setSubmitting))
        .catch(err => {
          debug(err)
          setSubmitting(false)
        })
    }}
    validationSchema={yup.object().shape({
      first_name: yup
        .string()
        .trim()
        .max(100)
        .min(1, 'A first name must be at least 1 characters long.')
        .required(' '),
      last_name: yup
        .string()
        .trim()
        .max(100)
        .min(1, 'A last name must be at least 1 characters long.')
        .required(' '),
      phone_number: yup
        .string()
        .trim()
        .min(
          10,
          'Phone number must be 11 characters long, 13 with a country code.' // eslint-disable-line no-template-curly-in-string
        )
        .max(
          13,
          'Phone number must be 11 characters long ${max} with a country code.' // eslint-disable-line no-template-curly-in-string
        )
        .required(' '),
      email: yup
        .string()
        .trim()
        .email('Email must be valid.')
        .required(' '),
      password: yup
        .string()
        .trim()
        .min(8, 'A password must be a minimum of 8 characters long.')
        .required(' '),
      confirmPassword: yup
        .string()
        .trim()
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.')
        .required(' '),
    })}
    render={formikProps => (
      <UserRegistrationComponent
        {...formikProps}
        formRef={formRef}
        humanReadableUserRole={humanReadableUserRole}
      />
    )}
  />
)

UserRegistrationForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.node),
  handleSubmit: PropTypes.func.isRequired,
  formRef: PropTypes.string.isRequired,
  humanReadableUserRole: PropTypes.string,
}
UserRegistrationForm.defaultProps = {
  initialValues: {},
  humanReadableUserRole: null,
}

const mapDispatchToProps = (dispatch, { formRef, history: { push } }) => ({
  handleSubmit: (values, setSubmitting) =>
    dispatch(registerUser(push, values, setSubmitting, formRef)),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(UserRegistrationForm)
)
