import Debug from 'debug'
import statusType from '../statusType/statusType'

const debug = Debug('web:multiLogAccumilator:')

/**
 * combines logs down to a type, count and last occurrence
 * @param {[]} rawLogs must have more than 1
 */
const multiLogAccumulator = rawLogArray => {
  // adds a log with a type that shouldn't match
  const rawLogs = [...rawLogArray, { type: null }]
  let lastType = ''
  let logs = []
  let count = 0
  let sameLogType = []
  let newestLogOfType = {}

  rawLogs.forEach((rawLog, index) => {
    const { type: typeToTest } = rawLog

    // sets up the loop
    if (index === 0) {
      count += 1
      lastType = typeToTest
      newestLogOfType = rawLog
      return
    }

    // just adds to the counter and assumes itself is older
    if (typeToTest === lastType) {
      sameLogType = [...sameLogType, rawLog]
      count += 1
      return
    }

    // log is of a different type
    const { type, created_at, _id } = newestLogOfType
    const status = statusType(type)
    sameLogType = [newestLogOfType, ...sameLogType]
    // appends to logs
    logs = [
      ...logs,
      { _id, type, created_at, count, status, rawLogs: sameLogType },
    ]

    // resets lookups
    count = 1
    lastType = typeToTest
    newestLogOfType = rawLog
    sameLogType = []
  })
  debug(logs)
  return logs
}

export default multiLogAccumulator
