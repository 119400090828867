import styled from '@emotion/styled'

const DropDownMenu = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: ${props => props.theme.borderRadius};
  display: block;
  min-width: 140px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  right: ${props => (props.right ? 0 : null)};
  left: ${props => (props.right ? 'auto' : null)};

  :before {
    position: absolute;
    top: -6px;
    left: 12px;
    display: inline-block;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 40, 100, 0.12);
    border-left: 5px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
    left: ${props => (props.right ? 'auto' : null)};
    right: ${props => (props.right ? '12px' : null)};
  }

  :after {
    position: absolute;
    left: 12px;
    display: inline-block;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    top: -5px;
    border-bottom: 5px solid #fff;
    content: '';
    left: ${props => (props.right ? 'auto' : null)};
    right: ${props => (props.right ? '12px' : null)};
  }
`

export default DropDownMenu
