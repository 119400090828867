import Debug from 'debug'
import api from '../../api'
import {
  riskPrefix,
  attachDetachDocWithJob,
} from '../l8LogbookState/l8LogbookState'
import { getDocumentsState } from '../../redux/reducer'
import { errorHandler } from '../errorState/errorState'
import { toggleLoader } from '../pageLoaderState/pageLoaderState'
import { successToaster } from '../../modules/Toasters/ToasterMethods'
import {
  createDownloadLinkAndClick,
  downloadDocumentRequest,
} from '../../utils/downloadDocument/downloadDocument'
import { updateJob } from '../jobsState/jobsState'

const debug = Debug('web:l8LogbookDocumentState:')

// --- Actions ---

const UPDATE_DOCUMENT_META = 'UPDATE_DOCUMENT_META'
const DELETE_DOC = 'DELETE_DOC'
const ADD_TO_DOCS_TO_DELETE = 'ADD_TO_DOCS_TO_DELETE'
const RESET_DOCS_TO_DELETE = 'RESET_DOCS_TO_DELETE'

// --- Action Creator ---

export const updateDocumentMeta = (doc, jobId) => ({
  type: UPDATE_DOCUMENT_META,
  payload: { [doc._id]: doc },
  reference: jobId,
})

const deleteDoc = (docId, jobId) => ({
  type: DELETE_DOC,
  payload: docId,
  reference: jobId,
})

export const addToDocsToDelete = docId => ({
  type: ADD_TO_DOCS_TO_DELETE,
  payload: docId,
})

export const resetDocsToDelete = () => ({
  type: RESET_DOCS_TO_DELETE,
})

// --- get and store document metadata ---

const getDocument = id => api(`${riskPrefix}/documents/${id}`)

const getDocumentDownload = id =>
  api(`${riskPrefix}/documents/${id}/download`, {
    responseType: 'blob',
  })

export const getAndStoreDocumentMetadata = (documentIds, jobId, modalRef) => (
  dispatch,
  getState
) =>
  Promise.all(
    documentIds.map(doc => {
      const parentInStore = getDocumentsState(getState())[jobId]
      const inStore = parentInStore ? parentInStore[doc] : false
      return inStore
        ? getDocument(doc)
            .then(newDoc =>
              dispatch(updateDocumentMeta({ ...inStore, ...newDoc }, jobId))
            )
            .catch(err => {
              debug(err.response)
              if (err.response.status === 404) {
                dispatch(deleteDoc(doc, jobId))
              } else {
                throw err
              }
            })
        : Promise.resolve()
            .then(() => getDocument(doc))
            .then(newDoc => {
              dispatch(updateDocumentMeta(newDoc, jobId))
              return newDoc.type.includes('image')
                ? getDocumentDownload(doc)
                    .then(response => {
                      debug(response)
                      return response
                    })
                    .then(file => URL.createObjectURL(file))
                    .then(preview => ({ ...newDoc, preview }))
                    .then(previewDoc =>
                      dispatch(updateDocumentMeta(previewDoc, jobId))
                    )
                : newDoc
            })
    })
  ).catch(err => {
    debug(err)
    dispatch(toggleLoader(false, false, 'uploadedDocs'))
    dispatch(errorHandler(err, modalRef))
  })

// -- get pdf download and convert to blob --

export const getPdfPreview = (id, jobMongoId, modalRef) => (
  dispatch,
  getState
) => {
  const parentInStore = getDocumentsState(getState())[jobMongoId]
  const inStore = parentInStore ? parentInStore[id] : false
  return getDocumentDownload(id)
    .then(file => URL.createObjectURL(file))
    .then(preview => ({ ...inStore, preview }))
    .then(previewDoc => dispatch(updateDocumentMeta(previewDoc, jobMongoId)))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err, modalRef))
    })
}

// --- remove document

const removeDocumentRequest = docId =>
  api(`${riskPrefix}/documents/${docId}`, { method: 'delete' })

export const removeDocument = ({ docId, jobMongoId, modalRef }) => (
  dispatch,
  getState
) => {
  const jobDocuments = getDocumentsState(getState())[jobMongoId]
  const { name } = (jobDocuments && jobDocuments[docId]) || {
    name: 'a file',
  }

  return Promise.resolve()
    .then(() => attachDetachDocWithJob(jobMongoId, docId, false))
    .then(response => dispatch(updateJob(response, response._id)))
    .then(() => removeDocumentRequest(docId))
    .then(() => dispatch(deleteDoc(docId, jobMongoId)))
    .then(() => successToaster(`deleted ${name}`, 'from your logbook', 'error'))
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err, modalRef))
    })
}

export const removeOnlyDocumentApi = (docId, parentId, errRef, isClearDown) => (
  dispatch,
  getState
) => {
  const jobDocuments = getDocumentsState(getState())[parentId]
  const { name } = (jobDocuments && jobDocuments[docId]) || {
    name: 'a file',
  }

  return removeDocumentRequest(docId)
    .then(() => dispatch(deleteDoc(docId, parentId)))
    .then(
      () =>
        !isClearDown &&
        successToaster(`deleted ${name}`, 'from your assessment', 'error')
    )
    .then(() => name)
    .catch(err => {
      debug(err)
      dispatch(errorHandler(err, errRef))
    })
}

// --- download document ---

export const downloadLogbookDocumentApi = (
  id,
  title,
  type,
  url
) => dispatch => {
  const [, fileType] = type.split('/')
  return Promise.resolve()
    .then(() => dispatch(toggleLoader(true, false, id)))
    .then(
      () =>
        url || downloadDocumentRequest(`${riskPrefix}/documents/${id}/download`)
    )
    .then(blobUrl => createDownloadLinkAndClick(blobUrl, title, fileType))
    .then(() => dispatch(toggleLoader(false, false, id)))
}

// --- Redux ---

const initialState = { docsToDelete: [] }

const l8LogbookDocuments = (state = initialState, action) => {
  const { type, payload, reference } = action
  switch (type) {
    case UPDATE_DOCUMENT_META:
      return { ...state, [reference]: { ...state[reference], ...payload } }
    case DELETE_DOC: {
      const currentDocs = { ...state[reference] }
      delete currentDocs[payload]

      return { ...state, [reference]: currentDocs }
    }
    case ADD_TO_DOCS_TO_DELETE: {
      const currentDocs = state.docsToDelete || []
      return { ...state, docsToDelete: [...currentDocs, payload] }
    }
    case RESET_DOCS_TO_DELETE:
      return { ...state, docsToDelete: [] }
    default:
      return state
  }
}

export const getDocuments = state => state
export const getDocsToDelete = state => state.docsToDelete

export default l8LogbookDocuments
