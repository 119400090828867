import styled from '@emotion/styled'

const Alert = styled('div')`
  label: Alert;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
  text-align: center;
`

export const SuccessAlert = styled(Alert)`
  label: SuccessAlert;
  color: #316100;
  background-color: #dff1cc;
  border-color: #d2ecb8;
`

export const DangerAlert = styled(Alert)`
  label: DangerAlert;
  color: #6b1110;
  background-color: #f5d2d2;
  border-color: #f1c1c0;
`

export const WarningAlert = styled(Alert)`
  label: WarningAlert;
  color: #7d6608;
  background-color: #fcf3cf;
  border-color: #fbeebc;
`

export const PrimaryAlert = styled(Alert)`
  label: PrimaryAlert;
  color: #24426c;
  background-color: #dae5f5;
  border-color: #cbdbf2;
`

export const SecondaryAlert = styled(Alert)`
  label: SecondaryAlert;
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2;
`

export const InfoAlert = styled(Alert)`
  label: InfoAlert;
  color: #24587e;
  background-color: #daeefc;
  border-color: #cbe7fb;
`

export default Alert
