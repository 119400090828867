import React from 'react'
import PropTypes from 'prop-types'
import { FastField, Field } from 'formik'

const FormikFastOrSlowField = ({ isFastField, ...otherProps }) =>
  isFastField ? <FastField {...otherProps} /> : <Field {...otherProps} />

FormikFastOrSlowField.propTypes = {
  isFastField: PropTypes.bool,
}

FormikFastOrSlowField.defaultProps = {
  isFastField: true,
}

export default FormikFastOrSlowField
