import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { connect } from 'react-redux'
import { getThemeState } from '../redux/reducer'
import defaultTheme from '../css/theme'

const ReduxThemeProvider = ({ theme, children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

ReduxThemeProvider.propTypes = {
  theme: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
}
ReduxThemeProvider.defaultProps = {
  theme: defaultTheme,
  children: null,
}
const mapStateToProps = state => ({
  theme: getThemeState(state),
})

export default connect(mapStateToProps)(ReduxThemeProvider)
